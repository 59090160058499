import axios from 'axios';
import { jwtDecode }  from 'jwt-decode';
import { lruMemoize } from 'reselect';

export const CMX_API_ROOT = '/v2/cmx';

const buildAxios =  lruMemoize( ( baseURL, sessionId ) => {
    const client = axios.create( { baseURL } );

    try {
        const accessTokenPayload = jwtDecode( sessionId );
        const companyId = isObject( accessTokenPayload ) && accessTokenPayload.cmp;

        client.interceptors.request.use( ( request ) => {
            request.headers.authorization = `Bearer ${ sessionId }`;
            request.headers[ 'company-id' ] = `${ companyId }`;

            return request;
        } );
    } catch ( e ) {
        console.warn( 'Cannot parse JWT token from session ID:', e );
    }
    return client;
} );


function isObject( thing ) {
    return ( typeof thing === 'object' ) && Boolean( thing );
}

/**
 * This function returns the translations that it receives from CMX using a promise. It
 * just calls 'listAllTranslatedProjectStrings', but it is friendlier and easier to use
 * (I think). It will create the AxiosInstance itself using the `sessionId` we provide.
 *
 * @param apiRootUrl    The URL of the Kong API root, e.g. https://qa.api.biw.cloud
 * @param sessionId     The user's session Id used to create an AxiosInstance that communicates with CMX.
 * @param projectName   The project to look up in CMX.
 * @param localeCode    The locale in the project to retrieve.
 */

export const getTranslatedProjectStrings = async ( apiRootUrl, sessionId, projectName, localeCode ) => {
    if ( sessionId === null ) {
        throw new Error( 'The \'Session ID\' you provided is null.' );
    }

    // Create AxiosInstance and reach out to CMX to pull translation results down
    const client = buildAxios( apiRootUrl, sessionId );

    const response = ( await client.post( `${ CMX_API_ROOT }/bundle/${ projectName }`, { locales: localeCode  } ) ).data;
    if ( localeCode in response.bundles ) {
        return response.bundles[ localeCode ];
    } else {
        throw new Error( 'Unable to fetch bundle' );
    }
};

import React from 'react';
import { LocaleString } from '@nackle/intl-tools';
import { Button, Modal } from '@nackle/paper';

const InstructionsDialog = ( props ) => {
    const {
        instructions,
        visible,
        onClose,
    } = props;
    return (
        <Modal
            getContainer=".game-container"
            visible={ visible }
            onCancel={ onClose }
            centered
            title={ <LocaleString code="game-loader.INSTRUCTIONS_MODAL.HEADER" /> }
            footer={ (
                <Button
                    id="instructions_dialog_close"
                    type="primary"
                    onClick={ onClose }
                >
                    <LocaleString code="game-loader.INSTRUCTIONS_MODAL.CLOSE_BUTTON" />
                </Button>
            ) }
        >
            { instructions || <LocaleString code="game-loader.FALLBACK_INSTRUCTIONS" /> }
        </Modal>
    );
};

export default InstructionsDialog;
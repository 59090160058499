import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const NackleItem = ( { className, leftIcon, rightIcon, title, description, size, itemOnClick, disabled } ) => {
    const itemClasses = classNames( {
        'nackle-item': true,
        [ className ]: className ? true : false,
        [ size ? size : 'medium' ]: true,
        'right-icon': rightIcon ? true : false,
        'left-icon': leftIcon ? true : false,
        'description': description ? true : false,
        'disabled': disabled ? true : false
    } );
    const itemClick = ( e ) => {
        if ( typeof itemOnClick === 'function' && !disabled ) {
            itemOnClick( e );
        }
    };
    return (
        <div className={ itemClasses } onClick={ itemClick }>
            <span className="left-container">
            { leftIcon &&
                <div className="item-icon left">
                    { leftIcon ? leftIcon : null }
                </div>
            }
            <div className="item-text-container">
                <div className="item-title">
                    { title }
                </div>
                {
                    description &&
                    <div className="item-description">
                        { description }
                    </div>
                }
            </div>
            </span>
            { rightIcon &&
                <div className="item-icon right">
                    { rightIcon ? rightIcon : null }
                </div>
            }
        </div>
    );
};

NackleItem.propTypes = {
    /** class name for component */
    className: PropTypes.string,
    /** icon for display on left side of notation */
    leftIcon: PropTypes.node,
    /** icon for display on right side of notation */
    rightIcon: PropTypes.node,
    /** title of item to display */
    title: PropTypes.string,
    /** description to display */
    description: PropTypes.string,
};

export { NackleItem };
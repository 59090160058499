import { Tooltip as Ttip } from 'antd';
import React from 'react';

const Tooltip = ( { children, ...props } ) => {
    return (
        <Ttip arrow={ { pointAtCenter: true } } { ...props }>
            { children }
        </Ttip>
    );
};
export { Tooltip };
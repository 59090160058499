import React from 'react';
import { CalendarWeek, ChevronDoubleLeft, ChevronDoubleRight, ChevronLeft, ChevronRight, Close, Time, MathMinus, ErrorFilled } from '@nackle/origami-icons';

const customIcons = {
    calendarIcon: (
        <span role='img' aria-label='calendar' className='anticon origami-calendar-week'>
            <CalendarWeek />
        </span>
    ),
    clearIcon: (
        <span role='img' aria-label='close-circle' className='anticon origami-circle-close-filled'>
            <Close />
        </span>
    ),
    clockIcon: (
        <span role='img' aria-label='close-circle' className='anticon origami-circle-close-filled'>
            <Time />
        </span>
    ),
    exchangeIcon: (
        <span role='img' aria-label='to' className='ant-picker-separator'>
            <MathMinus />
        </span>
    ),
    nextIcon: (
        <span role='img' aria-label='to' className='origami-picker-next-icon'>
            <ChevronRight />
        </span>
    ),
    prevIcon: (
        <span role='img' aria-label='to' className='origami-picker-prev-icon'>
            <ChevronLeft />
        </span>
    ),
    superNextIcon: (
        <span role='img' aria-label='to' className='origami-picker-super-next-icon'>
            <ChevronDoubleRight />
        </span>
    ),
    superPrevIcon: (
        <span role='img' aria-label='to' className='origami-picker-super-prev-icon'>
            <ChevronDoubleLeft />
        </span>
    ),
    error: (
        <span role='img' aria-label='to' className='origami-picker-super-prev-icon'>
            <ErrorFilled />
        </span>
    ),
};

export default customIcons;
import React from 'react';

const NackleBehavior = ( props ) => {

    const getClassNames = () => {
        if ( props.size === 'large' ) {
            return 'nackle-behavior nackle-behavior-large';
        } 
        if ( props.size === 'small' ) {
            return 'nackle-behavior nackle-behavior-small';
        } 
        else {
            return 'nackle-behavior';
        }
    };

    return ( 
        <div className={ getClassNames() }>
            { props.imageURL && 
                <img src={ props.imageURL } alt={ `${ props.name } behavior` }></img>
            }
            <span>{ props.name }</span>
        </div>
    );
};

export { NackleBehavior };
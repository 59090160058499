import React from 'react';
import { Typography as AntType } from 'antd';
import classNames from 'classnames';

const subcomponents = {
    Title: ( { weight, children, italic, ...restProps } ) => restProps.level === 6 ? (
        italic ? (
            <h6 className={ classNames(
                'ant-typography',
                'ant-typography-heading-6',
                weight ? `ant-typography-${ weight }` : ''
            ) }
            { ...restProps }
            >
                <i>{ children }</i>
            </h6>
        ) : (
            <h6 className={ classNames(
                'ant-typography',
                'ant-typography-heading-6',
                weight ? `ant-typography-${ weight }` : ''
            ) }
            { ...restProps }
            >
                { children }
            </h6>
        )
    ) : (
        <AntType.Title
            className={ classNames(
                weight ? `ant-typography-${ weight }` : '',
                { [ restProps.className ]: restProps.className ? true : false }
            ) } { ...restProps } >
            { children }
        </AntType.Title>
    ),
    Text: ( { size, weight, children, ...restProps } ) => (
        <AntType.Text
            className={ classNames(
                weight ? `ant-typography-${ weight }` : '',
                size ? `ant-typography-size-${ size }` : '',
                { [ restProps.className ]: restProps.className ? true : false }
            ) }
            { ...restProps }
        >
            { children }
        </AntType.Text>
    ),
    Paragraph: ( { weight, children, ...restProps } ) => (
        <AntType.Paragraph
            className={ classNames(
                weight ? `ant-typography-${ weight }` : '',
                { [ restProps.className ]: restProps.className ? true : false }
            ) }
            { ...restProps }>
            { children }
        </AntType.Paragraph>
    ),
    Link: ( { weight, children, ...restProps } ) => (
        <AntType.Link
            className={ classNames(
                weight ? `ant-typography-${ weight }` : '',
                { [ restProps.className ]: restProps.className ? true : false }
            ) }
            { ...restProps }>
            { children }
        </AntType.Link>
    )
};

const Typography = ( { children, ...restProps } ) => {
    return <AntType { ...restProps }>{ children }</AntType>;
};

Object.keys( subcomponents ).forEach( component => {
    Typography[ component ] = subcomponents[ component ];
} );

export default Typography;

import React from 'react';

/*interface Props {
    id: string;
    color: string;
    radius: number;
    animName?: string;
    animDelay: number;
    animDuration: number;
    offsetX?: number;
    offsetY?: number;
}*/

export class Particle extends React.Component {

    render() {
        const { id, color, radius, animName, animDuration, animDelay, offsetX, offsetY } = this.props;

        const circleStyle = {
            width: radius,
            height: radius,
            transform: `translate(${ ( offsetX ) ? offsetX : 0 }px, ${ ( offsetY ) ? offsetY : 0 }px)`,
            borderRadius: '50%',
            backgroundColor: color,
            opacity: 0,
            position: 'absolute',
            marginLeft: 'auto',
            marginRight: 'auto',

            animationName: ( animName ) ? animName : '',
            animationTimingFunction: 'cubic-bezier(.18, .62, .16, .99)',
            animationDuration: `${ animDuration }s`,
            animationDelay: `${ animDelay }s`,
            animationIterationCount: 1,
            animationDirection: 'normal',
            animationFillMode: 'forwards',
        };

        return (
            <div id={ id } style={ circleStyle }/>
        );
    }
}

// This lives in its own module so it can be mocked-out.
// It turns out even though we've told Webpack to use `fileLoader` to load the file's path,
// Jest lacks a similar mechanism so it tries to actually import the loader package like
// any other module.

import '@nackle/game-loader-component/static/loader.css';
import '@nackle/game-loader-component/static/award-swirl.png';
import { getDeployedResourceUrl } from '../utils/buildUrls';

export const LOADER_URL = getDeployedResourceUrl();
export const IMG_AWARD_SWIRL = '/award-swirl.png';

try {
    // try to preload this to make the gameplay experience better
    new Image().src = IMG_AWARD_SWIRL;
    // tslint:disable-next-line: no-empty
} catch ( e ) {
    console.log( e );
}

import './bannerImage.jpg';
export const FALLBACK_ARCADE_IMAGE = '/bannerImage.jpg';

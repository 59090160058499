import { Translate } from '@nackle/arcade-core-v2';
import InstantPlay from './InstantPlay';
import React from 'react';
import { connect } from 'react-redux';
import { buildInstantPlayGames, Status } from '../stolen-from-widget';
import { Typography } from '@nackle/paper';
import { FloorText } from '../../translation-data';
import '../arcade-room/RoomCabinetList.less';
import './InstantPlayList.less';

const { Title } = Typography;

const mapStateToProps = () => {
    const getInstantPlayGames = buildInstantPlayGames();

    return ( state ) => {
        return {
            plays: getInstantPlayGames( state ),
        };
    };
};

class InstantPlays extends React.Component {
    render() {
        const { plays } = this.props;

        if ( plays === Status.LOADING ) {
            return <div>`&quot;`loading`&quot;`</div>;
        }
        if ( plays === Status.ERROR ) {
            return <div>`&quot;`error`&quot;`</div>;
        }

        if ( plays.length === 0 ) {
            return <></>;
        }

        return (
            <div className="room">
                <div className="room-header">
                    <div className="room-header-left">
                        <Title level={ 2 }><Translate id={ FloorText.INSTANT_PLAYS_HEADER } /></Title>
                    </div>
                </div>
                <div className="instant-play-list">
                    { plays.map( ( play ) => (
                        <InstantPlay
                            cabinet={ play.cabinet }
                            key={ play.cabinet.cabinetId }
                            onClick={ this.props.onClickCabinet }
                            playCount={ play.playCount }
                            roomId={ play.roomId }
                        />
                    ) ) }
                    <div className="instant-play-spacer" />
                    <div className="instant-play-spacer" />
                    <div className="instant-play-spacer" />
                    <div className="instant-play-spacer" />
                </div>
            </div>
        );
    }
}

export default connect( mapStateToProps )( InstantPlays );

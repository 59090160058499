import Oidc, { UserManager } from 'oidc-client/lib/oidc-client';
import jwtDecode from 'jwt-decode';
import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';
import Events from './events';
import random from './random';
import Storage, { STORAGE_TYPE_LOCAL } from './storage';
import ActivityTimeout from './activity-timeout';

const storage = new Storage( STORAGE_TYPE_LOCAL );

const ERROR_MESSAGES_TO_IGNORE = [
    'End-User authentication is required'
];

export default function Auth( options, location = window.location ) {
    const basePathname = options.basePathname || '';
    const isLocalhost = ( location.origin.indexOf( '://localhost' ) >= 0 );

    const events = {
        signInNeeded: new Events(),
        signIn: new Events(),
        signOut: new Events(),
        stateChange: new Events(),
        setLocation: new Events(),
        signInError: new Events(),
        signOutError: new Events(),
        sessionExpired: new Events(),
        sessionExpiring: new Events()
    };

    options = {
        basePathname: basePathname,
        clientId: `${ location.origin }${ basePathname }`,
        redirectUri: `${ location.origin }${ basePathname }/oidc_signin`,
        postLogoutRedirectUri: `${ location.origin }${ basePathname }/oidc_signout`,
        silentRedirectUri: `${ location.origin }${ basePathname }/oidc_renew`,
        popup: false,
        popupRedirectUri: `${ location.origin }${ basePathname }/oidc_signin`,
        popupWindowFeatures: 'location=no,toolbar=no,width=500,height=800,left=100,top=100',
        popupWindowTarget: '_blank',
        responseType: 'code',
        responseMode: 'query',
        scope: `openid profile company permissions email recovery address phone${ options.offlineAccess ? ' offline_access' : '' }`,
        filterProtocolClaims: true,
        automaticSilentRenew: false, // handle this with an event
        revokeAccessTokenOnSignOut: true,
        loadUserInfo: true,
        clockSkew: 600,
        monitorAccessTokenExpiration: true,
        checkRecoveryMethods: true,
        enableActivityTimeout: false,
        activityTimeoutInMinutes: 60,
        activityPromptInMinutes: 50,
        ...( options || {} )
    };

    // create a storate key to track where to return
    const returnToKey = `bridge.returnTo.${ options.clientId }`;
    const sessionExpiredKey = `bridge.sessionExpired.${ options.clientId }`;

    const referrer = document ? document.referrer : null;
    if ( referrer ) {
        options.extraQueryParams = {
            ...( options.extraQueryParams || {} ),
            referrer: encodeURIComponent( referrer.split( '?' )[ 0 ] )
        };
    }

    // check to see if a sso_token was passed along
    const ssoToken = getSSOToken();
    if ( ssoToken ) {
        options.extraQueryParams = {
            ...( options.extraQueryParams || {} ),
            ssoToken
        };
    }

    // legacy support for company-setup
    if ( options.clientId === 'admin' ) {
        options.clientId = `admin:${ location.origin }${ basePathname }`;
    }

    // make trace functions
    function getTraceLocation() {
        if ( options.debugLocation ) {
            return ` location: ${ window.location.href }`;
        }
        return '';
    }
    function traceStart( name ) {
        if ( options.debug === true || ( options.debug === undefined && isLocalhost ) ) {
            console.group();
            console.debug( `${ name }${ getTraceLocation() } entered` );

            return {
                end: ( value ) => {
                    console.debug( `${ name }${ getTraceLocation() } exited` );
                    console.groupEnd();
                    return value;
                }
            };
        }
        // return an end function that does nothing
        return { end: ( value ) => value };
    }

    // have oidc library log to the console.
    if ( options.debug === true || ( options.debug === undefined && isLocalhost ) ) {
        if ( options.debugClient !== false ) {
            Oidc.Log.logger = console;
            Oidc.Log.level = Oidc.Log.DEBUG;
        }
    }
    // make sure uris are in lower case
    options.redirectUri = options.redirectUri.toLowerCase();
    options.postLogoutRedirectUri = options.postLogoutRedirectUri.toLowerCase();
    options.silentRedirectUri = options.silentRedirectUri.toLowerCase();

    const getRootUri = ( includeQuery ) => {
        const trace = traceStart( 'getRootUri' );

        if ( includeQuery ) {
            return trace.end( `${ ( `${ location.origin }${ options.basePathname }` ).toLowerCase() }${ location.search }` );
        } else {
            return trace.end( `${ location.origin }${ options.basePathname }`.toLowerCase() );
        }
    };
    function getCurrentUri( includeQuery ) {
       const trace = traceStart( 'getCurrentUri' );

        if ( includeQuery ) {
            return trace.end( `${ ( `${ location.origin }${ location.pathname }` ).toLowerCase() }${ location.search }` );
        } else {
            return trace.end( `${ location.origin }${ location.pathname }`.toLowerCase() );
        }
    }
    const isAuthUri = ( uri ) => {
       const trace = traceStart( 'isAuthUri' );

        if ( !uri ) {
            uri = getCurrentUri();
        }

        return trace.end(
            uri === options.redirectUri ||
            uri === options.postLogoutRedirectUri ||
            uri === options.silentRedirectUri
        );
    };

    // if we are in an auth url; don't run the session check
    if ( isAuthUri( getCurrentUri() ) ) {
        options.monitorSession = false;
    }

    // create the user manager
    const userManager = new UserManager( getUserManagerConfig( options ) );
    // remove stale state
    userManager.clearStaleState();

    let state = {};
    const cache = {};

    const getUser = async () => {
       const trace = traceStart( 'getUser' );

        const user = await userManager.getUser();
        refreshState( user );
        return trace.end( user );
    };

    const removeUser = async() => {
       const trace = traceStart( 'removeUser' );

        await userManager.removeUser();
        await userManager.clearStaleState();
        refreshState( null );

        return trace.end();
    };

    const refreshState = ( user, delegateUser ) => {
       const trace = traceStart( 'refreshState' );

        const original = {
            access_token: user ? user.access_token : null,
            access_token_expires_at: user && user.expires_at ? new Date( user.expires_at * 1000 ) : null,
            refresh_token: user ? user.refresh_token : null,
            subject: user ? user.profile.sub : null,
            claims: {
                company_id: user ? user.profile.company_id : null,
                person_id: user ? user.profile.person_id : null,
                username: user ? user.profile.preferred_username : null,
                name: user ? user.profile.name : null,
                family_name: user ? user.profile.family_name : null,
                given_name: user ? user.profile.given_name : null,
                locale: user ? user.profile.locale : null,
                country: user ? user.profile.country : null,
                email: user ? user.profile.email : null,
                emails: user ? user.profile.emails : null,
                phone_number: user ? user.profile.phone_number : null,
                phone_numbers: user ? user.profile.phone_numbers : null,
                address: user ? user.profile.address : null,
                addresses: user ? user.profile.addresses : null,
                picture: user ? user.profile.picture : null,
                permissions: user ? user.profile.permissions : null,
                permittedCompanyIds: user ? user.profile.permitted_company_ids : null,
                attributes: user ? user.profile.attributes || {} : null
            }
        };

        const delegate = {
            access_token: delegateUser ? delegateUser.access_token : null,
            subject: delegateUser ? delegateUser.subject : null,
            claims: {
                company_id: delegateUser ? delegateUser.company_id : null,
                person_id: delegateUser ? delegateUser.person_id : null,
                username: delegateUser && user ? user.profile.preferred_username : null,
                name: delegateUser && user ? user.profile.name : null,
                family_name: delegateUser && user ? user.profile.family_name : null,
                given_name: delegateUser && user ? user.profile.given_name : null,
                locale: delegateUser && user ? user.profile.locale : null,
                country: delegateUser && user ? user.profile.country : null,
                email: delegateUser && user ? user.profile.email : null,
                emails: delegateUser && user ? user.profile.user : null,
                phone_number: delegateUser && user ? user.profile.phone_number : null,
                phone_numbers: delegateUser && user ? user.profile.phone_numbers : null,
                address: delegateUser && user ?  user.profile.address : null,
                addresses: delegateUser && user ? user.profile.addresses : null,
                picture: delegateUser && user ? user.profile.picture : null,
                permissions: delegateUser ? delegateUser.permissions : null,
                permittedCompanyIds: delegateUser && user ? user.profile.permitted_company_ids : null,
                attributes: delegateUser ? {} : null
            }
        };

        const newState = {
            client_id: options.clientId,
            id_token: user ? user.id_token : null,
            session_id: user ? user.profile.sid : null,
            access_token: delegateUser ? delegate.access_token : original.access_token,
            access_token_expires_at: original.access_token_expires_at,
            refresh_token: original.refresh_token,
            subject: delegateUser ? delegate.subject : original.subject,
            claims: { ...( delegateUser ? delegate.claims : original.claims ) },
            original,
            delegate,
            actor: delegateUser ? delegateUser.act : user ? user.profile.act : null
        };

        const didStateChange = (
            JSON.stringify( state || {} ) !== JSON.stringify( newState )
        );

        if ( didStateChange ) {
            state = newState;
            events.stateChange.fire( state );
        }

        return trace.end();
    };

    const resume = async ( session, signInOnResumeError ) => {
       const trace = traceStart( 'resume' );

        const result = await signInOrResume( true, session, signInOnResumeError );

        return trace.end( result );
    };

    const signIn = async () => {
       const trace = traceStart( 'signIn' );

        const result = await signInOrResume( false );

        return trace.end( result );
    };

    const signInOrResume = async ( isResuming, session, signInOnResumeError ) => {
       const trace = traceStart( 'signInOrResume' );

        // make sure sign in is only called once at a time
        // the auth process keeps some state and this can get mismatched if called multiple times
        if ( cache.signInOrResumePromise ) {
            const p = cache.signInOrResumePromise;
            const result = await Promise.resolve( p );
            return trace.end( result );
        }

        try {
            const p = signInOrResumeHelper( isResuming, session, signInOnResumeError );
            cache.signInOrResumePromise = p;
            const result = await Promise.resolve( p );
            return trace.end( result );
        } catch ( error ) {
            // error messages from OpenId Connect are error_description, JS errors are message
            const errorMessage = error ? ( error.error_description || error.message ) : '';

            if ( ( isResuming && signInOnResumeError ) ) {
                console.error( error );

                let cancelRetry = false;
                events.signInError.fire( { error, preventDefault: () => cancelRetry = true } );
                if ( cancelRetry ) {
                    return trace.end();
                }

                // remove the current user
                await userManager.removeUser();
                await userManager.clearStaleState();
                // try signIn instead
                const result = await signInOrResume( false );
                return trace.end( result );
            } else if ( ERROR_MESSAGES_TO_IGNORE.includes( errorMessage ) ) {
                return trace.end();
            }

            console.error( error );
            events.signInError.fire( { error, preventDefault: () => false } );
        }
        finally {
            cache.signInOrResumePromise = undefined;
        }

        return trace.end();
    };
    const signInOrResumeHelper = async ( isResuming, session, signInOnResumeError ) => {
       const trace = traceStart( 'signInOrResumeHelper' );

        let user = null;
        const uri = getCurrentUri();
        let returnToUri = getCurrentUri( true );
        let isSessionExpired = false;

        switch ( uri ) {
            case options.redirectUri: // we are at the signin callback page
            case options.popupRedirectUri:
                // complete the flow
                if ( options.popup ) {
                    await userManager.signinPopupCallback();
                } else {
                    await userManager.signinRedirectCallback();
                }
                // restore the location
                returnToUri = storage.getItem( returnToKey );
                // if nothing was stored return to the current uri
                if ( !returnToUri ) {
                    returnToUri = getRootUri( true );
                }
                // get the user
                user = await getUser();
                // check to see if recovery methods are needed.
                let returnToType = LocationType.SIGN_IN;
                if ( user && user.profile ) {
                    if (
                        options.checkRecoveryMethods &&
                        user.profile.recovery_methods_set === false &&
                        !( user.profile.act && user.profile.act.type === 'proxy' )
                    ) {
                        if ( user.profile.recovery_methods_url ) {
                            returnToType = LocationType.RECOVERY_METHODS;
                            let source = encodeURIComponent( returnToUri );
                            if ( source === 'undefined' ) {
                                source = encodeURIComponent( getRootUri( true ) );
                            }
                            returnToUri = `${ user.profile.recovery_methods_url }?source=${ source }`;
                        } else {
                            console.error( 'No Recovery Methods URL set' );
                        }
                    } else if ( user.profile.status === 'inactive' ) {
                        if ( user.profile.marketplace_login_url ) {
                            returnToType = LocationType.MARKETPLACE_LOGIN;
                            returnToUri = user.profile.marketplace_login_url;
                        } else {
                            console.error( 'No Marketplace Login URL set' );
                        }
                    }
                }
                // set the location
                setLocation( returnToType, returnToUri );
                break;
            case options.silentRedirectUri: // we are at the renew callback page (iframe)
                // complete the flow
                await userManager.signinSilentCallback();
                // get the user
                user = await getUser();
                break;
            case options.postLogoutRedirectUri: // we are at the signout callback page
                // complete the flow
                if ( options.popup ) {
                    await userManager.signoutPopupCallback();
                } else {
                    await userManager.signoutCallback();
                }
                // remove the user
                await userManager.removeUser();
                await userManager.clearStaleState();
                // fire the sign out event
                events.signOut.fire();
                // restore the location
                isSessionExpired = (
                    storage.getItem( sessionExpiredKey ) === 'true'
                );
                returnToUri = storage.getItem( returnToKey );
                if ( !returnToUri ) {
                    returnToUri = getRootUri( true );
                }

                if ( isSessionExpired ) {
                    sessionExpired();
                } else {
                    // set the location
                    setLocation( LocationType.SIGN_OUT, returnToUri );
                }

                break;
            default: // we are at a normal page
                // save the location
                if ( isAuthUri( returnToUri ) ) {
                    returnToUri = storage.getItem( returnToKey );
                    if ( !returnToUri ) {
                        returnToUri = getRootUri( true );
                    }
                }
                storage.setItem( returnToKey, returnToUri );
                // get the user
                user = await getUser();
                // if there is no user or is expired, try to sign in
                if ( user && !user.expired && ( !session || session.session_state === user.session_state ) ) {
                    // restart the activity timer when coming back to the page
                    if ( options.enableActivityTimeout ) {
                        activityTimeout.recordActivity();
                    }
                    // if a user is signed in, fire the sign in event
                    // so the component can update its state
                    events.signIn.fire( state );
                    return;
                }
                // clear the user
                await userManager.removeUser();
                await userManager.clearStaleState();
                if ( !isResuming ) {
                    // allow canceling the sign in
                    let cancelSignIn = false;
                    events.signInNeeded.fire( { preventDefault: () => cancelSignIn = true } );
                    if ( cancelSignIn ) {
                        return;
                    }
                }
                // begin the flow
                if ( options.popup ) {
                   await userManager.signinPopup();
                   // get the user
                    user = await getUser();
                    // if there is no user or is expired, try to sign in
                    if ( user && !user.expired && ( !session || session.session_state === user.session_state ) ) {
                        // if a user is signed in, fire the sign in event
                        // so the component can update its state
                        events.signIn.fire( state );
                    }
                } else {
                    await userManager.signinRedirect();
                }

                break;
        }

        return trace.end();
    };

    const signOut = async ( options ) => {
        if ( isAuthUri() ) {
            return;
        }

        const user = await getUser();
        if ( !user ) {
            return;
        }

       const trace = traceStart( 'signOut' );

        // make sure sign out is only called once at a time
        // the auth process keeps some state and this can get mismatched if called multiple times
        if ( cache.signOutPromise ) {
            const p = cache.signOutPromise;
            return await Promise.resolve( p );
        }

        try {
            const p = signOutHelper( options );
            cache.signInOrResumePromise = p;
            const result = await Promise.resolve( p );
            return trace.end( result );
        } catch ( error ) {
            console.error( error );
            events.signOutError.fire( { error } );
        } finally {
            cache.signOutPromise = undefined;
        }

        return trace.end();
    };
    const signOutHelper = async ( signOutOptions = {} ) => {
       const trace = traceStart( 'signOutHelper' );

        let returnToUri = signOutOptions.returnToUri;
        if ( !returnToUri ) {
            returnToUri = getCurrentUri( true );
        }

        if ( signOutOptions.sessionExpired ) {
            storage.setItem( sessionExpiredKey, 'true' );

        } else {
            storage.setItem( sessionExpiredKey, '' );
        }

        // save the location
        if ( isAuthUri( returnToUri ) ) {
            returnToUri = storage.getItem( returnToKey );
            if ( !returnToUri ) {
                returnToUri = getRootUri( true );
            }
        }
        storage.setItem( returnToKey, returnToUri );

        if ( activityTimeout ) {
            activityTimeout.stopChecking();
        }

        if ( options.popup ) {
            await userManager.signoutPopup();
        } else {
            await userManager.signoutRedirect();
        }

        return trace.end();
    };

    const hasSession = async () => {
       const trace = traceStart( 'hasSession' );

        try {
            const metadata = await userManager.metadataService.getMetadata();
            const sessionEndpoint = metadata.token_endpoint.replace( '/token', '/session' );

            let query = '';
            const storageToken = await userManager._userStore.get( userManager._userStoreKey );
            if ( storageToken && typeof storageToken === 'string' ) {
                query = `?id_token_hint=${ encodeURIComponent( JSON.parse( storageToken ).id_token ) }`;
            }

            const res = await fetch( `${ sessionEndpoint }${ query }`, {
                credentials: 'include'
            } );

            const result = await res.json();
            return trace.end( result.success );
        } catch {
            const session = await getSession();
            return trace.end( !!session );
        }
    };

    const getSession = async () => {
       const trace = traceStart( 'getSession' );

        try {
            const result = await userManager.querySessionStatus();
            return trace.end( result );
        } catch ( error ) {
            console.debug( error ); // using to debug to keep it out of non-verbose logging
            return trace.end( null );
        }
    };

    function getSSOToken() {
       const trace = traceStart( 'getSSOToken' );

        if ( location.hash && location.hash.indexOf( 'sso_token=' ) > 0 ) {
            return trace.end( location.hash.substring( location.hash.indexOf( 'sso_token=' ) ).split( '=' )[ 1 ] );
        }
        return trace.end( null );
    }

    const delegate = async ( company, subject ) => {
       const trace = traceStart( 'delegate' );

        const metadata = await userManager.metadataService.getMetadata();
        const user = await userManager.getUser();

        if ( metadata && state.original.access_token ) {
            const subjectHeader = base64Encode( JSON.stringify( {
                alg: 'none'
            } ) );
            const subjectPayload = base64Encode( JSON.stringify( {
                aud: options.clientId,
                cmp: company,
                sub: subject || state.original.subject,
                may_act: {
                    aud: options.clientId,
                    cmp: state.original.claims.company_id,
                    sub: state.original.subject
                }
            } ) );

            const subjectToken = `${ subjectHeader }.${ subjectPayload }.`;

            const response = await fetch( metadata.token_endpoint, {
                method: 'POST',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded'
                },
                body: [
                    `grant_type=${ encodeURIComponent( 'urn:ietf:params:oauth:grant-type:token-exchange' ) }`,
                    `&subject_token=${ subjectToken }`,
                    `&subject_token_type=${ encodeURIComponent( 'urn:ietf:params:oauth:token-type:jwt' ) }`,
                    `&actor_token=${ state.original.access_token }`,
                    `&actor_token_type=${ encodeURIComponent( 'urn:ietf:params:oauth:token-type:access_token' ) }`,
                    `&client_id=${ encodeURIComponent( options.clientId ) }`
                ].join( '' )
            } );

            const data = await response.json();

            if ( response.ok ) {
                const payload = jwtDecode( data.access_token );
                // get the user info for the delegate
                const userInfoResponse = await fetch( metadata.userinfo_endpoint, {
                    headers: { authorization: `Bearer ${ data.access_token }` }
                } );
                const userInfo = await userInfoResponse.json();

                refreshState( user, {
                    access_token: data.access_token,
                    company_id: payload.cmp,
                    subject: payload.sub,
                    person_id: payload.person_id,
                    permissions: userInfo.permissions || {},
                    act: userInfo.act
                } );
                return trace.end( state );
            } else {
                const user = await userManager.getUser();
                refreshState( user );
                trace.end();
                throw new Error( data.message || data.error_description );
            }
        }
    };

    const assumeIdentity = async (
        actorType,
        subjectCompanyId,
        subjectAccount,
        location = window.location.href,
        postRestoreLocation = window.location.href,
        target = '_self',
        validator = ''
    ) => {
       const trace = traceStart( 'assumeIdentity' );

        const metadata = await userManager.metadataService.getMetadata();
        const query = validator ? `?validator=${ validator }` : '';

        if ( metadata && state.original.access_token ) {
            const form = document.createElement( 'form' );
            form.setAttribute( 'method', 'POST' );

            form.setAttribute( 'action', metadata.token_endpoint.replace( '/token', `/assume-identity${ query }` ) );
            form.setAttribute( 'target', target );

            const clientIdInput = document.createElement( 'input' );
            clientIdInput.setAttribute( 'type', 'hidden' );
            clientIdInput.setAttribute( 'name', 'client_id' );
            clientIdInput.setAttribute( 'value', options.clientId );
            form.appendChild( clientIdInput );

            const actorTypeInput = document.createElement( 'input' );
            actorTypeInput.setAttribute( 'type', 'hidden' );
            actorTypeInput.setAttribute( 'name', 'actor_type' );
            actorTypeInput.setAttribute( 'value', actorType );
            form.appendChild( actorTypeInput );

            const actorTokenInput = document.createElement( 'input' );
            actorTokenInput.setAttribute( 'type', 'hidden' );
            actorTokenInput.setAttribute( 'name', 'actor_token' );
            actorTokenInput.setAttribute( 'value', state.original.access_token );
            form.appendChild( actorTokenInput );

            const subjectCompanyInput = document.createElement( 'input' );
            subjectCompanyInput.setAttribute( 'type', 'hidden' );
            subjectCompanyInput.setAttribute( 'name', 'subject_company_id' );
            subjectCompanyInput.setAttribute( 'value', subjectCompanyId );
            form.appendChild( subjectCompanyInput );

            if ( subjectAccount ) {
                const subjectAccountInput = document.createElement( 'input' );
                subjectAccountInput.setAttribute( 'type', 'hidden' );
                subjectAccountInput.setAttribute( 'name', 'subject_account' );
                subjectAccountInput.setAttribute( 'value', subjectAccount );
                form.appendChild( subjectAccountInput );
            }

            const locationInput = document.createElement( 'input' );
            locationInput.setAttribute( 'type', 'hidden' );
            locationInput.setAttribute( 'name', 'location' );
            locationInput.setAttribute( 'value', location );
            form.appendChild( locationInput );

            const postRestoreLocationInput = document.createElement( 'input' );
            postRestoreLocationInput.setAttribute( 'type', 'hidden' );
            postRestoreLocationInput.setAttribute( 'name', 'post_restore_location' );
            postRestoreLocationInput.setAttribute( 'value', postRestoreLocation );
            form.appendChild( postRestoreLocationInput );

            document.body.appendChild( form );
            form.submit();
        }

        return trace.end();
    };

    const restoreIdentity = async ( target = '_self' ) => {
       const trace = traceStart( 'restoreIdentity' );

        const metadata = await userManager.metadataService.getMetadata();

        if ( metadata && state.original.access_token ) {
            const form = document.createElement( 'form' );
            form.setAttribute( 'method', 'POST' );
            form.setAttribute( 'action', metadata.token_endpoint.replace( '/token', '/restore-identity' ) );
            form.setAttribute( 'target', target );

            const clientIdInput = document.createElement( 'input' );
            clientIdInput.setAttribute( 'type', 'hidden' );
            clientIdInput.setAttribute( 'name', 'client_id' );
            clientIdInput.setAttribute( 'value', options.clientId );
            form.appendChild( clientIdInput );

            const subjectTokenInput = document.createElement( 'input' );
            subjectTokenInput.setAttribute( 'type', 'hidden' );
            subjectTokenInput.setAttribute( 'name', 'subject_token' );
            subjectTokenInput.setAttribute( 'value', state.original.access_token );
            form.appendChild( subjectTokenInput );

            document.body.appendChild( form );
            form.submit();
        }

        return trace.end();
    };

    const refresh = async ( authState ) => {
       const trace = traceStart( 'refresh' );

        if ( !authState ) {
            authState = state;
        }
        const refreshToken = authState.refresh_token;
        if ( !refreshToken ) {
            return;
        }

        const metadata = await userManager.metadataService.getMetadata();

        const response = await fetch( metadata.token_endpoint, {
            method: 'POST',
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            body: [
                'grant_type=refresh_token',
                `&refresh_token=${ refreshToken }`,
                `&client_id=${ encodeURIComponent( options.clientId ) }`,
                options.clientSecret ? `&client_secret=${ encodeURIComponent( options.clientSecret ) }` : ''
            ].join( '' )
        } );

        const data = await response.json();

        if ( response.ok ) {
            const hasDelegateToken = !!authState.delegate.access_token;
            const delegateCompany = authState.delegate.claims.company_id;
            const delegateSubject = authState.delegate.subject;

            refreshState( {
                id_token: authState.id_token,
                access_token: data.access_token,
                expires_at: Math.floor( new Date().valueOf() / 1000 ) + data.expires_in,
                refresh_token: data.refresh_token,
                profile: {
                    sub: authState.original.subject,
                    company_id: authState.original.claims.company_id,
                    person_id: authState.original.claims.person_id,
                    preferred_username: authState.original.claims.username,
                    name: authState.original.claims.name,
                    family_name: authState.original.claims.family_name,
                    given_name: authState.original.claims.given_name,
                    locale: authState.original.claims.locale,
                    country: authState.original.claims.country,
                    email: authState.original.claims.email,
                    emails: authState.original.claims.emails,
                    phone_number: authState.original.claims.phone_number,
                    phone_numbers: authState.original.claims.phone_numbers,
                    address: authState.original.claims.address,
                    addresses: authState.original.claims.addresses,
                    picture: authState.original.claims.picture,
                    permissions: authState.original.claims.permissions,
                    attributes: authState.original.claims.attributes,
                    sid: authState.session_id,
                    act: authState.original.act
                }
            } );

            if ( hasDelegateToken ) {
                await delegate( delegateCompany, delegateSubject );
            }

            return trace.end( data );
        } else {
            trace.end();
            throw data;
        }
    };

    const renew = async () => {
       const trace = traceStart( 'renew' );

        const hasDelegateToken = !!state.delegate.access_token;
        const delegateCompany = state.delegate.claims.company_id;
        const delegateSubject = state.delegate.subject;

        await userManager.signinSilent();

        if ( hasDelegateToken ) {
            delegate( delegateCompany, delegateSubject );
        } else {
            const user = await userManager.getUser();
            refreshState( user );
        }

        return trace.end();
    };

    const setLocation = async ( type, location ) => {
       const trace = traceStart( 'setLocation' );

        let cancel = false;
        const args = {
            type,
            location,
            preventDefault: () => cancel = true
        };

        await events.setLocation.fire( args );

        trace.end();

        if ( !cancel ) {
            window.location = args.location;
        }
    };

    refreshState();

    function sessionExpired() {
       const trace = traceStart( 'sessionExpired' );

        let cancel = false;
        events.sessionExpired.fire( { preventDefault: () => cancel = true } );
        if ( cancel ) {
            return trace.end();
        }

        trace.end();

        const userManager2 = new UserManager( getUserManagerConfig( {
            ...options,
            extraQueryParams: {
                ...( options.extraQueryParams || {} ),
                sessionExpired: 'true'
            }
        } ) );
        userManager2.clearStaleState();
        userManager2.signinRedirect();
    }

    const getMetadata = async () => {
        const trace = traceStart( 'getMetadata' );

        const result = await userManager.metadataService.getMetadata();

        return trace.end( result );
    };

    //subscribe to expiring
    if ( options.monitorAccessTokenExpiration ) {
        userManager.events.addAccessTokenExpiring( renew );
        userManager.events.addAccessTokenExpired( sessionExpired );
    }

    // timeout sessions if there isn't any activity (if enabled)
    const activityTimeout = new ActivityTimeout( options );
    if ( options.enableActivityTimeout ) {
        if ( activityTimeout.checkForTimeout() ) {
            signOut( { sessionExpired: false } );
        }

        activityTimeout.startChecking(
            ( e ) => {
                events.sessionExpiring.fire( e );
            },
            ( forced ) => {
                signOut( { sessionExpired: !forced } );
            }
        );
    }

    //check for expired session when the tab comes into focus
    const handleVisibilityChange = async () => {
       const trace = traceStart( 'handleVisibilityChange' );

        if ( !document.hidden ) {

            if ( options.enableActivityTimeout ) {
                // check to see if the session has expired from inactivity
                if ( activityTimeout.checkForTimeout() ) {
                    signOut( { sessionExpired: true } );
                } else {
                    // restart the activity timer when coming back to the page
                    activityTimeout.recordActivity();
                }
            }

            let expiration = getExpiration( state.access_token );

            if ( expiration && expiration.valueOf() < new Date().valueOf() ) {
                const session = await getSession();
                if ( session ) {
                    await renew();
                    expiration = getExpiration( state.access_token );
                }
            }
            if ( expiration && expiration.valueOf() < new Date().valueOf() ) {
                sessionExpired();
            }
        }

        return trace.end();
    };

    if ( options.monitorAccessTokenExpiration ) {
        document.addEventListener( 'visibilitychange', handleVisibilityChange, false );
    }

    const module = {
        getUser,
        removeUser,
        refreshState,
        getRootUri,
        getCurrentUri,
        isAuthUri,
        signIn,
        signOut,
        resume,
        getSession,
        hasSession,
        getSSOToken,
        delegate,
        assumeIdentity,
        restoreIdentity,
        renew,
        refresh,
        setLocation,
        getMetadata,
    };

    return {
        get events() { return events; },
        get options() { return options;},
        get state() { return state; },
        ...module,
    };
}

export const getClientIdWithIdentifier = ( clientId, identifier ) => {
    if ( !identifier ) {
        return clientId;
    }
    return `${ clientId }; identifier=${ identifier }`;
};

export const identifyClient = async( authority, clientId, clientSecret ) => {
    if ( !clientId ) {
        return {
            success: false
        };
    }

    const response = await fetch( `${ authority }/clients/identify`, {
        method: 'POST',
        headers: {
            'content-type': 'application/x-www-form-urlencoded'
        },
        body: [
            `&client_id=${ encodeURIComponent( clientId ) }`,
            clientSecret ? `&client_secret=${ encodeURIComponent( clientSecret ) }` : ''
        ].join( '' )
    } );

    return await response.json();
};



export const generateCodeVerifier = async() => {
    return `${ random() }${ random() }${ random() }`;
};

export const generateCodeChallange = async( codeVerifier ) => {
    return Base64.stringify( sha256( codeVerifier ) )
        .replace( /\+/g, '-' )
        .replace( /\//g, '_' )
        .replace( /=/g, '' );
};

export const LocationType = {
    SIGN_IN: 'sign_in',
    SIGN_OUT: 'sign_out',
    RECOVERY_METHODS: 'recovery_methods'
};

function getUserManagerConfig( options ) {
    const config = {};

    const mapping = {
        'authority': 'authority',
        'clientId': 'client_id',
        'clientSecret': 'client_secret',
        'redirectUri': 'redirect_uri',
        'responseType': 'response_type',
        'responseMode': 'response_mode',
        'scope': 'scope',
        'prompt': 'prompt',
        'display': 'display',
        'maxAge': 'max_age',
        'uiLocales': 'ui_locales',
        'loginHint': 'login_hint',
        'acrValues': 'acr_values',
        'clockSkew': 'clockSkew',
        'loadUserInfo': 'loadUserInfo',
        'filterProtocolClaims': 'filterProtocolClaims',
        'postLogoutRedirectUri': 'post_logout_redirect_uri',
        'popupRedirectUri': 'popup_redirect_uri',
        'popupWindowFeatures': 'popupWindowFeatures',
        'popupWindowTarget': 'popupWindowTarget',
        'silentRedirectUri': 'silent_redirect_uri',
        'automaticSilentRenew': 'automaticSilentRenew',
        'silentRequestTimeout': 'silentRequestTimeout',
        'accessTokenExpiringNotificationTime': 'accessTokenExpiringNotificationTime',
        'userStore': 'userStore',
        'monitorSession': 'monitorSession',
        'checkSessionInterval': 'checkSessionInterval',
        'revokeAccessTokenOnSignOut': 'revokeAccessTokenOnSignout',
        'includeIdTokenInSilentRenew': 'includeIdTokenInSilentRenew',
        'staleStateAge': 'staleStateAge',
        'extraQueryParams': 'extraQueryParams',
        'clientAuthentication': 'client_authentication'
    };

    Object.keys( mapping ).forEach( ( key ) => {
        if ( options[ key ] !== undefined ) {
            config[ mapping[ key ] ] = options[ key ];
        }
    } );

    return config;
}

function getExpiration( jwt ) {
    if ( !jwt ) {
        return null;
    }
    return new Date( jwtDecode( jwt ).exp  * 1000 );
}
function base64Encode( str ) {
    return btoa( encodeURIComponent( str ).replace(
        /%([0-9A-F]{2})/g,
        ( match, p1 ) => String.fromCharCode( '0x' + p1 )
    ) );
}

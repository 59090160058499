import { Games, Play, PreloadImage, Translate } from '@nackle/arcade-core-v2';
import { Spin } from '@nackle/paper';
import React from 'react';
import { connect } from 'react-redux';
import { FloorText } from '../../translation-data';

import './InstantPlay.less';

const GameImageBackground = ( { url } ) => (
    <div
        className="instant-play-cover-image-background"
        style={ {
            backgroundImage: `url(${ url })`,
        } }
    />
);

const GameImageForeground = ( { url } ) => (
    <img
        alt=""
        className="instant-play-cover-image-foreground"
        src={ url }
    />
);

const mapDispatchToProps = {
    openGame: Play.openGame,
};

class InstantPlay extends React.Component {
    state = {};

    render() {
        const {
            cabinet,
            playCount,
            roomId,
        } = this.props;

        const {
            foregroundLoaded,
            backgroundLoaded,
        } = this.state;

        const gameSlug = cabinet.name.replace( /\W+/g, '_' ).toLocaleLowerCase();

        const foregroundImageUrl = cabinet.instantPlayForegroundImageUrl;
        const backgroundImageUrl = cabinet.instantPlayBackgroundImageUrl
            || cabinet.image
            || cabinet.bannerImage;

        const imagesLoaded = ( foregroundLoaded || !foregroundImageUrl ) && backgroundLoaded;

        return (
            <Translate
                fallback={ cabinet.name }
                id={ Games.cmxKeys.formatCmxRomNameKey( cabinet.cabinetId ) }
                stripHtml
            >
                { ( gameName ) => (
                    <Translate
                        id={ FloorText.INSTANT_PLAY_GAME_LABEL }
                        args={ {
                            gameName,
                            playCount,
                        } }
                    >
                        { ( label ) =>
                            <button
                                className={ 'nkl-card nkl-card-hoverable instantPlay-container' }
                                data-cabinet-id={ cabinet.cabinetId }
                                data-rom-id={ cabinet.romId }
                                data-room-id={ roomId }
                                id="game-name-link"
                                onClick={ this.handleClick }
                                aria-label={ label }
                                title={ label }
                            >
                                { foregroundImageUrl &&
                                    (
                                        <PreloadImage
                                            url={ foregroundImageUrl }
                                            onPreloadComplete={ this.handleForegroundPreloaded }
                                        />
                                    )
                                }
                                <PreloadImage
                                    url={ backgroundImageUrl }
                                    onPreloadComplete={ this.handleBackgroundPreloaded }
                                />

                                { !imagesLoaded && <Spin className="instant-play-cover-image-spinner" /> }
                                { imagesLoaded && <GameImageBackground url={ backgroundImageUrl } /> }
                                { imagesLoaded && foregroundImageUrl && <GameImageForeground url={ foregroundImageUrl } /> }

                                <div className="instantPlay-plays" id={ `instant_play_${ gameSlug }_play_count` }>
                                    <Translate
                                        args={ { playCount, playCountClass: 'instantPlay-lozenge' } }
                                        id={ FloorText.PLAY_COUNT_LABEL }
                                        parseHtml
                                    />
                                </div>
                            </button>
                        }
                    </Translate>
                ) }
            </Translate>
        );
    }

    handleClick = () => {
        const { cabinet, openGame, roomId } = this.props;
        openGame( cabinet.cabinetId, roomId ).catch( ( e ) => console.error( e ) );
    };

    handleForegroundPreloaded = () => this.setState( { foregroundLoaded: true } );
    handleBackgroundPreloaded = () => this.setState( { backgroundLoaded: true } );
}

export default connect( null, mapDispatchToProps )( InstantPlay );

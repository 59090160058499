import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
const { Header } = Layout;
import { Select as Nav } from '../select/select/select';
import { ChevronDown, Check } from '@nackle/origami-icons';

export const navOptions = [
    {
        label: 'Origami',
        value: 'https://nackle.biw-pages.com/paper/origami/'
    },
    {
        label: 'Mesabi',
        value: 'https://nackle.biw-pages.com/paper/mesabi/'
    },
    {
        label: 'Crane',
        value: 'https://nackle.biw-pages.com/paper/crane/'
    },
    {
        label: 'Origami Icons',
        value: 'https://nackle.biw-pages.com/paper/origami-icons/'
    },

];

const GlobalNav = ( { options, value, onChange, children, ...props } ) => {
    const [ computedValue, setComputedValue ] = useState();
    const handleGlobalNav = ( value ) => {
        window.location.href = value;
        if ( typeof onChange === 'function' ) {
            onChange( value );
        }
    };
    useEffect( () => {
        const location = window.location.href;
        const currentValue = navOptions.filter( option => location.indexOf( option.value ) > -1 );
        if ( currentValue && currentValue.length > 0 ) {
            setComputedValue( currentValue[ 0 ].value );
        }
    }, [ value ] );
    return (
        <Header className='global-nav-header'>
            <div className='global-nav'>

                <Nav
                    options={ options ? options : navOptions }
                    className='global-nav'
                    suffixIcon={ <ChevronDown /> }
                    menuItemSelectedIcon={ <Check /> }
                    defaultValue={ value || computedValue }
                    onChange={ handleGlobalNav }
                    { ...props }
                >
                </Nav>

            </div>
            { children }
        </Header>
    );
};
export { GlobalNav };
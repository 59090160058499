import React from 'react';
import { Select as AntSelect } from 'antd';
import { Spin } from '../../spin/spin';
import { ChevronDown, Close, Check } from '@nackle/origami-icons';
const Select = ( { ...props } ) => {
    const { size, loading, notFoundContent } = props;
    switch ( size ) {
        case 'small':
            return (
                <AntSelect
                    removeIcon={ <Close /> }
                    notFoundContent={ loading ? <Spin size="small" spinning={ true } /> : notFoundContent }
                    suffixIcon={ <ChevronDown /> }
                    menuItemSelectedIcon={ <Check /> }
                    { ...props }
                >
                </AntSelect>
            );
        case 'middle':
            return (
                <AntSelect
                    removeIcon={ <Close /> }
                    notFoundContent={ loading ? <Spin size="small" spinning={ true } /> : notFoundContent }
                    suffixIcon={ <ChevronDown /> }
                    menuItemSelectedIcon={ <Check /> }
                    { ...props }
                >
                </AntSelect>
            );
        case 'large':
            return (
                <AntSelect
                    removeIcon={ <Close /> }
                    notFoundContent={ loading ? <Spin size="small" spinning={ true } /> : notFoundContent }
                    suffixIcon={ <ChevronDown /> }
                    menuItemSelectedIcon={ <Check /> }
                    { ...props }
                >
                </AntSelect>
            );
        default:
            return (
                <AntSelect
                    removeIcon={ <Close /> }
                    notFoundContent={ loading ? <Spin size="small" spinning={ true } /> : notFoundContent }
                    suffixIcon={ <ChevronDown /> }
                    menuItemSelectedIcon={ <Check /> }
                    { ...props }
                >
                </AntSelect>

            );
    }
};


export { Select };

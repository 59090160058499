/**
 * Expose a Promise's "resolve" and "reject" callbacks so they can be called from outside the Promise scope.
 */
export class Deferred {

    constructor( resolve, reject ) {
        this.resolve;
        this.reject;
    }

    resolve( thing ) {
        return thing;
    }

    reject( err ) {
        return err;
    }
    promise = new Promise( ( resolveFn, rejectFn ) => {
        this.resolve = resolveFn;
        this.reject = rejectFn;
    } );
}

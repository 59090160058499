//import { Reward } from '@nackle/game-loader-comm-api';

/**
 * Kame always stores points as a decimal number (e.g. "350.0").
 *
 * Games don't know this though, and they're likely to display this string verbatim.
 * This function strips off the ".0" from points rewards
 */
export function fixDecimalPointsBug( rewards ) {
    return rewards.map( ( reward ) =>
        reward.type === 'POINTS' ?
            { ...reward, value: stripPointZero( reward.value ) } :
            reward,
    );
}

/**
 * Safely strip off the ".0" from the end of a Kame points reward amount (e.g. "350.0"),
 * but ONLY if it's a whole number.
 */
function stripPointZero( pointsValue ) {
    if ( typeof pointsValue !== 'string' ) {
        return pointsValue;
    }

    const asNumber = Number( pointsValue );
    if ( isNaN( asNumber ) ) {
        return pointsValue;
    }

    return asNumber.toString( 10 );
}

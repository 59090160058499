import React, { forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import { composeRef } from 'rc-util/lib/ref';
import { Close, Search as SearchIcon } from '@nackle/origami-icons';
import { Input } from './input';
import { Button } from '../../button/button';
import { getString } from '@nackle/intl-tools';

const Search = forwardRef( ( props, ref ) => {
    const {
        size,
        suffix,
        enterButton = false,
        addonAfter,
        loading,
        disabled,
        onSearch: customOnSearch,
        onChange: customOnChange,
        iconRight,
        label,
        labelProps,
        caption,
        captionProps,
        ...restProps
    } = props;

    const composedRef = useRef( false );
    const inputRef = useRef( null );

    const searchIcon = typeof enterButton === 'boolean' ? <SearchIcon /> : null;

    const onChange = ( e ) => {
        if ( e && e.target && e.type === 'click' && customOnSearch ) {
            customOnSearch( e.target.value, e );
        }
        if ( customOnChange ) {
            customOnChange( e );
        }
    };

    const onMouseDown = ( e ) => {
        if ( document.activeElement === inputRef.current?.input ) {
            e.preventDefault();
        }
    };

    const onSearch = ( e ) => {
        if ( customOnSearch ) {
            customOnSearch( inputRef.current?.input?.value, e );
        }
    };

    const onPressEnter = ( e ) => {
        if ( composedRef.current || loading ) {
            return;
        }
        onSearch( e );
    };
    labelProps ? labelProps.size = size : '';
    captionProps ? captionProps.size = size : '';
    const searchButton = (
        <Button
            className='ant-input-search-icon'
            type={ enterButton ? 'primary' : undefined }
            size={ size }
            disabled={ disabled }
            key='enterButton'
            onMouseDown={ onMouseDown }
            onClick={ onSearch }
            loading={ loading }
            icon={ searchIcon }
            aria-label={ getString( 'origami.input.search' ) }
        >
            { enterButton }
        </Button>
    );

    return (
        enterButton ? (
            <Input
                ref={ composeRef( inputRef, ref ) }
                className='ant-input-search'
                size={ size }
                onPressEnter={ onPressEnter }
                addonAfter={ searchButton }
                allowClear={ props.allowClear ? { clearIcon: <Close /> } : false }
                suffix={ suffix }
                onChange={ onChange }
                disabled={ disabled }
                label={ label ? label : undefined }
                labelProps={ label ? labelProps : undefined }
                caption={ caption ? caption : undefined }
                captionProps={ caption ? captionProps : undefined }
                { ...restProps }
            />
        ) : iconRight ? (
            <Input
                ref={ composeRef( inputRef, ref ) }
                size={ size }
                onPressEnter={ onPressEnter }
                className='ant-input-search'
                suffix={ searchButton }
                onChange={ onChange }
                disabled={ disabled }
                label={ label ? label : undefined }
                labelProps={ label ? labelProps : undefined }
                caption={ caption ? caption : undefined }
                captionProps={ caption ? captionProps : undefined }
                { ...restProps }
            />
        ) : (
            <Input
                ref={ composeRef( inputRef, ref ) }
                size={ size }
                onPressEnter={ onPressEnter }
                className='ant-input-search'
                prefix={ searchButton }
                onChange={ onChange }
                disabled={ disabled }
                label={ label ? label : undefined }
                labelProps={ label ? labelProps : undefined }
                caption={ caption ? caption : undefined }
                captionProps={ caption ? captionProps : undefined }
                { ...restProps }
            />
        )
    );
} );
Search.propTypes = {
    /** input size */
    size: PropTypes.oneOf( [ 'large', 'middle', 'small' ] ),
    /** icon to the right of input */
    suffix: PropTypes.node,
    /** allow enter to search or string/node for inside button */
    enterButton: PropTypes.oneOfType( [
        PropTypes.bool,
        PropTypes.node
    ] ),
    /** loading state */
    loading: PropTypes.bool,
    /** disabled state */
    disabled: PropTypes.bool,
    /** onSearch callback */
    customOnSearch: PropTypes.func,
    /** onChange callback */
    customOnChange: PropTypes.func,
    /** display search icon on right side of input */
    iconRight: PropTypes.bool,
};

export default Search;

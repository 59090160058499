import React, { useState } from 'react';
import { Button, Image as Img } from 'antd';
import { LocaleString } from '@nackle/intl-tools';
import { Eye, CloseLarge } from '@nackle/origami-icons';

const MaskComponent = () => {
    return (
        <div className='preview-image'>
            <Eye/>
            <span><LocaleString code="origami.image.preview" /></span>
        </div> );
};

const Image = ( props ) => {
    const { className, src } = props;
    const [ visible, setVisible ] = useState( false );
    const [ error, setError ] = useState( false );
    const fallbackImage = 'https://api.biw.cloud/v1/images/edd21eb7-2c21-485e-95b6-afe834ca9fe7';
    const handleCancel = () => {
        setVisible( false );
    };
    const showModal = () => {
        setVisible( true );
    };
    return (
        <div className={ className ? `image-container ${ className }` : 'image-container' } >
            <Img
                src={ src }
                onClick={ showModal }
                onError={ () => setError( true ) }
                preview={ {
                    visible: visible,
                    imageRender: () => (
                        <div className='image-preview'>
                            <img src={ error ? fallbackImage : src } alt='Preview' />
                            <Button onClick={ handleCancel }><CloseLarge width={ 20 } height={ 20 } /></Button>
                        </div>
                    ),
                    toolbarRender: () => null,
                    mask: (
                        <MaskComponent />
                    ),
                    
                } }
                fallback={ fallbackImage }
                { ...props }

            />
        </div> );
};
export { Image };

import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Row, Col, Button } from '../index';
import { Progress } from 'antd';
import { Upload, MathPlusLarge, Attachment, CircleCloseFilled, ErrorFilled } from '@nackle/origami-icons';
import classNames from 'classnames';
import { getString } from '@nackle/intl-tools';
import { LocaleString } from '@nackle/intl-tools';

const NackleUpload = ( props ) => {
    
    const [ allFiles, setAllFiles ] = useState( [] );
    const [ stateAcceptedFiles, setStateAcceptedFiles ] = useState( [] );
    const [ stateFileRejections, setStateFileRejections ] = useState( [] );
    const [ progress, setProgress ] = useState( { } );
    const [ isUploaded, setIsUploaded ] = useState( false );

    const onDrop = ( newAcceptedFiles, newFileRejections )  => {
        if ( props.multiple === true ) {
            const currentStateAcceptedFiles = stateAcceptedFiles;
            const updatedAcceptedFiles = Array.from( [ ...new Set( [ ...currentStateAcceptedFiles, ...newAcceptedFiles ] ) ] );
            setStateAcceptedFiles( updatedAcceptedFiles );

            const currentStateFileRejections = stateFileRejections;
            const updatedFileRejections = Array.from( [ ...new Set( [ ...currentStateFileRejections, ...newFileRejections ] ) ] );
            setStateFileRejections( updatedFileRejections );
    
            const currentAllFiles = allFiles;
            const newAllFiles = Array.from( [ ...new Set( [ ...currentAllFiles, ...updatedAcceptedFiles, ...updatedFileRejections ] ) ] );
            setAllFiles( newAllFiles );

            if ( typeof props.onDrop === 'function' ) {
                props.onDrop( updatedAcceptedFiles, updatedFileRejections );
            }
        } else {
            setStateAcceptedFiles( newAcceptedFiles );
            setStateFileRejections( newFileRejections );
            const newAllFiles = Array.from( [ ...new Set( [ ...newAcceptedFiles, ...newFileRejections ] ) ] );
            setAllFiles( newAllFiles );

            if ( typeof props.onDrop === 'function' ) {
                props.onDrop( newAcceptedFiles, newFileRejections );
            }
        }
        newAcceptedFiles.forEach( acceptedFile => {
            const fileReader = new FileReader();
            const currentProgress = progress;
            currentProgress[ acceptedFile.path ] = 0;
            setProgress( currentProgress );
            fileReader.readAsDataURL( acceptedFile );
            fileReader.addEventListener( 'loadend', ( event ) => {

                setTimeout( () => {
                    const currentProgressSet100 = progress;
                    currentProgressSet100 [ acceptedFile.path ] = 100;
                    setProgress( currentProgressSet100  );
                    setIsUploaded( true );
                        
                    setTimeout( () => {
                        const currentProgressCompleteLoading = progress;
                        delete currentProgressCompleteLoading[ acceptedFile.path ];
                        setProgress( currentProgressCompleteLoading );
                        setIsUploaded( false );
                    }, 500 );
                }, 500 );
            } );
        } );
    };

    const deleteFile = ( fileToDelete ) => {
        if ( fileToDelete.errors ) {
            const findFile = allFiles.find( existingFile => existingFile === fileToDelete );
            const existingFileRejections = stateFileRejections;
            const updatedFileRejections = existingFileRejections.filter( existingFile => existingFile !== findFile );
            setStateFileRejections( updatedFileRejections );
            const existingAllFiles = allFiles;
            const updatedAllFiles = existingAllFiles.filter( existingFile => existingFile !== findFile );
            setAllFiles( updatedAllFiles );
            if ( typeof props.onDelete === 'function' ) {
                props.onDelete( stateAcceptedFiles, updatedFileRejections );
            }
        } else {
            const findFile = allFiles.find( existingFile => existingFile === fileToDelete );
            const existingAcceptedFiles = stateAcceptedFiles;
            const updatedAcceptedFiles = existingAcceptedFiles.filter( existingFile => existingFile !== findFile );
            setStateAcceptedFiles( updatedAcceptedFiles );
            const existingAllFiles = allFiles;
            const updatedAllFiles = existingAllFiles.filter( existingFile => existingFile !== findFile );
            setAllFiles( updatedAllFiles );
            if ( typeof props.onDelete === 'function' ) {
                props.onDelete( updatedAcceptedFiles, stateFileRejections );
            }
        }
    };

    const handleErrorButtons = ( button ) => {
        setStateAcceptedFiles( [] );
        setStateFileRejections( [] );
        setAllFiles( [] );
        if ( typeof props.onDelete === 'function' ) {
            props.onDelete( [], [] );
        }
        if ( button === 'newUpload' ) {
            inputRef.current.click();
        }
    };



    const getIcon = () => {
        if ( props.size === 'large' ) {
            if ( stateFileRejections.length > 0 ) {
                return <ErrorFilled className='upload-icon error'/>;
            } else {
                return <Upload className='upload-icon' />;
            }
        } if ( props.size === 'small' ) {
            return <MathPlusLarge className='upload-icon' />;
        }
    };

    const errorMessageKeys = {
        'file-too-large': {
            'instructions': 'origami.upload.uploadInstuctionsError.fileTooLarge',
            'parameters': props.fileTooLarge
        },
        'file-too-small': {
            'instructions': 'origami.upload.uploadInstuctionsError.fileTooSmall',
            'parameters': props.fileTooSmall
        },
        'too-many-files': {
            'instructions': 'origami.upload.uploadInstuctionsError.tooManyFiles',
            'parameters': props.tooManyFiles
        },
        'file-invalid-type': {
            'instructions': 'origami.upload.uploadInstructionsError.fileInvalidType',
            'parameters': props.invalidType
        }
    };

    const getTextKey = ( textType ) => {
        if ( textType === 'instructions' ) {
            if ( stateFileRejections.length > 0 && props.size === 'large' ) {
                const errorCode = stateFileRejections[ 0 ].errors[ 0 ].code;
                return errorMessageKeys[ errorCode ].instructions;
            } else {
                //if you are using a large and a small upload component in the same place, and need them to have different upload instructions,
                //use the uploadInstructionsKey prop to specify different instructions for each one
                return ( props.uploadInstructionsKey || 'origami.upload.uploadInstructions' );
            }
        }
        if ( textType === 'parameters' ) {
            if ( stateFileRejections.length > 0 && props.size === 'large' ) {
                const errorCode = stateFileRejections[ 0 ].errors[ 0 ].code;
                return errorMessageKeys[ errorCode ].parameters;
            }
            return props.uploadParameters;
        }
    };

    const displayUploadedFiles = allFiles.map( file => {
        const uploadedFileClassNames = classNames( {
            'uploaded-file': true,
            'rejected': file.errors ? true : false,
            'accepted': file.errors ? false : true,
            'display-progress-bar': progress[ file.path ] !== undefined ? true : false,
        } );
        return (
            <li className={ uploadedFileClassNames }  key={ file.errors ? file.file.path : file.path }>
                <Row className='uploaded-file-row' justify={ 'space-between' }>
                    <Col className='icon-col'>
                        <Attachment/>
                    </Col>
                    <Col className={ 'path-col' }>
                        <div className='file-path'>{ file.errors ? file.file.path : file.path }</div>
                        { progress[ file.path ] !== undefined &&
                            <div className='progress-bar'>
                                <Progress size={ 'small' } percent={ progress[ file.path ] } showInfo={ false }></Progress>
                            </div>
                        }
                    </Col>
                    <Col className='delete-button-col'>
                        <Button 
                            className='delete-button' 
                            size={ 'large' } 
                            type={ 'text' } 
                            icon ={ <CircleCloseFilled/> }
                            onClick={ () => deleteFile( file ) }
                        />
                    </Col>
                </Row>
            </li>
        );
    } );



    const getSizeClassName = () => {
        if ( props.size === 'large' ) {
            return ( 'nackle-upload nackle-upload-large' ); 
        }
        if ( props.size === 'small' ) {
            if ( props.filePreviewMode === true ) {
                return ( 'nackle-upload nackle-upload-small nackle-upload-small-preview' );
            }
            else { return ( 'nackle-upload nackle-upload-small' ); }
        } else {
            return 'nackle-upload nackle-upload-medium';
        }
    };
    const sizeClassName = getSizeClassName();

    const uploadContainerClassName = classNames( {
        'uploaded': isUploaded,
        'upload-container': true,
    } );



    const { getRootProps, getInputProps, inputRef } = useDropzone( { 
        onDrop: onDrop, 
        maxSize: props.maxSize, 
        minSize: props.minSize,
        accept: props.accept, 
        multiple: props.multiple === true ? true : false,
        maxFiles: props.maxFiles, 
        noClick: ( props.size === 'large' && stateFileRejections.length > 0 ) ? true : false 
    } );

    return ( 
        <div className={ sizeClassName }>
            <div className={ uploadContainerClassName }>
                <div className={ 'upload-content' } { ...getRootProps() }>
                    <input style='display: none' { ...getInputProps() } />
                        <div className={ stateFileRejections.length > 0 ? 'dropzone-container error' : 'dropzone-container' }>

                            { ( props.size === 'large' || props.size === 'small' ) &&
                                <div className="upload-icon-div">{ getIcon() }</div>
                            }

                            <div className='upload-instructions'>
                                { stateFileRejections.length > 0 && props.size === 'large' ?
                                    <span>{ getString( getTextKey( 'instructions' ) ) }</span>
                                :
                                    <LocaleString code={ getTextKey( 'instructions' ) }/>
                                }
                            </div>
                            
                            { props.size === 'large' && 
                                <div className='large-upload-parameters'>
                                    <span>{ getTextKey( 'parameters' ) }</span>
                                </div>
                            }

                            { ( props.size === 'large' && stateFileRejections.length > 0 ) &&
                                <div className='error-buttons'>
                                    <Button 
                                        className={ 'cancel-button' }
                                        size={ 'small' } 
                                        shape={ 'round' } 
                                        onClick={ ( button ) => handleErrorButtons( 'cancel' ) }
                                    >
                                        { getString( 'origami.upload.cancelButton' ) }
                                    </Button>
                                    <Button 
                                        className={ 'new-upload-button' } 
                                        size={ 'small' } 
                                        shape={ 'round' } 
                                        type={ 'primary' } 
                                        onClick={ ( button ) => handleErrorButtons( 'newUpload' ) }
                                    >
                                        { getString( 'origami.upload.newUploadButton' ) }
                                    </Button>
                                </div>
                            }

                        </div>
                        { ( props.size === 'medium' || !props.size ) &&
                            <div className='medium-upload-parameters'>
                                <span>{ getTextKey( 'parameters' ) }</span>
                            </div>
                        }
                </div>
            </div>
            { 
                props.displayFiles &&
                    <div className={ props.size === 'small' ? 'uploaded-files-list-container uploaded-files-list-container-small' : 'uploaded-files-list-container' }>
                        <ul className={ allFiles.length > 1 ? 'uploaded-file-list display-multiple-files' : 'uploaded-file-list' }> 
                            { displayUploadedFiles }
                        </ul>
                    </div>
            }
        </div>
    );
};

export { NackleUpload };


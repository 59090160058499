import React from 'react';
import { Menu as Mnu } from 'antd';
import classNames from 'classnames';


export const Menu = ( { className, ...props } ) => {
    const { menuSize } = props;
    const menuClasses = classNames( {
        'menu-popover menu-sm': menuSize === 'small' ? 'menu-sm' : '',
        'menu-popover menu-lg': menuSize === 'large' ? 'menu-lg' : '',
        'menu-popover menu-md': menuSize === 'medium' ? 'menu-md' : ''
    } );
    return (
        <Mnu
            { ...props } className={ className ? `${ menuClasses } ${ className }` : menuClasses }>
        </Mnu>
    );
};

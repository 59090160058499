import { CommError } from './comm-error';
import { CALL, DATA_CLONE_ERROR, ERR_CONNECTION_DESTROYED, FULFILLED, MESSAGE, REJECTED, REPLY } from './constants';
import { generateId } from './generated-id';
import { deserializeError, serializeError } from './serialize-error';

/*export interface CommConnectionInfo {
    local: Window;
    remote: Window;
    remoteOrigin: string;
}*/

/*export interface CommConnection<T = DefaultMethodMap> {
    api: PromiseWrappedMethods<T>;
    destroy: () => Promise<any>;
}*/

export default class Comm {
    localName;
    shouldLog = false;
    isDestroyed = false;
    remoteApi = {};
    destructionCallbacks = [];

    constructor( localName ) {
        this.localName = localName;
        this.log( `${ this.localName }: Creating connection` );
    }

    destroy = async () => {
        this.log( `${ this.localName }: Destroying connection` );
        await Promise.all( this.destructionCallbacks.map( ( cb ) => cb() ) );
        this.destructionCallbacks = [];
    };

    addDestructionCallback = ( cb ) => {
        //this.destructionCallbacks.push(cb);
    };

    log = ( ...args ) => {
        if ( this.shouldLog ) {
            console.log( ...args );
        }
    };

    /**
     * Listens for "call" messages coming from the remote, executes the corresponding method, and
     * responds with the return value.
     * @param {Object} info Information about the local and remote windows.
     * @param {Object} methods The keys are the names of the methods that can be called by the remote
     * while the values are the method functions.
     * @returns {Function} A function that may be called to disconnect the receiver.
     */
    connectCallReceiver( info, methods ) {
        const { local: localWindow, remote: remoteWindow, remoteOrigin } = info;
        let destroyed = false;

        this.log( `${ this.localName }: Connecting call receiver` );
        this.log( `${ this.localName }: My methods are: ${ Object.keys( methods ).join( ', ' ) }` );

        const handleMessageEvent = ( event )  => {
            if ( event.source === remoteWindow && event.data.msgType === CALL ) {
                const { methodName, args, id } = event.data;

                this.log( `${ this.localName }: Received ${ methodName }() call` );
                if ( methodName in methods ) {
                    const createPromiseHandler = ( resolution ) =>
                        ( returnValue )  => {
                            this.log( `${ this.localName }: Sending ${ methodName }() reply` );

                            if ( destroyed ) {
                                // It's possible to throw an error here, but it would need to be thrown asynchronously
                                // and would only be catchable using window.onerror. This is because the consumer
                                // is merely returning a value from their method and not calling any function
                                // that they could wrap in a try-catch. Even if the consumer were to catch the error,
                                // the value of doing so is questionable. Instead, we'll just log a message.
                                this.log(
                                    `${ this.localName }: Unable to send ${ methodName }() reply due to destroyed connection`,
                                );
                                return;
                            }

                            const message = {
                                msgType: REPLY,
                                id,
                                resolution,
                                returnValue,
                                returnValueIsError: false,
                            };

                            if ( resolution === REJECTED && returnValue instanceof Error ) {
                                message.returnValue = serializeError( returnValue );
                                message.returnValueIsError = true;
                            }

                            try {
                                remoteWindow.postMessage( message, remoteOrigin );
                            } catch ( err ) {
                                // If a consumer attempts to send an object that's not cloneable (e.g., window),
                                // we want to ensure the receiver's promise gets rejected.
                                if ( err.name === DATA_CLONE_ERROR ) {
                                    remoteWindow.postMessage(
                                        {
                                            msgType: REPLY,
                                            id,
                                            resolution: REJECTED,
                                            returnValue: serializeError( err ),
                                            returnValueIsError: true,
                                        },
                                        remoteOrigin,
                                    );
                                }

                                throw err;
                            }
                        };

                    Promise.resolve()
                        // eslint-disable-next-line prefer-spread
                        .then( () => methods[ methodName ].apply( methods, args ) )
                        .then(
                            ( returnValue ) => createPromiseHandler( FULFILLED )( returnValue ),
                            ( returnError ) => createPromiseHandler( REJECTED )( returnError ),
                        );
                }
            }
        };

        localWindow.addEventListener( MESSAGE, handleMessageEvent );

        this.addDestructionCallback( () => {
            destroyed = true;
            Object.keys( this.remoteApi ).forEach( ( key ) => delete this.remoteApi[ key ] );
            localWindow.removeEventListener( MESSAGE, handleMessageEvent );
        } );
    }

    /**
     * Augments an object with methods that match those defined by the remote. When these methods are
     * called, a "call" message will be sent to the remote, the remote's corresponding method will be
     * executed, and the method's return value will be returned via a message.
     *
     * @param {Object} info Information about the local and remote windows.
     * @param {Array} methodNames Names of methods available to be called on the remote.
     * @returns {Object} The call sender object with methods that may be called.
     */
    connectCallSender( info, methodNames ) {
        const { local: localWindow, remote: remoteWindow, remoteOrigin } = info;
        let destroyed = false;

        this.log( `${ this.localName }: Connecting call sender` );
        this.log( `${ this.localName }: Remote methods are: ${ methodNames.join( ', ' ) }` );

        const createMethodProxy = ( methodName )  =>
            ( ...args ) => {
                this.log( `${ this.localName }: Sending ${ methodName }() call` );

                // This handles the case where the iframe has been removed from the DOM
                // (and therefore its window closed), the consumer has not yet
                // called destroy(), and the user calls a method exposed by
                // the remote. We detect the iframe has been removed and force
                // a destroy() immediately so that the consumer sees the error saying
                // the connection has been destroyed.
                if ( remoteWindow.closed ) {
                    this.destroy();
                }

                if ( destroyed ) {
                    throw new CommError(
                        `Unable to send ${ methodName }() call due ` + 'to destroyed connection',
                        ERR_CONNECTION_DESTROYED,
                    );
                }

                return new Promise( ( resolve, reject ) => {
                    const id = generateId();
                    const handleMessageEvent = ( event ) => {
                        if ( event.source === remoteWindow && event.data.msgType === REPLY && event.data.id === id ) {
                            this.log( `${ this.localName }: Received ${ methodName }() reply` );
                            localWindow.removeEventListener( MESSAGE, handleMessageEvent );

                            let returnValue = event.data.returnValue;

                            if ( event.data.returnValueIsError ) {
                                returnValue = deserializeError( returnValue );
                            }

                            ( event.data.resolution === FULFILLED ? resolve : reject )(
                                returnValue,
                            );
                        }
                    };

                    localWindow.addEventListener( MESSAGE, handleMessageEvent );
                    remoteWindow.postMessage(
                        {
                            msgType: CALL,
                            id,
                            methodName,
                            args,
                        },
                        remoteOrigin,
                    );
                } );
            };

        this.addDestructionCallback( () => destroyed = true );
        methodNames.forEach( ( methodName ) => this.remoteApi[ methodName ] = createMethodProxy( methodName ) );
    }
}

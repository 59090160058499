import React, { useEffect, useRef, useState, forwardRef } from 'react';
import { Row, Col, Avatar, Label } from '../';
import Select from 'react-select/async';
import { components } from 'react-select';
import { Search, ChevronDown } from '@nackle/origami-icons';
import { getString } from '@nackle/intl-tools';
import { EmptyState } from './empty-state-illustration';
import classNames from 'classnames';

const env = window.location.hostname.toLowerCase().split( '.' ).find( function ( chunk ) {
    return [ 'ci', 'localhost', 'qa', 'pprd' ].indexOf( chunk ) >= 0;
} );
const baseProfileUrl = `${ window.location.hostname }/api/profile`;
const qaProfileUrl = 'https://qa.api.biw.cloud/v1/profile';

//displayed when no search results
const NoOptions = () => {
    return (
        <div className="empty-state-container">
            <EmptyState />
            <div>{ getString( 'origami.search.noResultsFound' ) }</div>
        </div>
    );
};
//we're making this look like a search input by overriding the dropdown chevron with the search icon from 
//origami icons and moving that from the right hand side to the left of the input
const DropdownIndicator = ( { children, ...props } ) => (
    <div className="icon-container">
        <Search className="icon" fill="#59606D" />
    </div>
);

//custom label for tag so display correct info
const MultiValueLabel = ( props ) => {
    //set value for key
    props.data.value = props.data.id;
    const { innerRef, innerProps } = props;
    return (
        <components.MultiValueLabel className="multi-label" key={ props.data.id } ref={ innerRef } { ...innerProps }>
            { `${ props.data.givenName } ${ props.data.surname }` }
        </components.MultiValueLabel>
    );
};
const ValueContainer = ( { children, ...props } ) => {
    const [ expanded, setExpanded ] = useState( false );
    const [ showChevron, setShowChevron ] = useState( false );
    const [ scrollBar, setScrollBar ] = useState( false );
    const [ placeholder, setPlaceholder ] = useState();
    const valueRef = useRef();

    const containerClasses = classNames( {
        'value': true,
        'expanded': expanded ? true : false,
        'scroll': scrollBar ? true : false
    } );
    useEffect( () => {
        if ( valueRef.current?.clientHeight > 86 ) {
            setShowChevron( true );
        } else {
            setShowChevron( false );
        }
        if ( valueRef.current?.clientHeight > 199 ) {
            setScrollBar( true );
        } else {
            setScrollBar( false );
        }

    }, [ children ] );
    const hidePlaceholder = () => {
        const placeholder = document.querySelector( '.search-select__placeholder' );
        if ( placeholder ) {
            placeholder.style.display = 'none';
            setPlaceholder( placeholder );
        } else {
            setTimeout( () => {
                hidePlaceholder();
            }, 100 );
        }
    };
    const showPlaceholder = () => {
        if ( children.length > 2 ) {
            return;
        } else {
            if ( placeholder ) {
                placeholder.style.display = 'block';
            }
        }
    };
    const expand = () => {
        const chevron = document.querySelector( '.chevron' );
        chevron.style.top = '';
        setExpanded( !expanded );
    };
    return (

        <components.ValueContainer className={ containerClasses } { ...props } >
            <div className="ref-container" ref={ valueRef } onFocus={ hidePlaceholder } onBlur={ showPlaceholder }>
                { children }
                {
                    showChevron &&
                    <div className="search-chevron-container">
                        <div role="button" tabIndex={ 0 } onKeyUp={ ( event ) => { if ( event.key === 'Enter' ) { expand(); } } } onClick={ () => { expand(); } } onTouchEnd={ () => expand() } className='chevron'>
                            <ChevronDown className={ expanded ? 'up' : 'down' } fill={ '#59606D' } />
                        </div>
                    </div>

                }
            </div>
        </components.ValueContainer>
    );
};
//custom search result per designs - same as team members
const CustomOption = ( props ) => {
    const { innerProps, innerRef } = props;
    const person = props.data;
    const personDetails = [];
    if ( person.jobTitle ) {
        personDetails.push( person.jobTitle );
    }
    if ( person.department ) {
        personDetails.push( person.department );
    }
    if ( person.country ) {
        personDetails.push( person.country );
    }
    const personString = personDetails.join( ', ' );
    const label = (
        <div className={ person.disabled ? 'cursor-not-allowed' : '' }>
        <div className={ person.disabled ? 'disabled-search-result-wrapper' : 'search-result-wrapper' } role="menuitem" tabIndex={ 0 } ref={ innerRef } key={ person.id } onKeyUp={ ( event ) => { if ( event.key === 'Enter' ) { props.selectOption( event ); } } } onClick={ ( event ) => { props.selectOption( event ); } } { ...innerProps } >

            <div className={ person.disabled ? 'disabled search-result' : 'search-result' }>
                <span className="avatar-container">
                    <Avatar size={ 'medium' } alt={ getString( 'origami.avatar.alt' ) } className="primary-background-avatar" src={ `${ env === 'localhost' ? qaProfileUrl : baseProfileUrl }/public/images/image/${ person.companyId }/${ person.id }/avatar/170/170` }>{ person.givenName.slice( 0, 1 ) }</Avatar>
                </span>
                <span className="text-container">
                    <div className="search-name">
                        { `${ person.givenName } ${ person.surname }` }
                    </div>
                    <div className="person-details" key={ person.id }>
                        { personString }
                    </div>
                </span>
                <div className="disabled-reason">
                    { person.disabledReason }
                </div>

            </div>
        </div>
        </div>
    );

    return label;

};

const NacklePersonSearch = forwardRef( function NackleSearch( props, ref ) {
    const {
        placeholder,
        teamLabel,
        showTeam,
        emptyTeamLabel,
        ariaLabel,
        className,
        selectClassName
    } = props;
    const [ selected, setSelected ] = useState( [] );
    const [ options, ] = useState( [] );
    const [ teamMembers, ] = useState( props.teamMembers );
    //get preselected/selected people for tag population
    const selectedIds = selected.map( person => {
        return person.id;
    } );

    //filter out already selected team members so they can't be added twice
    const filtered = [];
    if ( teamMembers ) {
        teamMembers.map( member => {
            if ( !selectedIds.includes( member.id ) ) {
                filtered.push( member );
            }
        } );
    }
    const theme = ( theme ) => ( {
        ...theme,
        spacing: {
            ...theme.spacing,
            controlHeight: 44,
            baseUnit: 1,
        }
    } );
    const selectPerson = ( person ) => {
        setSelected( [ ...person ] );
        if ( typeof props.onSelect === 'function' ) {
            props.onSelect( person );
        }
    };
    const handleSearch = ( value, callback ) => {
        const { searchPersons, companyId, user } = props;
        searchPersons( value ).then( resp => {
            const persons = resp;
            const companyPersons = persons.map( person => {
                person.companyId = companyId;
                person.value = person.id;
                return person;
            } );
            //filter out the logged in user from the results because you can't recognize yourself
            const filteredCompanyPersons = companyPersons.filter( person => {
                return person.id !== user.claims.person_id;
            } );
            callback( filteredCompanyPersons );
        } );


    };
    const handleKeyPress = ( e, callback, variables ) => {
        if ( e.key === 'Enter' ) {
            if ( typeof callback === 'function' ) {
                if ( variables ) {
                    callback( variables );
                } else {
                    callback();
                }
            }
        }
    };
    const handleSelect = ( options, action ) => {
        switch ( action.action ) {
            case 'clear':
                selectPerson( [] );
                break;
            case 'remove-value':
                const filtered = selected.filter( person => person.id !== action.removedValue.id );
                selectPerson( filtered );
                break;
            case 'select-option':
                if ( selected.length === 0 ) {
                    selectPerson( options );
                } else if ( selected.length > 0 ) {
                    selected.push( action.option );
                    selectPerson( selected );
                }
                break;

            default:
                console.log( 'unknown action' );
                break;
        }
    };
    const searchClasses = classNames( {
        'content-container': true,
        'search-screen': true,
        [ className ]: className ? true : false
    } );
    return (
        <div className={ searchClasses } ref={ ref }>
            <Row gutter={ [ 24, 24 ] }>
                <Col span={ 24 }>

                    <Select
                        aria-label={ ariaLabel }
                        components={ {
                            DropdownIndicator,
                            Option: CustomOption,
                            MultiValueLabel,
                            LoadingIndicator: null,
                            ValueContainer
                        } }
                        noOptionsMessage={ NoOptions }
                        classNamePrefix={ 'search-select' }
                        styles={ {
                            control: ( baseStyles, state ) => ( {
                                ...baseStyles,
                                flexDirection: 'row-reverse',
                                borderRadius: '8px',
                                border: '1px solid #C9D1DF',
                                '&:hover': {
                                    border: '1px solid #C9D1DF',
                                },
                                '&:focus': {
                                    boxShadow: '0 0 4px 0 rgba(50, 114, 217, 0.75)'
                                }


                            } ),
                            indicatorSeparator: ( baseStyles, state ) => ( {
                                ...baseStyles,
                                display: 'none'
                            } ),
                            indicatorsContainer: ( baseStyles, state ) => ( {
                                ...baseStyles,
                                marginLeft: '10px',
                                marginRight: '10px'
                            } ),
                            valueContainer: ( baseStyles, state ) => ( {
                                ...baseStyles,
                                paddingTop: '10px',
                                paddingBottom: '10px',
                                gap: '4px'
                            } ),
                            multiValue: ( baseStyles, state ) => ( {
                                ...baseStyles,
                                height: '24px',
                                paddingLeft: '10px',
                                backgroundColor: '#F5F7FA',
                                borderRadius: '6px',
                                overflow: 'hidden'
                            } ),
                            multiValueRemove: ( baseStyles, state ) => ( {
                                ...baseStyles,
                                width: '34px',
                                justifyContent: 'center',
                                '&:hover': {
                                    backgroundColor: '#F5F7FA',
                                    cursor: 'pointer'
                                },
                                'svg': {
                                    width: '16px',
                                    height: '16px',
                                    fill: '#59606D'
                                }
                            } )
                        } }
                        theme={ theme }
                        openMenuOnFocus={ false }
                        openMenuOnClick={ false }
                        isClearable={ false }
                        isMulti={ true }
                        autoFocus={ true }
                        className={ selectClassName ? `${ selectClassName } person-search` : 'person-search' }
                        options={ options }
                        value={ selected }
                        onChange={ ( value, action ) => handleSelect( value, action ) }
                        placeholder={ placeholder || 'Search for people in your organization' }
                        loadOptions={ handleSearch } />
                </Col>
            </Row>
            { showTeam &&

                ( filtered && filtered.length > 0 ?
                    <>
                        <div className="team-container">
                            <Label className="sub-description" text={ teamLabel || 'Add people from your team.' } />
                            <Row>
                                <Col span={ 24 }>


                                    { filtered.map( person => {
                                        const personDetails = [];
                                        if ( person.jobTitle ) {
                                            personDetails.push( person.jobTitle );
                                        }
                                        if ( person.department ) {
                                            personDetails.push( person.department );
                                        }
                                        personDetails.push( person.country );
                                        const personString = personDetails.join( ', ' );
                                        return (
                                            <div className={ person.disabled ? 'disabled search-result' : 'search-result' } tabIndex="0" role="menuitem" key={ person.id } onKeyUp={ person.disabled ? () => {} : () => handleKeyPress( event, selectPerson, [ ...selected, person ] ) } onClick={ person.disabled ? () => {} : () => { selectPerson( [ ...selected, person ] ); } }>
                                                <span className="avatar-container">
                                                    <Avatar alt={ getString( 'origami.avatar.alt' ) } size={ 'medium' } className="primary-background-avatar" src={ `${ env === 'localhost' ? qaProfileUrl : baseProfileUrl }/public/images/image/${ props.companyId }/${ person.id }/avatar/170/170` }>{ person.givenName.slice( 0, 1 ) }</Avatar>
                                                </span>
                                                <span className="text-container">
                                                    <div className="search-name">
                                                        { `${ person.givenName } ${ person.surname }` }
                                                    </div>
                                                    <div className="person-details">
                                                        <span>{ personString }</span>
                                                    </div>
                                                </span>
                                                <div className="disabled-reason">
                                                    { person.disabledReason }
                                                </div>

                                            </div>
                                        );
                                    } )
                                    }

                                </Col>
                            </Row>
                        </div>
                    </>
                    :
                    <div className="no-team-container">
                        <EmptyState />
                        <div className="search-text-no-team">{ emptyTeamLabel || 'No Results' }</div>
                    </div>
                ) }

        </div> );
} );
export { NacklePersonSearch };
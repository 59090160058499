import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ColorPicker } from 'antd';
import { CirclePicker } from 'react-color';
import './color-gradient-picker.less';
import './color-swatches-picker.less';
import classNames from 'classnames';
import { Button } from '../button/button';
import { ChevronDown } from '@nackle/origami-icons';

const ButtonDisplay = ( { textContent, hexString, showText, showIcon, iconStyle } ) => {
    if ( showText ) {
        return <span className='color-name'>{ textContent || hexString.toUpperCase() }</span>;
    } else if ( showIcon ) {
        return <span className='color-name'><ChevronDown style={ iconStyle } /></span>;
    } else {
        return null;
    }
};
const NackleColorPicker = ( { defaultValue, children, ...props } ) => {
    const [ currentColor, setCurrentColor ] = useState( defaultValue );
    const [ open, setOpen ] = useState( false );
    const { color, onChangeComplete, type, showText, colorTagShape, panelType, buttonProps, showIcon, size, textContent, ...restProps } = props;

    /*Convert to HEX color*/
    const hexString = useMemo(
        () => ( typeof currentColor === 'string' ? currentColor : currentColor?.toHexString() ), [ currentColor ],
      );
    const iconStyle = { transform: `rotate( ${ open ? '180deg' : '0' } )` };
      /*set current color for swatch panel*/
      document.documentElement.style.setProperty( '--currentColor', `${ currentColor }` );

      const swatchColors = [
        '#F5F7FA',
        '#B1BACA',
        '#9099A9',
        '#59606D',
        '#34383F',
        '#000000',
        '#787C04',
        '#759825',
        '#0AA648',
        '#198E48',
        '#239393',
        '#08827A',
        '#2A7A8D',
        '#48759C',
        '#3272D9',
        '#2C41E3',
        '#4949DB',
        '#6767E6',
        '#7A68B3',
        '#9656D6',
        '#BD56D6',
        '#D656CC',
        '#D6568C',
        '#D6246E',
        '#DE1B1B',
        '#EA3B12',
        '#EB6311',
        '#E68E00',
        '#D8C430',
        '#B77100'
    ];

    const presets = [
            {
              colors: swatchColors,
            },
          ];

    const customPanelRender = ( _, { components: { Presets } } ) => (
            <Presets/>
      );
    
      const controlTriggerClasses = classNames( {
        'trigger-button-with-text': showText,
        'trigger-button-icon-only': !showText,
        'trigger-circle-button-icon': colorTagShape && showText,
        'circle': colorTagShape === 'circle' ? true : false
    } );

    const panelControlClasses = classNames( {
        'swatch-panel-color-sm': size === 'small' ? true : false,
        'swatch-panel-color-lg': size === 'large' ? true : false,
        'swatch-panel-color-md': size === 'middle' ? true : false,
    } );
   
      const handleChangeComplete = ( color ) => {
        if ( typeof onChangeComplete === 'function' ) {
            onChangeComplete( color );
            setCurrentColor( color );
        }
    };

    return (
        <>
             { type === 'swatches' &&
                <div className='nackle-swatches-color-picker'>
                    <CirclePicker
                        colors={ swatchColors }
                        onChangeComplete={ ( color ) => handleChangeComplete( color.hex ) }
                        color={ color ? color : undefined }
                        width={ 292 }
                        circleSpacing={ 0 }
                        circleSize={ 42 }
                        { ...restProps }
                    />
                </div>
            }

            { type === 'gradient' &&
                <div className='nackle-gradient-color-picker'>
                    <ColorPicker
                        onChangeComplete={ ( color ) => handleChangeComplete( color.metaColor ) }
                        color={ color ? color : undefined }
                        open={ true }
                        children={ <div /> }
                        getPopupContainer={ ( triggerNode ) => triggerNode.parentNode }
                        arrow={ false }
                    />
                </div>
            }
             { type === 'trigger' &&
                <div className='nackle-trigger-color-picker'>
                    
                        <Button
                            className={ controlTriggerClasses }
                            size = { size }
                            onClick={ () => setOpen( !open ) }
                            { ...buttonProps }
                        >
                            <div className='panel-color-picker'>
                                {  panelType === 'swatches' ?
                                    <ColorPicker 
                                        className={ panelControlClasses }
                                        trigger='click'
                                        open={ open }
                                        defaultValue={ defaultValue }   
                                        onChangeComplete={ ( color ) => handleChangeComplete( color.metaColor ) }
                                        color={ color ? color : undefined }
                                        panelRender={ customPanelRender }
                                        presets= { presets }
                                        size= { size }
                                        showText={ () => ButtonDisplay( { textContent, hexString, showText, showIcon, iconStyle } ) }
                                        placement={ 'top' } 
                                        { ...restProps } /> 
                                : 
                                    <ColorPicker 
                                        className={ panelControlClasses }
                                        trigger='click'
                                        open={ open }
                                        defaultValue={ defaultValue }   
                                        onChangeComplete={ ( color ) => handleChangeComplete( color.metaColor ) }
                                        color={ color ? color : undefined } 
                                        size= { size }
                                        showText={ () => ButtonDisplay( { textContent, hexString, showText, showIcon, iconStyle } ) }
                                        placement={ 'top' } 
                                        { ...restProps } /> 
                                }
                            </div> 
                    </Button>
                </div>
            }
        </>
    );
};


NackleColorPicker.propTypes = {
    /** Type of color picker */
    type: PropTypes.oneOf( [ 'swatches', 'gradient', 'trigger' ] ).isRequired,
    /** Current selected color */
    color: PropTypes.string,
    /** callback returning color when color is picked*/
    onChangeComplete: PropTypes.func,
    /** Show text color */
    showText: PropTypes.bool,
    /** props of button*/
    buttonProps: PropTypes.object,
};

export { NackleColorPicker };


import React from 'react';
import { TimePicker as AntTimePicker } from 'antd';
import customIcons from '../date-picker/custom-icons';
import classNames from 'classnames';
import dayjs from 'dayjs';

const { clearIcon, clockIcon, exchangeIcon } = customIcons;

const TimePicker = ( { ...props } ) => {

    const timePickerPopupClassNames = classNames( {
        'origami-time-picker-dropdown': true,
        'origami-time-picker-dropdown-HHmm': props.format === 'HH:mm' ? true : false,
        'origami-time-picker-dropdown-HH': props.format === 'HH' ? true : false,
        'origami-time-picker-dropdown-ha': props.format === 'h a' ? true : false,
        'origami-time-picker-dropdown-hhmma': props.format === 'h:mm a' ? true : false,
    } );

    const onChange = ( time, timeString ) => {
        if ( typeof props.onChange === 'function' ) {
            if ( props.format === 'HH' ) {
                const convertedTimeString = dayjs( time ).format( 'HH' );
                props.onChange( time, convertedTimeString );
            } else {
                props.onChange( time, timeString );
            }
        }
    };

    const getTimeFormat = () => {
        if ( props.format === 'HH' ) {
            return 'HH:mm';
        }
        if ( !props.format ) {
            return 'h a';
        }
        else { 
            return ( props.format );
        }
    };

    const getPopupStyle = () => {
        if ( props.format === 'HH:mm' || props.format === 'h a' ) {
            return { 'minWidth': '140px' };
        }
        if ( props.format === 'h:mm a' ) {
            return { 'minWidth': '164px' };
        }
    };

    return (
        <AntTimePicker
            popupClassName={ timePickerPopupClassNames }
            popupStyle={ getPopupStyle() }
            suffixIcon={ clockIcon }
            allowClear={ { clearIcon: clearIcon } }
            showNow={ props.format === 'HH:mm' || props.format === 'h:mm a' ? true : false }
            onChange={ onChange }
            needConfirm={ props.format === 'HH' ? false : true }
            { ...props }
            format={ getTimeFormat() }
        />
    );
};

const RangePicker = ( { ...props } ) => (
    <AntTimePicker.RangePicker
        suffixIcon={ clockIcon }
        allowClear={ { clearIcon: clearIcon } }
        separator={ exchangeIcon }
        { ...props }
    />
);

TimePicker.RangePicker = RangePicker;

export { TimePicker };
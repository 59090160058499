import React from 'react';
import PropTypes from 'prop-types';
import { Avatar as Avtr } from 'antd';
import AvatarInfo from './avatar-info';
import AvatarGroup from './avatar-group';
const Avatar = ( { children, ...props } ) => {
const { className, size } = props;
    switch ( size ) {
        case 'mini':
            return <Avtr { ...props } size={ 24 } className={ className ? `mini-avatar ${ className }` : 'mini-avatar' }>
                { children }
            </Avtr>;
        case 'small':
            return <Avtr { ...props }   size={ 36 } className={ className ? `small-avatar ${ className }` : 'small-avatar' }>
                { children }
            </Avtr>;
        case 'medium':
            return <Avtr { ...props }   size={ 48 } className={ className ? `medium-avatar ${ className }` : 'medium-avatar' }>
                { children }
            </Avtr>;
        case 'large':
            return <Avtr { ...props }  size={ 64 } className={ className ? `large-avatar ${ className }` : 'large-avatar' }>
                { children }
            </Avtr>;
        case 'jumbo':
            return <Avtr { ...props }  size={ 128 } className={ className ? `jumbo-avatar ${ className }` : 'jumbo-avatar' }>
                { children }
            </Avtr>;
        default:
            return <Avtr { ...props }  size={ 48 } className={ className ? `medium-avatar ${ className }` : 'medium-avatar' }>
                { children }
            </Avtr>;
    }
};
Avatar.Group = AvatarGroup;
Avatar.Info = AvatarInfo;

Avatar.propTypes = {
    /** Size of Avatar */
    size: PropTypes.oneOf( [ 'mini', 'small', 'medium', 'large', 'jumbo' ] ),
    /** Class name */
    className: PropTypes.string,
    /** Children - generally initial(s) for fallback when no avatar image is present */
    children: PropTypes.node
};

export { Avatar };



import React, { CSSProperties } from 'react';

/*export interface SegmentData {
    elem: JSX.Element;
    styleCSS: string;
    elemStyle: CSSProperties;
}*/

const revealEasing = 'linear';
const spinEasing = 'cubic-bezier(.15, .44, .38, 1)';

/**
 * Creates a ring by creating `segmentColors.length` of ring segements. The data is stored in member
 * variables.
 * @param id                The id to assign the ring (must be unique).
 * @param revealToRight     Direction to reveal the segments and spin the ring.
 * @param outerRadius       Radius of the ring (up to the edge of the element).
 * @param segmentColors     Colors of each ring segment to be made.
 * @param delayTime         The time for the delay BEFORE revealing the segment.
 * @param revealTime        The time for the revealing of the segment BEFORE dragging it.
 * @param spinTime          The time to let the segments spin around the ring.
 * @param spinAngleOffset   Angle that the ring should end at after spinning.
 * @param gapAngle          The angle of the gap inbetween each segment.
 */
export const createRing = (
    id,
    revealToRight,
    outerRadius,
    stroke,
    segmentColors,
    delayTime,
    revealTime,
    spinTime,
    spinAngleOffset,
    gapAngle = 10,
) => {
    const ringSegCount = segmentColors.length;
    const segments = [];

    // Calculations for the ring
    const radius = ( ( outerRadius * 2 ) - ( stroke * 2 ) ) / 2;
    const circumference = radius * 2 * Math.PI;
    const speed = circumference / revealTime;
    const gapDist = ( ( gapAngle / 360 ) * circumference );
    const gapDelay = ( gapDist / speed );
    const segmentRevealTime = ( revealTime / ringSegCount );
    const arcAngle = ( 360 / ringSegCount ) - gapAngle;

    // Assemble the ring
    for ( let i = 0; i < ringSegCount; i++ ) {
        const segID = `${ id }_seg_${ segments.length }`;
        const segDelay = ( i * segmentRevealTime );
        const dragTime = revealTime - ( ( i + 1 ) * segmentRevealTime );
        const dragAngle = ( ringSegCount - i - 1 ) * ( 360 / ringSegCount );
        const spinAngle = spinAngleOffset + dragAngle;

        // Create a segment of ring and store it.
        const segment = getSegement( segID, segmentColors[ i ], stroke, radius, revealToRight, ( segDelay + gapDelay ) + delayTime, segmentRevealTime - gapDelay, dragTime, spinTime, arcAngle, dragAngle, spinAngle );
        segments.push( segment );
    }
    return segments;
};

/**
 * This function creates a JSX.Element to be used as a ring segment, the styling that accompanies
 * that element, and the generated animation (as a CSS string). These three are returned in an
 * object.
 * @param id                    The id to be used for the element and its corresponding animation.
 * @param color                 The color to use for the element.
 * @param radius                Radius of the ring this segment should belong to.
 * @param revealToRight         Whether or not the segment should reveal itself out of the right side of the 'spawn point'.
 * @param delayTime             The time for the delay BEFORE revealing the segment.
 * @param revealTime            The time for the revealing of the segment BEFORE dragging it.
 * @param dragTime              The time for dragging of the segment around the ring to make room for other revealing segments in the ring BEFORE spinning around the ring.
 * @param spinTime              The time to let the segments spin around the ring.
 * @param arcAngle              Angle of the segment (circle arc) after it has fully revealed.
 * @param dragAngle             Angle on the ring (circle) to drag the segment to after having been revealed.
 * @param spinAngle             Angle to end up at after spinning around the ring (circle).
 * @param segmentAngleOffset    Initial angle offset for segments on a ring.
  */
export const getSegement = (
    id,
    color,
    stroke,
    radius,
    revealToRight,
    delayTime,
    revealTime,
    dragTime,
    spinTime,
    arcAngle,
    dragAngle,
    spinAngle,
    segmentAngleOffset = -90,
) => {
    // Get the animation to be used by the element
    const circumference = radius * 2 * Math.PI;

    // Collect basic information for animation and update the segments style properties.
    const scaleVal = ( revealToRight ) ? 1 : -1;

    // If the browser is any browser other than IE or Edge, perform the ring animations, else, show a static image
    if ( !detectIE() ) {
        const totalTime = delayTime + revealTime + dragTime + spinTime;

        // Using the total time of the animation, plan out the percentages throughout the animation.
        const delayPercent = Math.floor( ( delayTime / totalTime ) * 100 );
        const revealPercent = Math.floor( ( ( revealTime / totalTime ) * 100 ) + delayPercent );
        const dragPercent = Math.floor( ( ( dragTime / totalTime ) * 100 ) + revealPercent );

        // Creates a string representing part of a CSS keyframes rule using the parameters given.
        const ruleState = ( prefix, opacity, segAngle, offsetAngle, easing = 'linear' ) => {
            return `{
                opacity: ${ opacity };
                stroke-dashoffset: ${ circumference - ( circumference * ( segAngle / 360 ) ) };
                ${ prefix }transform: scaleX(${ scaleVal }) rotate(${ offsetAngle + segmentAngleOffset }deg);
                animation-timing-function: ${ easing };
            }`;
        };

        // Assemble the keyframe animations all into one string
        let rules = '';
        [ '-webkit-', '' ].forEach( ( prefix ) => {
            rules += `@${ prefix }keyframes ${ id } {
                ${ 0.001 }% ${ ruleState( prefix, 0, 0, 0, revealEasing ) }
                ${ delayPercent }% ${ ruleState( prefix, 0, 0, 0, revealEasing ) }
                ${ ( revealPercent !== dragPercent )
                    ?
                        `${ revealPercent }% ${ ruleState( prefix, 1, arcAngle, 0, revealEasing ) }
                        ${ dragPercent }% ${ ruleState( prefix, 1, arcAngle, dragAngle, spinEasing ) }`
                    :
                        `${ dragPercent }% ${ ruleState( prefix, 1, arcAngle, dragAngle, spinEasing ) }`
                }
                ${ 100 }% ${ ruleState( prefix, 1, arcAngle, spinAngle, revealEasing ) }
            }${ '\n' }`;
        } );

        // Get initial element details
        const percentage = 0;
        const dashOffset = circumference - percentage / 100 * circumference;
        const style = {
            transformOrigin: '50% 50%',
            transform: 'rotate(-90deg)',
            strokeDasharray: `${ circumference } ${ circumference }`,
            strokeDashoffset: `${ dashOffset }`,
            strokeLinecap: 'round',
            strokeWidth: stroke,

            animationName: ( id ) ? id : '',
            animationDuration: `${ totalTime }s`,
            animationDelay: `${ 0 }s`,
            animationDirection: 'normal',
            animationFillMode: 'forwards',
        };

        // Get the JSX.Element
        const element = (
            <circle
                key={ id }
                stroke={ color }
                r={ radius }
                cx={ `${ 50 }%` }
                cy={ `${ 50 }%` }
                style={ style }
                fill="none"
            />
        );

        return {
            elem: element,
            elemStyle: style,
            styleCSS: rules,
        };
    } else {
        // Get initial element details
        const dashOffset = circumference - ( circumference * ( arcAngle / 360 ) );
        const style = {
            strokeDasharray: `${ circumference } ${ circumference }`,
            strokeDashoffset: `${ dashOffset }`,
            strokeLinecap: 'round',
            strokeWidth: stroke,
        };

        const viewBoxSize = ( 2 * Math.sqrt( 2 ) * 100 ) - 75;

        // Get the JSX.Element
        const element = (
            <circle
                key={ id }
                stroke={ color }
                r={ radius }
                style={ style }
                fill="none"
                transform={ `translate(${ viewBoxSize / 2 }, ${ viewBoxSize / 2 }) rotate(${ spinAngle + segmentAngleOffset }) scale(${ scaleVal } 1)` }
            />
        );

        return {
            elem: element,
            elemStyle: style,
            styleCSS: '',
        };
    }
};

/**
 * Returns true if it detects the browser is Internet Explorer. False if not.
 */
const detectIE = () => {
    const ua = window.navigator.userAgent;
    if ( ( ua.indexOf( 'MSIE ' ) > 0 ) || ( ua.indexOf( 'Trident/' ) > 0 ) || ( ua.indexOf( 'Edge/' ) > 0 ) ) {
        return true;
    } else {
        return false;
    }
};


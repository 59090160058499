import React from 'react';
import { Particle } from './particle';

/*interface Props {
    delay: number;
    time: number;
    particleCount: number;
    minRadius: number;
    maxRadius: number;
}

interface ParticleDetails {
    color: string;
    radius: number;
    offsetX?: number;
    offsetY?: number;
}

interface DrivenParticle {
    details: ParticleDetails;
    destination: string;
}*/

export class ParticleBurst extends React.Component {

    animationLabel = 'particle';
    particleColors = [ '#FF2F27', '#0079FC', '#FF651A', '#EF59D3', '#47C0FF', '#953AFF', '#FFC329' ];

    offsetX = 0;
    offsetY = 80;

    // Particles that are 'premade' if desired.
    drivenParticles = [
        { details: { color: '#FF2F27', radius: 52 },    destination: `${ 190 - this.offsetX }px, ${ 110 - this.offsetY }px` },
        { details: { color: '#3D77F9', radius: 22 },    destination: `${ 30 - this.offsetX }px, ${ -180 - this.offsetY }px` },
        { details: { color: '#953AFF', radius: 57 },    destination: `${ 210 - this.offsetX }px, ${ -160 - this.offsetY }px` },
        { details: { color: '#FF2F27', radius: 30 },    destination: `${ 130 - this.offsetX }px, ${ -90 - this.offsetY }px` },
        { details: { color: '#EF59D3', radius: 8  },    destination: `${ 138 - this.offsetX }px, ${ -15 - this.offsetY }px` },
        { details: { color: '#FFC329', radius: 14 },    destination: `${ 126 - this.offsetX }px, ${ 40 - this.offsetY }px` },
        { details: { color: '#FF651A', radius: 20 },    destination: `${ 200 - this.offsetX }px, ${ 20 - this.offsetY }px` },
        { details: { color: '#47C0FF', radius: 20 },    destination: `${ 250 - this.offsetX }px, ${ -30 - this.offsetY }px` },
        { details: { color: '#FFC329', radius: 28 },    destination: `${ 290 - this.offsetX }px, ${ -80 - this.offsetY }px` },
        { details: { color: '#FF2F27', radius: 32 },    destination: `${ 330 - this.offsetX }px, ${ -11 - this.offsetY }px` },
        { details: { color: '#EF59D3', radius: 15 },    destination: `${ 280 - this.offsetX }px, ${ 30 - this.offsetY }px` },
        { details: { color: '#EF59D3', radius: 20 },    destination: `${ 170 - this.offsetX }px, ${ 240 - this.offsetY }px` },
        { details: { color: '#FFC329', radius: 32 },    destination: `${ 200 - this.offsetX }px, ${ 320 - this.offsetY }px` },
        { details: { color: '#0079FC', radius: 36 },    destination: `${ 300 - this.offsetX }px, ${ 220 - this.offsetY }px` },
        { details: { color: '#47C0FF', radius: 20 },    destination: `${ 370 - this.offsetX }px, ${ 170 - this.offsetY }px` },
        { details: { color: '#FFC329', radius: 26 },    destination: `${ 270 - this.offsetX }px, ${ 110 - this.offsetY }px` },
        { details: { color: '#0079FC', radius: 19 },    destination: `${ 125 - this.offsetX }px, ${ 360 - this.offsetY }px` },
        { details: { color: '#47C0FF', radius: 26 },    destination: `${ 40 - this.offsetX }px, ${ 340 - this.offsetY }px` },

        { details: { color: '#3D77F9', radius: 35 },    destination: `${ -160 - this.offsetX }px, ${ 100 - this.offsetY }px` },
        { details: { color: '#47C0FF', radius: 56 },    destination: `${ -200 - this.offsetX }px, ${ -80 - this.offsetY }px` },
        { details: { color: '#EF59D3', radius: 30 },    destination: `${ -100 - this.offsetX }px, ${ -210 - this.offsetY }px` },
        { details: { color: '#FFC329', radius: 35 },    destination: `${ -130 - this.offsetX }px, ${ -60 - this.offsetY }px` },
        { details: { color: '#FF651A', radius: 34 },    destination: `${ -170 - this.offsetX }px, ${ 260 - this.offsetY }px` },
        { details: { color: '#953AFF', radius: 12 },    destination: `${ -120 - this.offsetX }px, ${ 360 - this.offsetY }px` },
        { details: { color: '#FFC329', radius: 14 },    destination: `${ -230 - this.offsetX }px, ${ 350 - this.offsetY }px` },
        { details: { color: '#EF59D3', radius: 22 },    destination: `${ -255 - this.offsetX }px, ${ 280 - this.offsetY }px` },
        { details: { color: '#FF2F27', radius: 25 },    destination: `${ -320 - this.offsetX }px, ${ 260 - this.offsetY }px` },
        { details: { color: '#FFC329', radius: 25 },    destination: `${ -240 - this.offsetX }px, ${ 180 - this.offsetY }px` },
        { details: { color: '#953AFF', radius: 18 },    destination: `${ -236 - this.offsetX }px, ${ 60 - this.offsetY }px` },
        { details: { color: '#EF59D3', radius: 14 },    destination: `${ -220 - this.offsetX }px, ${ 5 - this.offsetY }px` },
        { details: { color: '#FF2F27', radius: 14 },    destination: `${ -170 - this.offsetX }px, ${ 20 - this.offsetY }px` },
        { details: { color: '#FF651A', radius: 30 },    destination: `${ -300 - this.offsetX }px, ${ -20 - this.offsetY }px` },
        { details: { color: '#0079FC', radius: 26 },    destination: `${ -280 - this.offsetX }px, ${ -100 - this.offsetY }px` },
        { details: { color: '#FF2F27', radius: 24 },    destination: `${ -310 - this.offsetX }px, ${ -160 - this.offsetY }px` },
        { details: { color: '#EF59D3', radius: 26 },    destination: `${ -350 - this.offsetX }px, ${ 100 - this.offsetY }px` },
    ];

    // Used to configure the burst animation.
    minBlastDist = 50;
    maxBlastDist = 400;
    scaleX = 1;
    scaleY = 0.6;

    // Holds the information for the created particles.
    particles = [];
    particleAnims = [];

    /**
     * The CONSTRUCTOR
     * @param props     The incoming props for this react element.
     */
    constructor( props ) {
        super( props );
        this.initializeParticleElements();
    }

    /**
     * The JSX.Element(s) that will be created and rendered whenever this react element is
     * asked to be rendered.
     */
    render() {
        return (
            <div className="particle-container">
                { this.particles }
                <style aria-hidden hidden>{ this.particleAnims.join( '\n' ) }</style>
            </div>
        );
    }

    /**
     * Initializes the `particles` array to contain JSX.Elements which are particles either
     * randomly generated or created using predefined particles in the `drivenParticles`
     * array.
     */
    initializeParticleElements() {
        // Generate particles
        const { time, particleCount, delay } = this.props;
        const numOfParticles = ( particleCount >= this.drivenParticles.length ) ? particleCount : this.drivenParticles.length;
        for ( let i = 0; i < numOfParticles; i++ ) {
            const particleName = `${ this.animationLabel }_${ i }`;
            const drivenParticle = this.getDrivenParticle( i );

            if ( drivenParticle ) {
                // --- If predefined information on a particle exists, use that for creation.
                this.particles[ i ] = this.createParticle( `particle-${ i }`, drivenParticle.details, particleName, time, delay );
                this.particleAnims.push( this.getParticleAnimation( particleName, drivenParticle.destination ) );
            } else {
                // --- If the predefined particle doesn't exist, generate a new one.
                const particleColor     = this.particleColors[ i % this.particleColors.length ];
                const particleRadius    = Math.floor( Math.random() * this.props.maxRadius ) + this.props.minRadius;
                const particleDetails = {
                    color: particleColor,
                    radius: particleRadius,
                    offsetX: 0,
                    offsetY: 0,
                };
                this.particles[ i ] = this.createParticle( `particle-${ i }`, particleDetails, particleName, time, delay );
                this.particleAnims.push( this.getParticleAnimation( particleName ) );
            }
        }
    }

    /**
     * Returns a random integer between the `min` and `max` provided (inclusive).
     * @param min   The minimum value for the returned integer.
     * @param max   The maximum value for the returned integer.
     */
    getRandom( min, max ) {
        return Math.floor( Math.random() * ( max - min + 1 ) ) + min;
    }

    /**
     * Generate a random destination in the form of a string to be used for particle
     * animations.
     */
    getRandomParticleDestination() {
        const dist  = this.getRandom( this.minBlastDist, this.maxBlastDist );
        const angle         = Math.random() * Math.PI * 2;
        const x             = Math.cos( angle ) * dist * this.scaleX;
        const y             = Math.sin( angle ) * dist * this.scaleY;
        return `${ x }px, ${ y }px`;
    }

    /**
     * Attempts to retrieve data for a predefined particle. If unable to get it, returns
     * null.
     * @param index     The index of the data to reach for.
     */
    getDrivenParticle( index )  {
        if ( index < 0 || index >= this.drivenParticles.length )  { return null; }
        return this.drivenParticles[ index ];
    }

    /**
     * Used to create and return a CSS animation for a particle in the form of a string.
     * @param animName      The name to assign to the new CSS animation.
     * @param destination   The target location for the particle to translate to.
     */
    getParticleAnimation( animName, destination = '' ) {
        const animationName     = animName;
        const animDest          = ( destination !== '' ) ? destination : this.getRandomParticleDestination();

        return `@keyframes ${ animationName } {
            0% {
                -webkit-transform: translate(0px, 0px);
                transform: translate(0px, 0px);
                opacity: 0;
            }
            100% {
                -webkit-transform: translate(${ animDest });
                transform: translate(${ animDest });
                opacity: 1;
            }
        }`;
    }

    /**
     * Using the particle details provided, the data is extracted and put into a new JSX.Element.
     * @param details       The details for the desired particle.
     */
    createParticle( id, details, animName, animDuration, animDelay = 0 ) {
        return (
            <Particle
                id={ id }
                key={ animName }
                color={ details.color }
                radius={ details.radius }
                animName={ animName }
                animDelay={ animDelay }
                animDuration={ animDuration }
                offsetX={ details.offsetX }
                offsetY={ details.offsetY }
            />
        );
    }
}

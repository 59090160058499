export const styles_config = 
    ` :host {
      box-shadow: none !important;
  }
  .category .sticky,
  .search input[type=search]::placeholder {
    color: #59606D;
    font-weight: 700;
    font-size: 10px;
    font-family: 'Poppins';
    text-transform: capitalize;
    padding-left: 8px;
      
  }
  .search .icon {
    content: url('https://api.biw.cloud/v1/images/search.svg');
    width: 20px; 
    height: 20px;
  }
  #nav button {
    color: none;
    transition: none;
  }
  #nav button :hover{
    background-color: #F5F7FA !important;
    border-radius: 4px;
  }
  #nav svg {
    width: 30px;
    height: 30px;
    color: rgb(89, 96, 109);
    padding: 5px;
  }
  button[aria-selected=true] svg {
    fill: rgb(var(--color-primary-none, 89, 96, 109));
    color: #59606D;
  }
  #nav .bar {
    background-color: rgb(var(--color-primary-none, 89, 96, 109));
    border-radius: 0px;
  }
  .skin-tone-button {
    border: none;
    margin: 6px;
    margin-left: 14px;
  }
  .skin-tone {
    width: 20px;
    height: 20px;
  }
  .skin-tone:after {
    box-shadow: none;
    border: none;
  }
  .skin-tone-1 {
    background-color: #F0C83B;
  }
  .search input  {
    font-size: 14px;
  }
  .search input[type="search"] {
    background-color: white;
    border: 1px solid #C9D1DF;
    border-radius: 6px;
    padding: 8px 2em 8px 2.2em;
    margin: 0px;
  }
  .search input[type="search"]:hover {
    border: 1px solid #9099A9;
}
  ::placeholder {
    color: #C9D1DF !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    }
    .margin-small-lr {
        display: none;
    }
    .skin-tone-button[aria-selected] {
        background-color: #F5F7FA;
        width: 36px !important;
        height: 36px !important;
    }
    .flex .flex-auto .flex-center .flex-middle {
        padding-left: 8px !important;
    }
    .skin-tone-button:hover {
        background-color: #F5F7FA;
        width: 36px !important;
        height: 36px !important;
    }
    .skin-tone-button:hover .skin-tone {
        width: 20px;
        height: 20px;
        transform: scale(.9);
    }
    .search .delete {
        content: url('https://api.biw.cloud/v1/images/close-icon.svg');
        height: 10px;
        width: 10px;
    }
    input::-webkit-textfield-decoration-container {
        padding-left: 10px;
    }
    .search input[type="search"]:focus, &:focus-within {
        box-shadow: 0px 0px 4px 0px #3272D9BF;
    }
    #nav:before {
        border-bottom: 1px solid #ECEFF4;
        background: transparent !important;
        top: 95% !important;
    }
    .menu {
        box-shadow: 0px 4px 16px 0px #34383F29;
    }
    .menu input[type="radio"]:checked + .option {
        border-radius: 25px;
        box-shadow: none;
    }
    .option:hover {
        background-color: #F5F7FA;
        border-radius: 25px;
        padding: 8px;
    }
    .option:hover .skin-tone {
        width: 18px;
        height: 18px;
    }
    .padding-lr {
        padding-right: 8px;
        padding-left: 8px;
    }
    .category button .background {
        background-color: #F5F7FA;
    }
    `;
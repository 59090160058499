import React from 'react';
import { SegmentData, createRing } from './award-creation-ring';
import { lruMemoize } from 'reselect';

/*interface Props {
    delay: number;
    revealTime: number;
    spinTime: number;
    spinAngleOffset: number;
    stroke: number;
    ringSpacing: number;
}*/

export class AwardRings extends React.Component {

    viewBoxDimensions = '';

    initializeRings = lruMemoize( ( delay, revealTime, spinTime, spinAngleOffset ) => {
        return this.createRingGroup( delay, revealTime, spinTime, spinAngleOffset );
    } );

    /**
     * The CONSTRUCTOR
     * @param props     The incoming props for this react element.
     */
    constructor( props ) {
        super( props );

        // Calculate the view area for the svg.
        const viewBoxSize = ( 2 * Math.sqrt( 2 ) * 100 ) - 75;
        this.viewBoxDimensions = `0, 0, ${ viewBoxSize }, ${ viewBoxSize }`;
    }

    /**
     * The JSX.Element(s) that will be created and rendered whenever this react element is asked
     * to be rendered.
     */
    render() {
        const { delay, revealTime, spinTime, spinAngleOffset } = this.props;
        const segments = this.initializeRings( delay, revealTime, spinTime, spinAngleOffset );

        const elems = segments.map( ( seg ) => seg.elem );
        const anims = segments.map( ( seg ) => seg.styleCSS ).join( '\n' );

        return (
            <svg className="award-rings-svg" viewBox={ this.viewBoxDimensions }>
                { elems }
                <style aria-hidden hidden>{ anims }</style>
            </svg>
        );
    }

    /**
     * Creates a default ring configuration (3 rings, preset colors, ...).
     * @param delayTime         The duration in which to wait for the animation
     * @param revealTime        The duration in which to reveal each segment around the ring.
     * @param spinTime          The duration in which the rings should spin around.
     * @param spinAngleOffset   The angle the rings should be offset after spinning.
     */
    createRingGroup( delayTime, revealTime, spinTime, spinAngleOffset ) {
        const { stroke } = this.props;
        const ring1 = createRing( 'ring_1', true, 80, stroke, [ '#FF651A', '#953AFF' ], delayTime, revealTime, spinTime, spinAngleOffset, 45 );
        const ring2 = createRing( 'ring_2', false, 90, stroke, [ '#47C0FF', '#FF2F27' ], delayTime, revealTime, spinTime, spinAngleOffset, 15 );
        const ring3 = createRing( 'ring_3', true, 100, stroke, [ '#FFC329', '#0079FC', '#EF59D3' ], delayTime, revealTime, spinTime, spinAngleOffset, 20 );
        return [ ...ring1, ...ring2, ...ring3 ];
    }
}

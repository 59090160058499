import {
    GameLoaderController,
    TranslationLoader,
} from '@nackle/arcade-core-v2';
import '@nackle/paper/dist/index.css';
import { Games } from '@nackle/arcade-core-v2';
import { DeckWrapper } from './components/DeckWrapper';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { appStore } from './app-store';
import './app.less';
import ArcadeIndex from './arcade-index/ArcadeIndex';
import { IMG_AWARD_SWIRL, LOADER_URL } from './assets';
import Auth from './auth';
import { ProjectKeys } from './translation-data';

const App = () => {
    useEffect( () => {
        console.log( 'appStore======>', appStore );
        console.log( 'LOADER_URL======>', LOADER_URL );
    }, [] );
    return (
        <Provider store={ appStore }>
            <Auth>
                <DeckWrapper>
                    <ArcadeIndex />
                    <TranslationLoader
                        projectKeys={ [
                            ProjectKeys.ARCADE_UI,
                            Games.cmxKeys.ARCADE_DATA_PROJECT_KEY,
                        ] }
                    />
                    <GameLoaderController
                        imageUrls={ { IMG_AWARD_SWIRL } }
                        loaderUrl={ LOADER_URL }
                    />
                </DeckWrapper>
            </Auth>
        </Provider>
    );
};

export default App;

import React from 'react';
import { Popover as Pover } from 'antd';
const Popover = ( { children, ...props } ) => {
        return (
            <Pover arrow={ { pointAtCenter: true } } { ...props }>
                { children }
            </Pover> );
};

export { Popover };

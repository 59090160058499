// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.90.3/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.instantPlay-container {
  flex-direction: column;
  align-items: center;
  background-color: white;
  height: 160px;
  border-width: 0;
  text-align: inherit;
  transition: transform 200ms;
  z-index: 0;
  position: relative;
}
.instantPlay-container.nkl-card {
  padding: 0;
}
.instantPlay-container:hover,
.instantPlay-container:focus {
  transform: scale(1.05);
}
.instantPlay-container .instant-play-cover-image-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.instantPlay-container .instant-play-cover-image-background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 3.33333333px;
  background-position: center;
  background-size: cover;
  animation: instant-play-image-fade-in 200ms;
  z-index: -1;
}
.instantPlay-container .instant-play-cover-image-foreground {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: -20px;
  height: calc(100% + 20px);
  animation: instant-play-image-fade-in 200ms;
}
.instantPlay-container .instantPlay-plays {
  position: absolute;
  right: 1em;
  bottom: 1em;
  padding: 4px 1em;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: lighter;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 9999px;
  color: white;
  line-height: 1em;
}
.instantPlay-container .instantPlay-plays .instantPlay-lozenge {
  font-weight: normal;
  font-size: 14px;
}
@keyframes instant-play-entrance {
  from {
    opacity: 0;
    transform: translate(0, -50%);
  }
  to {
    opacity: 1;
    transform: translate(0, 0%);
  }
}
@keyframes instant-play-image-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '../tooltip/tooltip.js';
import { getString } from '@nackle/intl-tools';
import { Achieved, AchievedRepeatable, NotAchieved, Error, InProgress, NotStarted } from './assets/badges.js';

import classNames from 'classnames';

const NackleStatusBadge = ( { className, status, tooltipProps } ) => {
    const badgeClasses = classNames( {
        'nackle-status-badge': true,
        [ status ]: true,
        [ className ]: className ? true : false
    } );
    switch ( status ) {
        case 'achieved':
            return (
                <Tooltip title={ getString( 'origami.statusBadge.complete' ) } { ...tooltipProps }>
                    <div className={ badgeClasses }>
                        <Achieved />
                    </div>
                </Tooltip>
            );
        case 'achieved-repeatable':
            return (
                <Tooltip title={ getString( 'origami.statusBadge.repeatable' ) } { ...tooltipProps }>
                    <div className={ badgeClasses }>
                        <AchievedRepeatable/>
                    </div>
                </Tooltip>
            );

        case 'error':
            return (
                <Tooltip title={ getString( 'origami.statusBadge.error' ) } { ...tooltipProps }>
                    <div className={ badgeClasses } { ...tooltipProps }>
                        <Error />
                    </div>
                </Tooltip>
            );
        case 'in-progress':
            return (
                <Tooltip title={ getString( 'origami.statusBadge.inProgress' ) } { ...tooltipProps }>
                    <div className={ badgeClasses } { ...tooltipProps }>
                        <InProgress />
                    </div>
                </Tooltip>
            );
        case 'not-achieved':
            return (
                <Tooltip title={ getString( 'origami.statusBadge.incomplete' ) } { ...tooltipProps }>
                    <div className={ badgeClasses } { ...tooltipProps }>
                        <NotAchieved />
                    </div>
                </Tooltip>
            );
        case 'not-started':
            return (
                <Tooltip title={ getString( 'origami.statusBadge.notStarted' ) } { ...tooltipProps }>
                    <div className={ badgeClasses }>
                        <NotStarted />
                    </div>
                </Tooltip>
            );
        default:
            return (
                <Tooltip title={ getString( 'origami.statusBadge.error' ) } { ...tooltipProps }>
                    <div className={ badgeClasses }>
                        <Error />
                    </div>
                </Tooltip>
            );
    }

};

NackleStatusBadge.propTypes = {
    /** optional class name for status badge */
    className: PropTypes.string,
    /** status of badge */
    status: PropTypes.oneOf( [ 'achieved', 'achieved-repeatable', 'error', 'in-progress', 'not-achieved', 'not-started' ] ),
    /** props for tooltip */
    tooltipProps: PropTypes.object
};

export { NackleStatusBadge };
import InjectTranslation from '../inject-translation';
import React, { Component } from 'react';
import { Button, Modal } from '@nackle/paper';
import { ParticleBurst } from '../effects/particle-brust/particle-brust';
import { AwardRings } from '../effects/award-rings/award-rings';
import { timeout } from '../util/delay';

const getAmount = ( value ) => {
  const num = Number( value );
  if ( isNaN( num ) ) {
    return 0;
  }
  return num;
};

// eslint-disable-next-line no-unused-vars
async function focusOnRef( ref ) {
  if ( ref ) {
    for ( let i = 0; i < 5; i++ ) {
      await timeout();
    }
    ref.focus();
  }
}
export class AwardDialog extends Component {
  state = {
    isLoading: false,
    reward: '',
  };

  render() {
    const { rewards, onPlayAgain, visible, onClose } = this.props;
    const rewardAmount = rewards.reduce(
      ( memo, reward ) => memo + getAmount( reward.value ),
      0
    );
    return (
      <>
        { visible && (
          <InjectTranslation
            params={ {
              [ 'game-loader.CLOSE' ]: null,
              [ 'game-loader.PLAY_AGAIN' ]: null,
              [ 'game-loader.YOU_WON' ]: null,
              [ 'game-loader.reward-unit.POINTS' ]: null,
              [ 'game-loader.MESSAGE' ]: { amount: rewardAmount },
            } }
          >
            { ( {
              'game-loader.CLOSE': closeText,
              'game-loader.PLAY_AGAIN': playAgainText,
              'game-loader.YOU_WON': youWonText,
              'game-loader.reward-unit.POINTS': pointsText,
              [ 'game-loader.MESSAGE' ]: message,
            } ) => (
              <Modal
                className="you-won-dialog"
                getContainer=".game-container"
                maskClosable={ false }
                keyboard={ false }
                closable={ false }
                visible={ visible }
                centered
                footer={ null }
              >
                <ParticleBurst
                  delay={ 0.2 }
                  time={ 1.4 }
                  particleCount={ 1 }
                  minRadius={ 10 }
                  maxRadius={ 25 }
                />

                <div className="content-container">
                  <div className="content-block">
                    <div className="award-display">
                      <div className="award-display__graphic-container">
                        <AwardRings
                          delay={ 0.0 }
                          revealTime={ 0.6 }
                          spinTime={ 1.0 }
                          spinAngleOffset={ 170 }
                          stroke={ 5 }
                          ringSpacing={ 10 }
                        />
                      </div>
                      <div className="award-display__text-container">
                        <span
                          className="award-dialog__award text-award"
                          data-testid="reward-amount"
                        >
                          { rewardAmount }
                        </span>
                        <span
                          className="award-dialog__label text-award-label"
                          data-testid="reward-type"
                        >
                          { pointsText }
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="content-block">
                    <div className="text-header center">
                      <p>{ youWonText }</p>
                    </div>
                    <div className="text-body center">
                      <p>{ message }</p>
                    </div>
                    <div className="footer">
                      <div className="footer-action-group">
                        { onPlayAgain && (
                          <Button
                            data-testid="play-again-button"
                            id="award_dialog_play_again"
                            type="primary"
                            loading={ this.state.isLoading }
                            onClick={ this.handleClickPlayAgain }
                          >
                            { playAgainText }
                          </Button>
                        ) }{ ' ' }
                        <Button
                          id="award_dialog_close"
                          type={ onPlayAgain ? undefined : 'primary' }
                          onClick={ onClose }
                        >
                          { closeText }
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            ) }
          </InjectTranslation>
        ) }
      </>
    );
  }

  handleClickPlayAgain = async () => {
    this.setState( { isLoading: true } );
    try {
      const { onPlayAgain } = this.props;
      if ( onPlayAgain ) {
        await onPlayAgain();
      }
    } finally {
      this.setState( { isLoading: false } );
    }
  };
}

import { Card } from '@nackle/paper';
import React, { Component } from 'react';

export class GameBannerContent extends Component {
    render() {
        const {
            description,
            header,
            playButton,
            preHeader,
        } = this.props;

        return (
            <Card className="nkl-dark game-banner-content">
                <div className="game-banner-info">
                    { preHeader && <div>{ preHeader }</div> }
                    <div className="game-banner-game-name">
                        { header }
                    </div>
                    <div className="game-banner-game-description">
                        { description }
                    </div>
                </div>
                { playButton }
            </Card>
        );
    }
}

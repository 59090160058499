import {
    PreloadImage,
    Translations,
    Games,
    Promotion } from '@nackle/arcade-core-v2';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Hero.less';
// Keep in sync with definition in Hero.scss
const HERO_FADEIN_MS = 500;

const mapStateToProps = ( state ) => {
    const maybePromotedGame = Promotion.getPromotedGame( state );
    // @ts-ignore
    const promotedGame = maybePromotedGame.data;

    if ( !promotedGame ) {
        return {
            altText: Translations.getTranslation(
                state,
                'arcade.banner.no-tokens.alt',
            )
        };
    } else {
        const altTextTranslation = Translations.getTranslation(
            state,
            Games.cmxKeys.formatCmxCabinetBannerAltKey( promotedGame.cabinetId ),
        );

        return {
            // if cabinet banner alt text does not exist, use rom banner alt text.
            altText: altTextTranslation ? altTextTranslation : Translations.getTranslation(
                state,
                Games.cmxKeys.formatCmxRomBannerAltKey( promotedGame.romId ),
            )
        };
    }
};

class Hero extends Component {
     state = {};

    UNSAFE_componentWillReceiveProps( nextProps, nextContext ) {
        this.handleFadeinComplete();
    }

    render() {
        const {
            backgroundImageUrl,
            children,
            altText
        } = this.props;
        const { fadeInImageUrl, previousBackgroundImageUrl } = this.state;

        return (
            <div
                className="hero"
                style={ { backgroundImage: `url(${ previousBackgroundImageUrl })` } }
            >
                { backgroundImageUrl &&
                    <PreloadImage
                        onPreloadComplete={ this.handleImageLoaded }
                        url={ backgroundImageUrl }
                        alt={ altText }
                    />
                }

                { fadeInImageUrl &&
                    <div
                        className="hero-background-fadein"
                        key={ fadeInImageUrl }
                        style={ { backgroundImage: `url(${ fadeInImageUrl }` } }
                    />
                }

                <div className="hero-content">
                    { children }
                </div>
            </div>
        );
    }

    handleImageLoaded = ( loadedImage ) => {
        this.setState( { fadeInImageUrl: loadedImage } );
        setTimeout( this.handleFadeinComplete, HERO_FADEIN_MS );
    };

    handleFadeinComplete = () => {
        this.setState( {
            fadeInImageUrl: undefined,
            previousBackgroundImageUrl: this.props.backgroundImageUrl,
        } );
    };
}

export default connect( mapStateToProps )( Hero );

import { Session } from '@nackle/arcade-core-v2';
import AppRoot from '@nackle/deck/src/deck/app-root/app-root';
import { auth } from 'config';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import './DeckWrapper.less';

const mapStateToProps = ( state ) => ( {
    authState: Session.getAuthState( state ),
    localeLoaded: Session.getLocale( state ),
} );

const mapDispatchToProps = {};

class DeckWrapper extends Component {
    state = {};

    componentDidCatch( error, errorInfo ) {
        console.error( 'Error in Deck:', error );
        this.setState( { error } );
    }

    render() {
        const { authState, localeLoaded, children } = this.props;
        console.log( 'auth state---------->', authState );
        const { error } = this.state;

        //@ts-ignore
        const locale = localeLoaded.data;

        return (
            ( authState && !error && (
                <AppRoot
                    auth={ auth }
                    user={ authState }
                    locale={ locale || 'en-US' }
                    guest={ false }
                    footer={ false }
                    leftNav={ false }
                >
                    { children }
                </AppRoot>
            ) ) || <div />
        );
    }
}

const decorated = connect( mapStateToProps, mapDispatchToProps )( DeckWrapper );
export { decorated as DeckWrapper };

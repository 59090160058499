import React, { useState } from 'react';
import { Tabs as Tbs } from 'antd';
import { Menu } from '../index';
import { MathPlus, Close, MoreDotsHorz } from '@nackle/origami-icons';

const Tabs = ( { children, ...props } ) => {
    const { size, tabPosition, onChange, type, items, className } = props;

    const [ activeKey, setActiveKey ] = useState( props.activeKey );

    const renderTabBar = ( props, DefaultTabBar ) => (
          <DefaultTabBar
            { ...props }
            mobile={ false }
          />
    );

    const onTabChange = ( key ) => {
        setActiveKey( key );
        onChange( key );
    };

    const menuOnClick = ( e ) => {
        setActiveKey( e.key );
        onChange( e.key );
    };

    const getMenuItem = ( label, key, icon, children, type ) => (
        { key, icon, children, label, type }
    );

    const getMenuOptions = () => {
        const menuItemsArray = items.map( item => { return ( getMenuItem( item.label, item.key ) ); } );
        menuItemsArray.forEach( menuItem => {
            const tabItem = items.find( ( item ) =>  menuItem.key === item.key );
            menuItem.disabled = tabItem.disabled;
        } );
        return menuItemsArray;
    };

    const menu = <Menu className={ 'more-tabs-menu' } items={ getMenuOptions() } onSelect={ menuOnClick } selectedKeys={ [ activeKey ] } ></Menu>;

    return (
        <React.Fragment>
            <Tbs
                className={ className }
                items={ items }
                size={ size }
                tabPosition={ tabPosition }
                type={ type }
                renderTabBar={ renderTabBar }
                more={ { icon: <MoreDotsHorz/>, overlay: menu } }
                activeKey={ activeKey }
                { ...props }
                onChange={ onTabChange }
            >
            </Tbs>
        </React.Fragment>
    );
};

Tabs.Icons = ( { children, ...props } ) => {
    const { size, tabPosition, items, onChange } = props;

    const [ activeKey, setActiveKey ] = useState( '1' );

    const renderTabBar = ( props, DefaultTabBar ) => (
        <DefaultTabBar
          { ...props }
          mobile={ false }
        />
    );

    const onTabChange = ( key ) => {
        setActiveKey( key );
        onChange( key );
    };

    const menuOnClick = ( e ) => {
        setActiveKey( e.key );
        onChange( e.key );
    };

    const getMenuItem = ( label, key, icon, children, type ) => (
        { key, icon, children, label, type }
    );

    const getMenuOptions = () => {
        const menuItemsArray = items.map( item => { return ( getMenuItem( item.label, item.key ) ); } );
        menuItemsArray.forEach( menuItem => {
            const tabItem = items.find( ( item ) =>  menuItem.key === item.key );
            menuItem.disabled = tabItem.disabled;
        } );
        return menuItemsArray;
    };

    const menu = <Menu className={ 'more-tabs-menu' } items={ getMenuOptions() } onSelect={ menuOnClick } selectedKeys={ [ activeKey ] } ></Menu>;

    return (
        <Tbs
            items={ items.map( ( item ) => {
                return (
                    {
                        ...item
                    }
                );
            } ) }
            size={ size }
            tabPosition={ tabPosition }
            renderTabBar={ renderTabBar }
            more={ { icon: <MoreDotsHorz/>, trigger: 'hover', overlay: menu } }
            activeKey={ activeKey }
            { ...props }
            onChange={ onTabChange }
        />
    );
};

Tabs.Card = ( { children, ...props } ) => {
    const { size, items, onChange, activeKey, onEdit } = props;
    switch ( size ) {
        case 'small':
            return <Tbs
                addIcon={ <MathPlus /> }
                onChange={ onChange }
                size={ size }
                type='editable-card'
                activeKey={ activeKey }
                onEdit={ onEdit }
                items={ items.map( ( item ) => {
                    return (
                        {
                            label: item.label,
                            key: item.key,
                            disabled: item.disabled ? item.disabled : false,
                            children: item.children,
                            closeIcon: <Close fill='#59606D' width='16' height='16' />,
                            ...item
                        }
                    );
                } ) }
            >
            </Tbs>;
        case 'middle':
            return <Tbs
                addIcon={ <MathPlus /> }
                onChange={ onChange }
                size={ size }
                type='editable-card'
                activeKey={ activeKey }
                onEdit={ onEdit }
                items={ items.map( ( item ) => {
                    return (
                        {
                            label: item.label,
                            key: item.key,
                            disabled: item.disabled ? item.disabled : false,
                            children: item.children,
                            closeIcon: <Close fill='#59606D' width='20' height='20' />,
                            ...item
                        }
                    );
                } ) }
            >
            </Tbs>;
        case 'large':
            return <Tbs
                addIcon={ <MathPlus /> }
                onChange={ onChange }
                size={ size }
                type='editable-card'
                activeKey={ activeKey }
                onEdit={ onEdit }
                items={ items.map( ( item ) => {
                    return (
                        {
                            label: item.label,
                            key: item.key,
                            disabled: item.disabled ? item.disabled : false,
                            children: item.children,
                            closeIcon: <Close fill='#59606D' width='24' height='24' />,
                            ...item
                        }
                    );
                } ) }
            >
            </Tbs>;
        default:
            return <Tbs
                addIcon={ <MathPlus /> }
                onChange={ onChange }
                size={ size }
                type='editable-card'
                activeKey={ activeKey }
                onEdit={ onEdit }
                items={ items.map( ( item ) => {
                    return (
                        {
                            label: item.label,
                            key: item.key,
                            disabled: item.disabled ? item.disabled : false,
                            children: item.children,
                            closeIcon: <Close fill='#59606D' width='20' height='20' />,
                            ...item
                        }
                    );
                } ) }
            >
            </Tbs>;
    }
};

export { Tabs };
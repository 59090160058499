import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Button as Btn } from 'antd';

const Button = forwardRef( ( props, ref ) => {
    const { rightIcon, children, classNameProps, ...restProps } = props;
    return rightIcon ? (
        <Btn { ...restProps } ref={ ref }>
            { children }
            <span className='ant-btn-icon ant-btn-icon-right'>{ rightIcon }</span>
        </Btn>
    ) : (
        <Btn { ...restProps }  ref={ ref }>
            { children }
        </Btn>
    );
} );

Button.propTypes = {
    /** Icon to display on right side of button */
    rightIcon: PropTypes.node
};

export { Button };

import React, { forwardRef, useState, useEffect } from 'react';
import { Popover } from '../popover/popover.js';
import { Button } from '../button/button.js';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { customCategoryIcons } from './nav-icon.js';
import { styles_config } from './emoji-style-config.js';
import { EmojiSmile } from '@nackle/origami-icons';
import { getString } from '@nackle/intl-tools';


const NackleEmojiPicker = forwardRef( ( props, ref ) => {
    const { locale, buttonProps, popoverOpen, onOpen } = props;
    const [ open, setOpen ] = useState( popoverOpen );
    delete data.emojis.middle_finger;

    const applyStyles = () => {
        setTimeout( () => {
            const containers = document.querySelectorAll( '.emoji-container' );
            if ( containers && containers.length > 0 ) {
                containers.forEach( container => {
                    const emojiPicker = container?.children[ 0 ]?.children[ 0 ];
                    const search = emojiPicker.shadowRoot.querySelector( 'input[type=search]' );
                    if ( search ) {
                        search.setAttribute( 'placeholder', getString( 'origami.emoji.search' ) );
                    }
                    const style = document.createElement( 'style' );
                    style.innerHTML = styles_config;
                    emojiPicker.shadowRoot.appendChild( style );
                } );
            }
        }, 1 );
        if ( typeof onOpen === 'function' ) {onOpen( true );}
    };
    useEffect( () => {
        setOpen( popoverOpen );
    }, [ popoverOpen ] );
    return (
        <Popover
            overlayClassName="picker-popover"
            trigger="click"
            open={ open }
            placement='top'
            onOpenChange={ applyStyles }
            hideHeader={ true }
            content={
                <div ref={ ref } className="emoji-container">
                    <Picker
                        data={ data }
                        set={ 'native' }
                        perLine={ 7 }
                        locale={ locale ? locale.split( '-' )[ 0 ] : 'en' }
                        navPosition={ 'top' }
                        previewPosition="none"
                        categories={ [
                            'frequent',
                            'people',
                            'nature',
                            'foods',
                            'activity',
                            'places',
                            'objects',
                            'symbols',
                            'flags'
                        ] }
                        categoryIcons={ customCategoryIcons }
                        { ...props }
                    />
                </div>
            }
        >
                <Button shape='circle' icon={ <EmojiSmile /> } { ...buttonProps } onClick={ () => setOpen( !open ) } aria-label={ getString( 'origami.emoji.buttonLabel' ) } />
         
        </Popover>
    );
} );

export { NackleEmojiPicker };

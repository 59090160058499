import { Deferred } from './util/deffered';
import { connectToClient } from './iframe-comms';
import { getString } from '@nackle/intl-tools';
//import game from '../game-loader/index.html'
//var __html = require('../game-loader/index.html');
//var template = { __html: __html };

/**
 * What reward the user will receive for completing the game
 */
/*export interface Reward {
    value: string;
    type: string;
}*/

/**
 * Configuration options passed to LoaderIframe through the Game Loader Component
 */

/**
 * Handles communication with the Game Loader on the other side of the iframe
 *
 * IMO this class has two problems:
 *
 * 1) It's not a React component, it's a class. I honestly don't know why it was originally implemented this way
 *    - It can't be easily converted because the iframe-comms library expects to be the one to add the iframe
 *      element to its immediate parent. I don't know why that decision was made, either.
 *
 * 2) It's not clear what API concerns it wraps. It passes through some game events verbatim and intercepts others.
 *    I can't tell what logic went into deciding one way or the other.
 */

export class LoaderIframe {
  el;
  container;
  connection;
  opts;
  packageLoaded = new Deferred();

  // The connection override options specified on the last call to connect()
  clientConnectOpts = {};
  constructor( opts ) {
    this.opts = opts;

    const { loaderUrl, onPageLoaded } = opts;
    this.el = opts.elOverride || document.createElement( 'iframe' );
    this.el.className = 'game-frame';
    this.el.setAttribute( 'sandbox', 'allow-same-origin allow-scripts' );
    this.el.setAttribute( 'src', loaderUrl );
    this.el.onload = onPageLoaded;
    this.container = opts.container;
  }

  /**
   * Wait for the game loader to load and establish a connection
   *
   * @param clientConnectOpts - Additional connection options. Mainly used for overriding things for unit tests.
   */
  connect( clientConnectOpts = {} ) {
    this.clientConnectOpts = clientConnectOpts;
    return this.reconnect();
  }

  /**
   * Restart the game
   */
  async start( payload ) {
    if ( this.connection ) {
      await this.connection.api.start( payload );
    } else {
      throw new Error( 'Cannot start the game: No active connection' );
    }
  }

  destroy() {
    if ( this.connection ) {
      this.connection.destroy();
    }
  }

  focus() {
    this.el.focus();
  }

  /**
   * Connect to the game loader using the current set of options, terminating any existing connection.
   */
  async reconnect() {
    if ( this.connection ) {
      this.connection.destroy();
    }

    const {
      config,
      container,
      env,
      getSession,
      gameplayUpdate,
      localeCode,
      onPackageLoadError,
      onPackageLoaded,
      openedAt,
      romId,
      romName,
      setLoadingProgress,
    } = this.opts;

    const methods = {
      getSession,
      gameplayUpdate,
      onPackageLoadError: ( e ) => {
        onPackageLoadError( e );
        this.packageLoaded.reject( e );
      },
      onPackageLoaded: () => {
        this.el.className = 'game-frame is-loaded';
        onPackageLoaded();
        this.packageLoaded.resolve();
      },
      getBounds: () => ( {
        height: window.innerHeight - 64,
        width: window.innerWidth,
      } ),
      getMetadata: () => ( {
        config: JSON.stringify( config ),
        env,
        openedAt,
        romId,
        romName,
        localeCode,
      } ),
      setLoadingProgress,

      /**
       * Reaches into 'intl-tools' and gets the translated value for the reward
       * label. Note that this is *temporary* until the reward matrix is further
       * developed and implemented.
       */
      getRewardLabel: () => getString( 'game-loader.reward-unit.POINTS' ),
    };

    this.connection = await connectToClient( {
      iframeEl: this.el,
      container,
      methods,
      ...this.clientConnectOpts,
    } );
  }
}

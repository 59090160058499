export const Env = Object.freeze( {
  LOCAL: 'local',
  QA: 'qa',
  PPRD: 'pprd',
  PROD: 'prod',
} );

export function isEnv( env ) {
  switch ( env ) {
    case Env.LOCAL:
    case Env.QA:
    case Env.PPRD:
    case Env.PROD:
      return true;
    default:
      return false;
  }
}

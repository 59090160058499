import React from 'react';
import { Switch as AntdSwitch } from 'antd';

const Switch = ( props ) => {

    const onChange = ( value ) => {
        if ( typeof props.onChange === 'function' ) {
            props.onChange( value );
        }
    };

    const getSizeClassNames = () => {
        if ( props.size === 'large' ) {
            return ( 'origami-switch origami-switch-large' );
        }
        else {
            return ( 'origami-switch' );
        }
    };

    return (
        <AntdSwitch
            className={ getSizeClassNames() }
            onChange={ onChange }
            size={ props.size === 'small' ? 'small' : 'default' }
            disabled={ props.disabled }
            defaultChecked={ props.defaultChecked }
            loading={ props.loading }
            checked={ props.checked }
        ></AntdSwitch>
    );
};

export { Switch };
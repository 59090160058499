export class Deferred {

    constructor( resolve, reject ) {
        this.resolve = undefined;
        this.reject = undefined;
    }

    // tslint:disable-next-line:member-ordering
    promise = new Promise( ( resolveFn, rejectFn ) => {
        this.resolve = resolveFn;
        this.reject = rejectFn;
    } );
}
import React, { forwardRef } from 'react';
import { Popover, Button } from '../index.js';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { customCategoryIcons } from './nav-icon.js';
import { styles_config } from './emoji-style-config.js';
import { EmojiSmile } from '@nackle/origami-icons';


const NackleEmojiPicker = forwardRef( ( { ref, ...props } ) => {
    const { locale, buttonProps } = props;

    delete data.emojis.middle_finger;

    const applyStyles = () => {
        setTimeout( () => {
            const containers = document.querySelectorAll( '.emoji-container' );
            if ( containers && containers.length > 0 ) {
                containers.forEach( container => {
                    const emojiPicker = container?.children[ 0 ]?.children[ 0 ];
                    const style = document.createElement( 'style' );
                    style.innerHTML = styles_config;
                    emojiPicker.shadowRoot.appendChild( style );
                } );
                }
            }, 1 );
    };
    return (
       <Popover
            overlayClassName="picker-popover"
            trigger="click"
            placement='top'
            onOpenChange={ applyStyles }
            content={
                <div ref ={ ref } className="emoji-container">
                    <Picker
                        data={ data }
                        set={ 'native' }
                        perLine={ 7 }
                        locale={ locale ? locale.split( '-' )[ 0 ] : 'en' }
                        navPosition={ 'top' }
                        previewPosition="none"
                        categories={ [
                            'frequent',
                            'people',
                            'nature',
                            'foods',
                            'activity',
                            'places',
                            'objects',
                            'symbols',
                            'flags'
                        ] }
                        categoryIcons={ customCategoryIcons }
                        { ...props }
                    />
             </div>
        }
    >
            <Button shape='circle' icon={ <EmojiSmile /> } { ...buttonProps } />
    </Popover>
        );
} );

export { NackleEmojiPicker };

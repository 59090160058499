import React, { useState } from 'react';
import { LocaleString } from '@nackle/intl-tools';
import { Button, Modal } from '@nackle/paper';

const IntroductionDialog = ( props ) => {
    const [ isLoading, setIsLoading ] = useState( false );
    const {
        instructions,
        visible,
        onClose,
    } = props;
    const handleClickPlay = async () => {
        setIsLoading( true );
        try {
            await props.onPlay();
        } finally {
            setIsLoading( false );

        }
    };
    return (
        <Modal
            getContainer=".game-container"
            maskClosable={ false }
            closable={ false }
            centered
            visible={ visible }
            title={ <LocaleString code="game-loader.INTRODUCTION_MODAL.HEADER" /> }
            footer={ [
                (
                    <Button
                        disabled={ isLoading }
                        id="introduction_dialog_cancel"
                        size="large"
                        onClick={ onClose }
                    >
                        <LocaleString code="game-loader.INTRODUCTION_MODAL.CANCEL_BUTTON" />
                    </Button>
                ), (
                    <Button
                        data-testid="play-game-button"
                        id="introduction_dialog_play"
                        type="primary"
                        size="large"
                        loading={ isLoading }
                        onClick={ handleClickPlay }
                    >
                        <LocaleString code="game-loader.INTRODUCTION_MODAL.PLAY_BUTTON" />
                    </Button>
                ),
            ] }
        >
            { instructions || <LocaleString code="game-loader.FALLBACK_INSTRUCTIONS" /> }
        </Modal>
    );
};

export default IntroductionDialog;
import { Env, isEnv } from './types';

export const ARCADE_ROOT = '/arcadeapi/services/v1';

export function apiRootUrl( env ) {
    if ( isEnv( env ) ) {
        const isProd = env === 'prod';
        const envPrefix = isProd ? '' : `${ env }.`;
        return `https://${ envPrefix }api.biw.cloud`;
    }
    throw new Error( `Illegal environment ${ env }` );
}

export function gameRomUrl( env, romId ) {
    if ( env === Env.LOCAL ) {
        return '/game.zip';
    } else {
        return `${ apiRootUrl( env ) }${ ARCADE_ROOT }/roms/${ encodeURIComponent( romId ) }/file`;
    }
}

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Password from '../forms/input/password';
import { Form } from '../forms';
import { Item } from '../forms/form/item';
import {
    Check,
    Circle,
    CloseLarge,
    Help
} from '@nackle/origami-icons';
import { languageChangeEvent } from '@nackle/intl-tools';
import { NackleCaption } from '../../exports';
import { getString } from '@nackle/intl-tools';

class NacklePasswordHelper extends React.Component {
    constructor( props ) {
        super( props );
        this.pwordRef = React.createRef();
        this.confirmPwordRef = React.createRef();

        this.state = {
            focused: false,
            focusedConfirm: false,
            allCriteriaMet: false,
            complexityCriteriaMet: false,
            validation: {},
            localeUpdated: false,
            confirmPassword: '',
            passwordMatch: '',
            showError: false
        };
    }

    static getDerivedStateFromProps( props, state ) {

        const {
            value = '',
            meetsAllCriteria,
            passwordRules,
            passwordRules: { complexityRequirement }
        } = props;

        const {
            allCriteriaMet: prevCriteriaMet,
            currentPwdLength: prevPasswordLength
        } = state;

        const complexity = passwordRules?.complexity || [];
        const currentPwdLength = value.length;

        if ( prevCriteriaMet && prevPasswordLength === currentPwdLength ) {
            return null;
        }

        const complexityTests = {
            isLowerCaseRequired: /^(?=.*[a-z]).+$/,
            isUpperCaseRequired: /^(?=.*[A-Z]).+$/,
            isSpecialCharRequired: /[-!$%#@^&*()_+|~=`{}[\]:";'<>?,./\\]/,
            isNumberRequired: /[0-9]/
        };

        const validation = {
            lengthValid: currentPwdLength >= passwordRules?.minLength,
            complexityValidation: {}
        };

        let complexityResults = [];
        let complexityCount = 0;
        let complexityCriteriaMet = false;
        let allCriteriaMet = false;

        complexity.forEach( rule => {
            validation.complexityValidation[ rule ] = complexityTests[ rule ].test( value );
        } );

        complexityResults = Object.values( validation.complexityValidation );

        complexityCount = complexityResults.reduce( ( count, valid ) => {
            return valid ? ++count : count;
        }, 0 );

        complexityCriteriaMet = complexityCount >= complexityRequirement;

        allCriteriaMet = validation.lengthValid && complexityCriteriaMet;

        if ( typeof meetsAllCriteria === 'function' ) {
            if ( allCriteriaMet || ( prevCriteriaMet && !validation.lengthValid ) ) {
                meetsAllCriteria( allCriteriaMet );
            }
        }
        return {
            validation,
            complexityCriteriaMet,
            allCriteriaMet,
            currentPwdLength
        };
    }

    handleFocus = ( event, type ) => {
        const { onFocus } = this.props;
        event && event.persist();
        if ( type === 'confirm_password' ) {
            this.setState( { focusedConfirm: true } );
            this.setState( { focused: true } );
            this.setState( { showError: true } );
        } else {
            this.setState( { focused: true } );
        }
        if ( typeof onFocus === 'function' ) {
            onFocus( event );
        }
    };

    handleBlur = ( event ) => {
        const { onBlur } = this.props;
        event && event.persist();
        const { displayRequirement } = this.props;
        if ( !displayRequirement ) {
            this.setState( { focusedConfirm: false } );
            this.setState( { focused: false } );
        }
        if ( typeof onBlur === 'function' ) {
            onBlur( event );
        }
    };
    checkPasswordsMatch = () => {
        const { confirmPasswordValue, value } = this.props;
        if ( confirmPasswordValue && confirmPasswordValue.length != 0 ) {
            this.setState( { passwordMatch: confirmPasswordValue === value } );
        } else {
            this.setState( { passwordMatch: '' } );
        }
    };
    onChangePassword = ( e ) => {
        this.props.onChangePassword( e );
        this.setState( { showError: false } );
        
    };
    onChangeConfirmPassword = ( e ) => {
        const { validation: { lengthValid }, complexityCriteriaMet } = this.state;
        const passwordMatch = e.target.value === this.props.value;
        const validPassword = ( passwordMatch && lengthValid && complexityCriteriaMet );
      
        this.setState( { isPasswordValid: validPassword }, this.props.onChangeConfirmPassword( e.target.value, validPassword ) );
    };

    componentDidMount() {
        languageChangeEvent.on( ( { localeCode, bundle } ) => {
            this.setState( {
                localeUpdated: !this.state.localeUpdated
            } );
        } );
        this.checkPasswordsMatch();
    }

    componentDidUpdate( prevProps, prevState ) {
        if ( this.props.confirmPasswordValue !== prevProps.confirmPasswordValue ||
            this.props.value !== prevProps.value ) {
            this.checkPasswordsMatch();
        }
        if ( this.props.displayRequirement !== prevProps.displayRequirement ) {
            this.handleBlur();
        }
    }
    componentWillUnmount() {
        clearTimeout( null );
    }
        
    render() {
        const {
            className,
            passwordRules = {},
            passwordRulesLabels = {},
            value,
            name,
            label,
            helpComponent,
            meetsAllCriteria,
            localeUpdated,
            instructions,
            allInstructions,
            type,
            customPasswordPlaceholder,
            customConfirmPasswordPlaceholder,
            confirmPasswordValue,
            size,
            helperLabelProps,
            caption,
            tooltipText,
            helperCaptionProps,
            displayRequirement,
            ...props
        } = this.props;

        const complexityRules = passwordRules?.complexity || [];
        const complexityLabels = passwordRulesLabels?.complexity || {};
        const {
            focused,
            complexityCriteriaMet,
            validation: { complexityValidation, lengthValid },
            focusedConfirm,
            showError,
            passwordMatch
        } = this.state;

        const classes = classNames(
            'nkl-password-helper',
            'nkl-text-input',
            className
        );

        const minLengthClass = value === '' ?
            'length nkl-password-helper--initial' : lengthValid
                ? 'length nkl-password-helper--passes'
                : 'length nkl-password-helper--fails';

        const complexityClass = value === '' ?
            'complexity nkl-password-helper--initial' :
            complexityCriteriaMet
                ? 'complexity nkl-password-helper--passes'
                : 'complexity nkl-password-helper--fails';

        /* Default value */
        const labelObj = {
            password: getString( 'origami.passwordHelper.password' ),
            confirmPassword: getString( 'origami.passwordHelper.confirmPass' )
        };
        const captionDefault = getString( 'origami.passwordHelper.criteria.confirmPass' );

        return (
            <div className={ classes } style={ { 'width': props.width } }>

                {
                    helpComponent
                    && <span className={ 'nkl-input-helper' }>{ helpComponent }</span>
                }
                <div
                    className='nkl-password-helper-inner'
                    id={ 'password-input' }
                    ref={ this.pwordRef }>
                    {
                        type === 'CONFIRM_PASSWORD' ?
                            <Form
                                name="dependencies"
                                autoComplete="off"
                                layout="vertical"
                                { ...props }
                            >
                                <Item
                                    name="password"
                                >
                                    <Password
                                        label={ label === false ? false : true }
                                        name={ name }
                                        size={ size }
                                        value={ value }
                                        labelProps={ {
                                            text: helperLabelProps?.text ? helperLabelProps.text : labelObj.password,
                                            icon: helperLabelProps?.tooltipText ? <Help /> : null,
                                            size: { size },
                                            ...helperLabelProps
                                        } }
                                        caption={ caption === false ? false : true }
                                        onChange={ ( e ) => this.onChangePassword( e ) }
                                        { ...props }
                                        placeholder={ customPasswordPlaceholder }
                                        onBlur={ ( e ) => this.handleBlur( e ) }
                                        onFocus={ ( e ) => this.handleFocus( e, 'password' ) }
                                        captionProps={ {
                                            size: size, text:
                                                focused ?
                                                    <div className={ 'nkl-password-helper-rules' } aria-label="password requirements" >
                                                        <ul>
                                                            <li className={ minLengthClass }>
                                                                { value === '' ? <Circle className={ 'nkl-icon' } /> : lengthValid ? <Check className={ 'nkl-icon' } /> : <CloseLarge className={ 'nkl-icon' } /> }

                                                                <div>{ passwordRulesLabels?.minLength }</div>
                                                            </li>

                                                            <li className={ complexityClass }>
                                                                <div className='complex-requirement'>
                                                                    { value === '' ? <Circle className={ 'nkl-icon' } /> : complexityCriteriaMet ? <Check className={ 'nkl-icon' } /> : <CloseLarge className={ 'nkl-icon' } /> }

                                                                    <div>{ passwordRulesLabels?.complexityRequirement }</div>
                                                                </div>
                                                                <ul>
                                                                    { complexityRules.map( rule => {
                                                                        const classes = value === '' ?
                                                                            'nkl-password-helper--initial' :
                                                                            complexityValidation[ rule ]
                                                                                 ? 'nkl-password-helper--passes' : showError ? 'nkl-password-helper--initial' : 'nkl-password-helper--initial';
                                                                        return <li key={ rule } className={ classes } >
                                                                            { value === '' ? <Circle className={ 'nkl-icon' } /> : complexityValidation[ rule ] ? <Check className={ 'nkl-icon' } /> : showError ? <Circle className={ 'nkl-icon' } /> : <Circle className={ 'nkl-icon' } />  }

                                                                            <div>{ complexityLabels[ rule ] }</div>
                                                                        </li>;
                                                                    } ) }
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    : null
                                        } }
                                    />
                                </Item>
                                <Item
                                    name="Confirmpassword"
                                    dependencies={ [ 'password' ] }
                                >
                                    <Password
                                        label={ label === false ? false : true }
                                        name={ name }
                                        size={ size }
                                        labelProps={ {
                                            ...helperLabelProps,
                                            text: helperLabelProps?.confirmText ? helperLabelProps.confirmText : labelObj.confirmPassword,
                                            size: { size },
                                        } }
                                        caption={ caption === false ? false : true }
                                        value={ confirmPasswordValue }
                                        onChange={ ( thing ) => this.onChangeConfirmPassword( thing ) }
                                        onBlur={ ( e ) => this.handleBlur( e ) }
                                        onFocus={ ( e ) => this.handleFocus( e, 'confirm_password' ) }
                                        { ...props }
                                        placeholder={ customConfirmPasswordPlaceholder }
                                        captionProps={ {
                                            size: size, text: focusedConfirm ?
                                                <div className={ passwordMatch === '' ?
                                                    'confirm nkl-password-helper--initial' :
                                                    passwordMatch
                                                        ? 'confirm nkl-password-helper--passes'
                                                        : 'confirm nkl-password-helper--fails' }> { passwordMatch === '' ? <Circle className={ 'nkl-icon' } /> : passwordMatch ? <Check className={ 'nkl-icon' } /> : <CloseLarge className={ 'nkl-icon' } /> } { <NackleCaption size={ size } text={ helperCaptionProps.text || captionDefault } /> } </div>
                                                : null
                                        } }
                                    />
                                </Item>
                            </Form>
                            :
                            <Form>
                                <Item
                                    name="password"
                                >
                                    <Password
                                        label={ label === false ? false : true }
                                        name={ name }
                                        size={ size }
                                        value={ value }
                                        labelProps={ {
                                            text: helperLabelProps?.text ? helperLabelProps.text : labelObj.password,
                                            icon: helperLabelProps?.tooltipText ? <Help /> : null,
                                            size: { size },
                                            ...helperLabelProps
                                        } }
                                        caption={ caption === false ? false : true }
                                        onChange={ ( e ) => this.onChangePassword( e ) }
                                        placeholder={ customPasswordPlaceholder }
                                        onBlur={ ( e ) => this.handleBlur( e ) }
                                        onFocus={ ( e ) => this.handleFocus( e, 'password' ) }
                                        { ...props }
                                        captionProps={ {
                                            size: size, text: focused ?
                                                <div className={ 'nkl-password-helper-rules' } aria-label="password requirements" >
                                                    <ul>
                                                        <li className={ minLengthClass }>
                                                            { value === '' ? <Circle className={ 'nkl-icon' } /> : lengthValid ? <Check className={ 'nkl-icon' } /> : <CloseLarge className={ 'nkl-icon' } /> }

                                                            <div>{ passwordRulesLabels?.minLength }</div>
                                                        </li>

                                                        <li className={ complexityClass }>
                                                            <div className='complex-requirement'>
                                                                { value === '' ? <Circle className={ 'nkl-icon' } /> : complexityCriteriaMet ? <Check className={ 'nkl-icon' } /> : <CloseLarge className={ 'nkl-icon' } /> }

                                                                <div>{ passwordRulesLabels?.complexityRequirement }</div>
                                                            </div>
                                                            <ul>
                                                                { complexityRules.map( rule => {
                                                                    const classes = value === '' ?
                                                                        'nkl-password-helper--initial' :
                                                                        complexityValidation[ rule ]
                                                                        ? 'nkl-password-helper--passes' : showError ? 'nkl-password-helper--initial' : 'nkl-password-helper--initial';
                                                                    return <li key={ rule } className={ classes } >
                                                                       { value === '' ? <Circle className={ 'nkl-icon' } /> : complexityValidation[ rule ] ? <Check className={ 'nkl-icon' } /> : showError ? <Circle className={ 'nkl-icon' } /> : <Circle className={ 'nkl-icon' } />  }

                                                                        <div>{ complexityLabels[ rule ] }</div>
                                                                    </li>;
                                                                } ) }
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                                : null
                                        } }
                                    />
                                </Item>
                            </Form>
                    }

                </div>
            </div>
        );
    }
}

NacklePasswordHelper.propTypes = {
    /** optional class name */
    className: PropTypes.string,
    /** object of password rules */
    passwordRules: PropTypes.object,
    /** object of labels for rules */
    passwordRulesLabels: PropTypes.object,
    /** password input value */
    value: PropTypes.string,
    /** password input name */
    name: PropTypes.string,
    /** password input label */
    label: PropTypes.string,
    /** input helper component */
    helpComponent: PropTypes.node,
    /** callback when all criteria have been met */
    meetsAllCriteria: PropTypes.func,
    /** onFocus callback  */
    onFocus: PropTypes.func,
    /** onBlur callback */
    onBlur: PropTypes.func
};
export { NacklePasswordHelper };

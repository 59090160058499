import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const NackleRewardChip = ( { className, src, alt, earned, content, suffixText, lazy } ) => {
    const tileClasses = classNames( {
        'nackle-chip': true,
        'with-image': src ? true : false,
        earned: earned ? true : false,
        [ className ]: className ? true : false
    } );
    return (
        <div className={ tileClasses }>
            { src &&
                <img className="chip-image" src={ src } alt={ alt } loading={ lazy ? 'lazy' : 'eager' } />
            }
            <div className="chip-content">
                { content }
            </div>
            <div className="chip-suffix">
                { suffixText }
            </div>
        </div>
    );
};

NackleRewardChip.propTypes = {
    /** optional class name */
    className: PropTypes.string,
    /** image source for chip */
    src: PropTypes.string,
    /** alt text for chip image */
    alt: PropTypes.string,
    /** earned status */
    earned: PropTypes.bool,
    /** content of chip */
    content: PropTypes.node,
    /** suffix text of chip */
    suffixText: PropTypes.node
};
export { NackleRewardChip };
import React from 'react';
import { Popover, Button, NackleItem } from '..';
import classNames from 'classnames';
const NackleItemList = ( props ) => {
	const { items, size } = props;
    items.sort( ( a, b ) => { a.order > b.order ? 1 : -1; } );
	return (
		<>
			{ items.map( ( item, index ) => {
				return (
						<NackleItem
                            key={ index }
							leftIcon={ item?.leftIcon }
							title={ item?.title }
							description={ item?.description }
							itemOnClick={ item?.onClick }
							size={ size || '' }
						></NackleItem>
				);
			} ) }
		</>
	);
};

const NackleMultiButton = ( props ) => {
	const {
		left,
		right,
		popoverProps,
		current,
		onClick,
		menuSize,
		itemProps,
		items,
        size,
		...restProps
	} = props;
	const buttonClasses = classNames( {
		'left-button ant-btn-icon-only': left.icon ? true : false,
		'left-button': left.text ? true : false,
	} );
	if ( left.menu ) {
		return (
			<div className='multi-button'>
				<Popover
					placement='bottomLeft'
					overlayClassName='popover-menu multi-menu'
					hideHeader={ true }
					content={ <NackleItemList items={ items }  size={ size } /> }
					trigger="click"
					{ ...popoverProps }
				>
					<Button
						{ ...restProps }
						className='left-button'
						icon={ left?.icon }
                        size={ size }
					></Button>
				</Popover>
				<Button
					{ ...restProps }
					className='right-button'
					onClick={ right.onClick }
                    size={ size }
				>
					{ right.text ? right.text : right.icon }
				</Button>
			</div>
		);
	} else if ( right.menu ) {
		return (
			<div className='multi-button'>
				<Button
					{ ...restProps }
					className={ buttonClasses }
					onClick={ left.onClick }
                    size={ size }
				>
					{ left.text ? left.text : left.icon }
				</Button>
				<Popover
					placement='bottomRight'
					overlayClassName='popover-menu multi-menu'
					hideHeader={ true }
					content={ <NackleItemList items={ items }  size={ size } /> }
					trigger="click"
					{ ...popoverProps }
				>
					<Button
						{ ...restProps }
						className='right-button'
						icon={ right?.icon }
                        size={ size }
					></Button>
				</Popover>
			</div>
		);
	} else {
		return (
			<div className='multi-button'>
				<Button
					{ ...restProps }
					className='icon-left-button'
					onClick={ left.onClick }
                    size={ size }
				>
					{ left.icon }
				</Button>
				<Button
					{ ...restProps }
					className='icon-right-button'
					onClick={ right.onClick }
                    size={ size }
				>
					<span>{ left.icon ? left.icon : left.text }</span>
				</Button>
			</div>
		);
	}
};
export { NackleMultiButton };

export const customCategoryIcons = {

    frequent: {
        svg: `<svg fill-rule="evenodd" width="20" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.5 1.66663C5.90835 1.66663 2.16669 5.40829 2.16669 9.99996C2.16669 14.5916 5.90835 18.3333 10.5 18.3333C15.0917 18.3333 18.8334 14.5916 18.8334 9.99996C18.8334 5.40829 15.0917 1.66663 10.5 1.66663ZM10.5 16.6666C6.82502 16.6666 3.83335 13.675 3.83335 9.99996C3.83335 6.32496 6.82502 3.33329 10.5 3.33329C14.175 3.33329 17.1667 6.32496 17.1667 9.99996C17.1667 13.675 14.175 16.6666 10.5 16.6666ZM11.3417 10.1L14.3667 12.6916L13.2834 13.9583L9.68335 10.8666V4.99163L11.35 4.99163L11.35 10.1H11.3417Z" fill="currentColor"/>
        </svg>`
    },
    people: {
        svg: `<svg fill-rule="evenodd" width="20" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.375 1.66663C5.78335 1.66663 2.04169 5.40829 2.04169 9.99996C2.04169 14.5916 5.78335 18.3333 10.375 18.3333C14.9667 18.3333 18.7084 14.5916 18.7084 9.99996C18.7084 5.40829 14.9667 1.66663 10.375 1.66663ZM10.375 16.6666C6.70002 16.6666 3.70835 13.675 3.70835 9.99996C3.70835 6.32496 6.70002 3.33329 10.375 3.33329C14.05 3.33329 17.0417 6.32496 17.0417 9.99996C17.0417 13.675 14.05 16.6666 10.375 16.6666ZM13.725 10.7916L14.8167 11.4C13.9084 13.0416 12.2084 14.0583 10.375 14.0583C8.54169 14.0583 6.85002 13.0416 5.93335 11.4L7.02502 10.7916C7.71669 12.0333 9.00002 12.8083 10.375 12.8083C11.75 12.8083 13.0334 12.0333 13.725 10.7916ZM12.0417 8.33329C12.0417 7.87496 12.4167 7.49996 12.875 7.49996C13.3334 7.49996 13.7084 7.87496 13.7084 8.33329C13.7084 8.79163 13.3334 9.16663 12.875 9.16663C12.4167 9.16663 12.0417 8.79163 12.0417 8.33329ZM7.04169 8.33329C7.04169 7.87496 7.41669 7.49996 7.87502 7.49996C8.33335 7.49996 8.70835 7.87496 8.70835 8.33329C8.70835 8.79163 8.33335 9.16663 7.87502 9.16663C7.41669 9.16663 7.04169 8.79163 7.04169 8.33329Z" fill="currentColor"/>
        </svg>`
    },
    nature: {
        svg: `<svg width="20" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4584 15.7979C12.8887 15.7323 12.3487 15.5762 11.8549 15.3333H8.04169V17.7083C8.04169 18.0535 7.76187 18.3333 7.41669 18.3333C7.07151 18.3333 6.79169 18.0535 6.79169 17.7083V15.3333H2.41669L7.41669 3.33329L9.32948 7.924C9.98341 4.79928 11.8665 1.66663 14.0834 1.66663C16.8417 1.66663 19.0834 6.51663 19.0834 10.1666C19.0834 13.5366 17.1725 15.5142 14.7084 15.7979V17.7083C14.7084 18.0535 14.4285 18.3333 14.0834 18.3333C13.7382 18.3333 13.4584 18.0535 13.4584 17.7083V15.7979ZM14.0834 3.33329C15.4334 3.33329 17.4167 6.93329 17.4167 10.1666C17.4167 12.2837 16.4775 13.7132 14.91 14.0759L16.3101 10.7242C16.4431 10.4057 16.2928 10.0396 15.9743 9.90659C15.6558 9.77354 15.2897 9.92388 15.1566 10.2424L14.7084 11.3156V6.66663C14.7084 6.32145 14.4285 6.04163 14.0834 6.04163C13.7382 6.04163 13.4584 6.32145 13.4584 6.66663V9.60269L13.1935 8.96771C13.0607 8.64913 12.6947 8.49858 12.3761 8.63145C12.0575 8.76432 11.907 9.13029 12.0398 9.44887L13.4584 12.8501V14.1161C11.7701 13.8325 10.75 12.3729 10.75 10.1666C10.75 6.93329 12.7334 3.33329 14.0834 3.33329ZM8.04169 13.6666V12.0833C8.04169 11.7381 7.76187 11.4583 7.41669 11.4583C7.07151 11.4583 6.79169 11.7381 6.79169 12.0833V13.6666H4.91669L7.41669 7.66663L9.91669 13.6666H8.04169Z" fill="currentColor"/>
        </svg>
        `
    },
    foods: {
        svg: `<svg fill-rule="evenodd" width="20" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.625 9.89505C11.1869 9.52524 10.125 8.22052 10.125 6.66663V1.66663H16.7917V6.66663C16.7917 8.22052 15.7298 9.52524 14.2917 9.89505V11.6666H18.4584V12.5C18.4584 14.6 16.6 16.3666 13.4584 17.3V18.3333H6.79169V17.3C3.65002 16.3666 1.79169 14.5916 1.79169 12.5V11.6666H6.25947L2.94995 7.03329C2.74995 6.74996 2.81662 6.35829 3.09162 6.15829C3.37495 5.95829 3.75828 6.02496 3.96662 6.29996L7.79995 11.6666H8.22883L4.55838 3.59163C4.41671 3.27496 4.55838 2.90829 4.86671 2.76663C5.18338 2.62496 5.55005 2.76663 5.69171 3.07496L9.59702 11.6666H12.625V9.89505ZM11.7917 6.66663V5.62496H13.4584C13.8 5.62496 14.0834 5.34163 14.0834 4.99996C14.0834 4.65829 13.8 4.37496 13.4584 4.37496H11.7917V3.33329H15.125V6.66663C15.125 7.58329 14.375 8.33329 13.4584 8.33329C12.5417 8.33329 11.7917 7.58329 11.7917 6.66663ZM8.45835 16.0166V16.6666H11.7917V16.0166L12.425 15.8583C14.5334 15.3333 16.05 14.3916 16.5834 13.3333H3.66669C4.20002 14.3916 5.71669 15.3333 7.82502 15.8583L8.45835 16.0166Z" fill="currentColor"/>
        </svg>
        `
    },
    activity: {
        svg: `<svg fill-rule="evenodd" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.0711 19.0711C22.9763 15.1658 22.9763 8.83418 19.0711 4.92893C15.1658 1.02369 8.83418 1.02369 4.92893 4.92893C1.02369 8.83418 1.02369 15.1658 4.92893 19.0711C8.83418 22.9763 15.1658 22.9763 19.0711 19.0711ZM19.9426 11.0407C19.7364 9.32374 18.9745 7.6608 17.6569 6.34315C16.3343 5.0206 14.6639 4.25792 12.9402 4.0551L12.93 4.11139C12.1086 8.58308 8.58304 12.1086 4.11136 12.93L4.05511 12.9402C4.25793 14.6639 5.02061 16.3343 6.34315 17.6569C7.66079 18.9745 9.32373 19.7364 11.0407 19.9426C11.0449 19.9191 11.0491 19.8956 11.0534 19.8721C11.8748 15.4004 15.4003 11.8748 19.872 11.0534C19.8955 11.0491 19.9191 11.0449 19.9426 11.0407ZM19.872 13.4292V13.0951C16.5073 13.8586 13.8586 16.5073 13.095 19.8721H13.429C14.9777 19.5924 16.4598 18.8539 17.6569 17.6569C18.8539 16.4598 19.5923 14.9778 19.872 13.4292ZM4.11136 10.8884L4.11136 10.666C4.37766 9.08301 5.12159 7.5647 6.34315 6.34315C7.56465 5.12165 9.08288 4.37773 10.6658 4.11139L10.8883 4.11139C10.1248 7.47615 7.47612 10.1249 4.11136 10.8884Z" fill="currentColor"/>
        </svg>
        `
    },
    places: {
        svg: `<svg fill-rule="evenodd" width="20" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" xmlns="http://www.w3.org/2000/svg" d="M20.665 2.78748C20.225 2.03748 19.265 1.77748 18.505 2.20748L14.315 4.62748L8.355 2.43748C6.715 1.83748 4.905 1.98748 3.395 2.85748L1.675 3.84748L10.215 6.98748L5.055 9.96748L3.165 9.26748L0.875 10.5875L4.695 11.9875L4.005 15.9975L6.295 14.6775L6.635 12.6975L11.795 9.71748L10.245 18.6875L11.965 17.6975C13.475 16.8275 14.515 15.3375 14.815 13.6175L15.895 7.35748L20.085 4.93748C20.835 4.49748 21.095 3.53748 20.665 2.77748V2.78748Z" fill="currentColor"/>
        </svg>
        `
    },
    objects: {
        svg: `<svg fill-rule="evenodd" width="20" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2417 1.66663C7.02962 1.66663 4.41669 4.32496 4.41669 7.59163C4.41669 9.50829 5.34037 11.3 6.85487 12.4083V17.5083H8.36937C8.85202 18.025 9.52605 18.3333 10.25 18.3333C10.974 18.3333 11.648 18.025 12.1307 17.5083H13.6452V12.4083C15.1597 11.3 16.0834 9.50829 16.0834 7.59163C16.0834 4.32496 13.4704 1.66663 10.2583 1.66663H10.2417ZM11.7229 12.85V13.8H8.76048V12.85H11.7229ZM11.648 15.5666H8.76048V14.625H11.7229V15.5666H11.648ZM12.4053 10.9083H10.6494V8.58329L12.1556 7.04996L11.5815 6.46663L10.2417 7.82496L8.90195 6.46663L8.32777 7.04996L9.83395 8.58329V10.9083H8.07812C6.98801 10.175 6.3223 8.92496 6.3223 7.59163C6.3223 5.39996 8.07812 3.60829 10.2417 3.60829C12.4053 3.60829 14.1611 5.39163 14.1611 7.59163C14.1611 8.92496 13.4954 10.175 12.4053 10.9083Z" fill="currentColor"/>
        </svg>
        `
    },
    symbols: {
        svg: `<svg fill-rule="evenodd" width="20" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.24996 3.89996C7.12496 3.51663 6.73329 3.27496 6.32496 3.34163C5.92496 3.40829 5.62496 3.75829 5.62496 4.16663V11.8166C5.36663 11.725 5.08329 11.6666 4.79163 11.6666C3.41663 11.6666 2.29163 12.7916 2.29163 14.1666C2.29163 15.5416 3.41663 16.6666 4.79163 16.6666C6.16663 16.6666 7.29163 15.5416 7.29163 14.1666V6.67496C7.90829 7.14163 8.72496 7.49996 9.79163 7.49996V5.83329C7.94163 5.83329 7.27496 3.97496 7.24996 3.89996ZM4.79163 15C4.33329 15 3.95829 14.625 3.95829 14.1666C3.95829 13.7083 4.33329 13.3333 4.79163 13.3333C5.24996 13.3333 5.62496 13.7083 5.62496 14.1666C5.62496 14.625 5.24996 15 4.79163 15Z" fill="currentColor"/>
        <path xmlns="http://www.w3.org/2000/svg" d="M18.9583 4.26663L18.125 2.81663L16.125 3.97496V1.66663H14.4583V3.97496L12.4666 2.81663L11.6333 4.26663L13.625 5.41663L11.6333 6.56663L12.4666 8.01663L14.4583 6.85829V9.16663H16.125V6.85829L18.125 8.01663L18.9583 6.56663L16.9583 5.41663L18.9583 4.26663Z" fill="#59606D"/>
        <path xmlns="http://www.w3.org/2000/svg" d="M17.2916 11.25H9.79163V12.5H17.2916V11.25Z" fill="#59606D"/>
        <path xmlns="http://www.w3.org/2000/svg" d="M9.79163 15H12.7083V18.3333H14.375V15H17.2916V13.3333H9.79163V15Z" fill="#59606D"/>
        </svg>
        `
    },
    flags: {
        svg: `<svg fill-rule="evenodd" width="20" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8917 7.76663L17.2334 4.43329C17.5 4.17496 17.575 3.78329 17.4334 3.43329C17.2917 3.09163 16.9584 2.86663 16.5834 2.86663H5.00004V1.66663H3.33337V18.3333H5.00004V12.6666H16.5834C16.9584 12.6666 17.2834 12.4416 17.4334 12.1C17.575 11.7583 17.5 11.3666 17.2334 11.1L13.8917 7.76663ZM5.00004 11V4.53329H14.7667L11.5334 7.76663L14.7667 11H5.00004Z" fill="currentColor"/>
        </svg>
        `
    },

};

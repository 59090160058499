import { Component } from 'react';
import { getString, languageChangeEvent } from '@nackle/intl-tools';
export default class InjectTranslation extends Component {

    static defaultProps = {
        stripHTML: false,
    };

    componentDidMount() {
        languageChangeEvent.on( this.handleLanguageChange );
    }

    componentWillUnmount() {
        languageChangeEvent.off( this.handleLanguageChange );
    }

    render() {
        const {
            children,
            params,
            stripHTML,
        } = this.props;

        const translations = {};
        Object.keys( params ).forEach( ( translationKey ) => {
            const replaceOptions = params[ translationKey ] || undefined;
            const translatedString = getString( translationKey, replaceOptions );
            translations[ translationKey ] = stripHTML ?
                translatedString.replace( /<(?:.|\n)*?>/gm, '' ) :
                translatedString;
        } );

        return children( translations );
    }

    handleLanguageChange = () => this.forceUpdate();
}

import React from 'react';
import { Session } from '@nackle/arcade-core-v2';
import { Bridge } from '@nackle/bridge';
import { connect } from 'react-redux';
import { auth } from '../config';

const mapStateToProps = ( state ) => {
    return {
        isAuthenticated: Session.isAuthenticated( state ),
    };
};

const mapDispatchToProps = {
    setUserData: Session.setUserData,
};

export class AppAuth extends React.Component {
    render() {
        const { children, isAuthenticated } = this.props;
        return (
            <Bridge
                requiresAuth
                auth={ auth }
                onSignIn={ this.handleUserDataChanged }
                onSignInError={ this.onError }
                onAuthStateChange={ this.handleUserDataChanged }
                onSignOut={ () => {this.handleUserDataChanged( undefined );} }
                onSignOutError={ this.onError }
            >
                { isAuthenticated && children }
            </Bridge>
        );
    }

    onError = ( error ) => console.error( 'This is error----------->', error );

    handleUserDataChanged = ( userData ) => {
        console.log( 'user-data---->', userData );
        console.log( 'this.props.setUserData--->', this.props.setUserData );
        this.props.setUserData( userData );
    };
}

export default connect( mapStateToProps, mapDispatchToProps )( AppAuth );

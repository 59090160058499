import React from 'react';
import PropTypes from 'prop-types';
import { Radio as AntRadio } from 'antd';
import Card from './card';
import Button from './button';

const RadioGroup = ( { children, options, optionType, showLockIcon, value, ...restProps } ) => {
    let childrenToRender = children;
    if ( options && options.length > 0 ) {
        childrenToRender = options.map( ( option ) => {
            if ( typeof option === 'string' || typeof option === 'number' ) {
                if ( optionType === 'card' ) {
                    return (
                        <Card key={ option } value={ option } { ...restProps }>
                            { option }
                        </Card>
                    );
                } else if ( optionType === 'button' ) {
                    return (
                        <Button key={ option } value={ option } { ...restProps }>
                            { option }
                        </Button>
                    );
                }
            }
            if ( optionType === 'card' ) {
                return (
                    <Card
                        key={ option.value }
                        description={ option.description }
                        showLockIcon={ showLockIcon || option.showLockIcon }
                        value={ option.value }
                        disabled={ option.disabled }
                        { ...restProps }
                    >
                        { option.label }
                    </Card>
                );
            } else if ( optionType === 'button' ) {
                return (
                    <Button
                        key={ option.value }
                        description={ option.description }
                        showLockIcon={ showLockIcon || option.showLockIcon }
                        value={ option.value }
                        disabled={ option.disabled }
                        { ...restProps }
                    >
                        { option.label }
                    </Button>
                );
            }
        } );
    }

    return (
        <AntRadio.Group
            className={ optionType ? `origami-radio-group-${ optionType }` : 'origami-radio-group' }
            options={ optionType === 'card' || optionType === 'button' ? undefined : options }
            optionType={ optionType === 'card' || optionType === 'button' ? undefined : optionType }
            value={ value }
            { ...restProps }
        >
            { childrenToRender }
        </AntRadio.Group>
    );
};

RadioGroup.propTypes = {
    /** array of options */
    options: PropTypes.array,
    /** what type of option group */
    optionType: PropTypes.oneOf( [ 'card', 'button', 'default' ] ),
    /** show the lock icon */
    showLockIcon: PropTypes.bool,
    /** option group value */
    value: PropTypes.any
};
export default RadioGroup;
import React from 'react';
import { apiRootUrl } from './game-loader-comm-api';
import { setLanguageBundle, updateLanguageBundle } from '@nackle/intl-tools';
import { Button, Menu, Progress, Modal, message } from '@nackle/paper';
import { Cross } from '@nackle/icons';
import { InfoCircleFilled } from '@ant-design/icons';
import InjectTranslation from './inject-translation';
import { AwardDialog }  from './modals/award-dialog';
import ErrorDialog from './modals/error-dialog';
import InstructionsDialog  from './modals/instruction-dialog';
import IntroductionDialog  from './modals/introduction-dialog';
import { getTranslatedProjectStrings } from './translation-actions';
import { fixDecimalPointsBug } from './util/fixDecimalPointBug';
import { LoaderIframe } from './LoaderIframe';
import { Deferred } from './util/deffered';
import './loader.css';

const Dialog = Object.freeze( {
    /**
     * Shows the user what they've won
     */
    AWARD: 'Award',

    ERROR: 'Error',

    /**
     * Tells the user how to play when they click the (i) icon
     */
    INSTRUCTIONS: 'Instructions',

    /**
     * Appears when the game loader first loads; tells the user how to play and lets them back out
     * without spending a token
     */
    INTRODUCTION: 'Introduction',
} );
  
export const CMX_PROJECT_KEY = 'arcade-ui';

 
export default class LoaderComponent extends React.Component {

     static defaultProps = {
        config: {},
        imageUrls: {},
        loaderUrl: '/build/loader.js',
        localeCode: 'en-US',
        LoaderIframeCls: LoaderIframe,
    };
 
    state = {
        isLoading: true,
        isLoaded: false,
        loadingProgress: 0,
        rewards: [],
        showModal: Dialog.INTRODUCTION,
        translationsLoaded: false,
        wonRewards: [],
        startListeners: null,
        gameScript: null,
        manifest: {},
        files: {}
    };
 
     iframeContainer;
     loaderIframe;
     whenLoaderIframeConnected = new Deferred();
     mounted = true;
 
     async componentDidMount() {
        this.loadTranslations();
    }
 
    /**
     * Construct and initialize the LoaderIframe when its container <div> is mounted in the DOM
     */
    
 
     componentWillUnmount() {
        this.mounted = false;
        this.clearLoaderIframe();
    }
 
     onLoad = () => {
        console.log( 'iframe loaded' );
        document.documentElement.classList.add( 'game-loader-overflow' );
    };
 
     handleOnClose = () => {
        document.documentElement.classList.remove( 'game-loader-overflow' );
        this.props.onClose();
    };
 
     handleEndModalClose = () => {
        this.handleCloseModal();
        this.setStateIfMounted( { isLoading: true } );
        document.documentElement.classList.remove( 'game-loader-overflow' );
        this.props.onClose();
    };
 
     loadAwardDialog = () => {

        this.setStateIfMounted( ( prev ) => ( { showModal: Dialog.AWARD, wonRewards: prev.rewards } ) );
    };
    handleIframeContainerRef = async ( ref ) => {
        this.iframeContainer = ref;
        if ( ref ) {
            try {
                const {
                    LoaderIframeCls,
                    ...props
                } = this.props;

                const loaderIframe = new ( this.props.LoaderIframeCls )( {
                    ...props,
                    gameplayUpdate: this.gameplayUpdate,
                    container: ref,
                    onPageLoaded: this.onLoad,
                    onPackageLoadError: ( e ) => {
                        console.error( 'Game loader reports error loading', e );
                        this.setStateIfMounted( {
                            isLoading: false,
                            isLoaded: false,
                            showModal: Dialog.ERROR,
                        } );
                    },
                    onPackageLoaded: () => {
                        this.setStateIfMounted( {
                            isLoading: false,
                            isLoaded: true,
                        } );
                    },
                    setLoadingProgress: ( loadingProgress ) => {
                        this.setStateIfMounted( {
                            isLoading: true,
                            isLoaded: false,
                            loadingProgress,
                        } );
                    },
                } );

                this.loaderIframe = loaderIframe;

                await loaderIframe.connect();

                this.whenLoaderIframeConnected.resolve( loaderIframe );
            } catch ( e ) {
                this.whenLoaderIframeConnected.reject( e );
            }
        }
    };
 
    render() {
        const {
            isLoading,
            isLoaded,
            loadingProgress,
            showModal,
            translationsLoaded,
            wonRewards
        } = this.state;
        const {
            instructions,
            onPlay,
        } = this.props;
        // Ideally we'd just be using the intl-tools package to render translated strings and strip the
        // HTML from the value, but because of how the Arcade is currently passing around the cabinet's
        // instructions, this is difficult to do. This addition makes so cabinet instructions always
        // have their HTML stripped from them before rendering on the Arcade Floor or Game Loader.
        //
        // TODO: Move this somewhere more appropriate or just make use of the intl-tools package.
        const HTML_TAG_REGEX = /<(?:.|\n)*?>/gm;
        const formattedInstructions = ( typeof instructions === 'string' ) ? instructions.replace( HTML_TAG_REGEX, '' ) : instructions;
 
        return (
            <>
                <Modal
                    className="game-loader-overlay"
                    transitionName="game-loader-overlay"
                    wrapClassName="game-loader-overlay-wrap"
                    closable={ false }
                    visible
                    keyboard={ false }
                    onCancel={ this.handleCloseModal }
                    mask={ false }
                    footer={ null }
                >
                    <div className={ `game-loader ${ isLoaded ? 'loaded' : '' }` }>
                        <Menu className="game-navbar" mode="horizontal">
                            { translationsLoaded && (
                                <InjectTranslation
                                    params={ {
                                        'game-loader.CLOSE_GAME': null,
                                        'game-loader.TOOLBAR.SHOW_INSTRUCTIONS': null,
                                    } }
                                >
                                    { ( {
                                        'game-loader.CLOSE_GAME': closeGameText,
                                        'game-loader.TOOLBAR.SHOW_INSTRUCTIONS': showInstructionsText,
                                    } ) => (
                                        <Menu.Item>
                                            <Button
                                                aria-label={ showInstructionsText }
                                                className="show-instructions"
                                                // You can't bring up the instructions when anything else is loaded
                                                disabled={ Boolean( showModal ) }
                                                icon={ <InfoCircleFilled /> }
                                                type="text"
                                                id="game-navbar_show_instructions"
                                                onClick={ this.handleShowInstructions }
                                                title={ showInstructionsText }
                                            />
                                            <Button
                                                aria-label={ closeGameText }
                                                className="close-button"
                                                icon={ <Cross /> }
                                                id="game_navbar_close"
                                                type="text"
                                                onClick={ this.handleEndModalClose }
                                                title={ closeGameText }
                                            />
                                        </Menu.Item>
                                    ) }
                                </InjectTranslation>
                            ) }
                        </Menu>
                        <div className="game-container">
                            <div className="game-container__frame" id="game-frame" ref={ this.handleIframeContainerRef } />
                            { isLoading && (
                                <Progress
                                    className="loading-spinner"
                                    percent={ loadingProgress }
                                    showInfo={ false }
                                />
                            ) }
                        </div>
                      
                    </div>
                </Modal>
                <AwardDialog
                    visible={ showModal === Dialog.AWARD }
                    onClose={ this.handleEndModalClose }
                    onPlayAgain={ onPlay && this.handlePlay }
                    rewards={ wonRewards }
                />
                 
                <ErrorDialog
                    visible={ showModal === Dialog.ERROR }
                    onClose={ this.handleOnClose }
                />
                { translationsLoaded && (
                    <IntroductionDialog
                        instructions={ formattedInstructions }
                        visible={ showModal === Dialog.INTRODUCTION }
                        onClose={ this.handleOnClose }
                        onPlay={ this.handlePlay }
                    />
                ) }
                <InstructionsDialog
                    instructions={ formattedInstructions }
                    visible={ showModal === 'Instructions' }
                    onClose={ this.handleCloseModal }
                />
            </>
        );
    }
 
     handlePlay = async () => {
        try {
            const { onPlay } = this.props;
            if ( !onPlay ) {
                throw new Error( 'Cannot play. Probably no tokens.' );
            }
 
            let { rewards } = await onPlay();
            rewards = fixDecimalPointsBug( rewards );
 
            this.setStateIfMounted( { rewards } );
 
            const loaderIframe = await this.whenLoaderIframeConnected.promise;
            loaderIframe.focus();
            await loaderIframe.start( { rewards } );
 
            // Hide the modal
            this.handleCloseModal();
        } catch ( e ) {
            console.error( e );
 
            message.error( 'An error has occurred. Please try again later.' );
 
            throw e;
        }
    };
 
    /**
     * Attempts to load all required CMX language bundles into the 'intl-tools' store.
     * Replaces all previously loaded language bundles. Once the attempted language
     * bundle load is finished, the state is updated.
     */
     async loadTranslations() {
        try {
            const languageBundle = await getTranslatedProjectStrings( apiRootUrl( this.props.env ), this.props.getSession(), CMX_PROJECT_KEY, this.props.localeCode );
            const response = await updateLanguageBundle( languageBundle );
            // If locale is undefined, that means the language bundle hasn't been set yet.
            if ( !response.locale ) {
                await setLanguageBundle( this.props.localeCode, languageBundle );
            }
        } finally {
            if ( this.mounted ) {
                this.setStateIfMounted( { translationsLoaded: true } );
            }
        }
    }
 
     handleShowInstructions = () => this.setStateIfMounted( { showModal: Dialog.INSTRUCTIONS } );
 
     handleCloseModal = () => this.setStateIfMounted( { showModal: undefined } );
 
     gameplayUpdate = ( options ) => {
        this.props.gameplayUpdate( options );
        if ( options.complete ) {
            this.loadAwardDialog();
        }
    };
 
    /* Wrap setState() so unit tests don't throw warnings about updating an unmounted component */
     setStateIfMounted = ( ...args ) => {
        if ( !this.mounted ) {
            return;
        }
        this.setState( ...args );
    };
 
     clearLoaderIframe() {
        if ( this.loaderIframe ) {
            this.loaderIframe.destroy();
            this.loaderIframe = null;
        }
    }
}


export const styles_config = 
    ` :host {
      box-shadow: none !important;
  }
  .category .sticky,
  .search input[type=search]::placeholder {
      color: #59606D;
      font-weight: 700;
      font-size: 10px;
      font-family: 'Poppins';
      text-transform: capitalize;
      padding-left: 8px !important;
  }
  .search .icon {
     content: url('https://api.biw.cloud/v1/images/search.png') !important;
  }
  .scroll {
      padding-right: var(--padding);
  }
  #nav button {
      color: none;
      transition: none;
  }
  #nav svg {
      width: 20px;
      height: 20px;
      color: rgb(89, 96, 109);
  }
  button[aria-selected=true] svg {
      fill:rgb(var(--color-primary-none, 89, 96, 109));;
      color: #59606D;
  }
  #nav .bar {
      background-color: rgb(var(--color-primary-none, 89, 96, 109));
  }
  .skin-tone-button {
      border: none;
  }
  .skin-tone:after {
      box-shadow: none;
      border: none;
  }
  .skin-tone-1 {
      background-color: #F0C83B;
  }
  .skin-tone {
      width: 20px;
      height: 20px;
  }
  .search input  {
      font-size: 14px;
  }
  .search input[type="search"] {
      background-color: white;
      border: 1px solid #C9D1DF;
      border-radius: 6px;
      padding: 8px 2em 8px 2.2em;
  }
  ::placeholder {
      color: #C9D1DF !important;
      font-size: 14px !important;
      font-weight: 400 !important;
    }`;
import videojs from 'video.js';
import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import {
    ArrowExpandRight,
    ArrowCompressRight,
    Volume,
    VolumeOff,
    CloseLarge
} from '@nackle/origami-icons';
import { Tooltip } from '../tooltip/tooltip.js';
import { Modal } from '..';
import { getString } from '@nackle/intl-tools';
import 'video.js/dist/video-js.css';
const NackleVideoPlayer = ( {
    className,
    isModalOpen,
    handleCancel,
    setIsModalOpen,
    options = {},
    sources,
    onVideoEnded,
    onVideoPlayed,
    onVideoPaused,
    allowSeek = false
} ) => {
    const [ isPlaying, setIsPlaying ] = useState( false );
    const [ isFullScreen, setIsFullScreen ] = useState( false );
    const [ finishedPlaying, setFinishedPlaying ] = useState( false );
    const [ isMuted, setIsMuted ] = useState( false );
    const [ isExpand, setIsExpand ] = useState( false );
    const [ lengthOfVideo, setLength ] = useState();
    const [ isSmallScreen, setIsSmallScreen ] = useState( false );

    const progressRef = useRef( null );
    const wrapperRef = useRef( null );
    const timeElapsedRef = useRef( null );
    const durationRef = useRef( null );
    const volumeRef = useRef( null );
    const controlsRef = useRef( null );
    const seekRef = useRef( null );
    const seekTooltipRef = useRef( null );
    const videoRef = useRef();
    const playRef = useRef();
    const pauseRef = useRef();
    const replayRef = useRef();
    const playerRef = useRef( null );

    const closeText = getString( 'origami.video.close' );
    const pauseText = getString( 'origami.video.pause' );
    const playText = getString( 'origami.video.play' );
    const unmuteText = getString( 'origami.video.unmute' );
    const muteText = getString( 'origami.video.mute' );
    const exitFullScreenText = getString( 'origami.video.exitFullScreen' );
    const enterFullScreenText = getString( 'origami.video.enterFullScreen' );
    const videoPlayerClasses = classNames( {
        'nackle-video-player': true,
        [ className ]: className ? true : false,
        'full-screen': isFullScreen ? true : false,
    } );
    const videoControlClasses = classNames( {
        'video-control': true,
        'full-control': isFullScreen ? true : false,
    } );
    const controlPanelClasses = classNames( {
        'control-panel': true,
        'full-panel': isFullScreen ? true : false,
    } );
    const videoProgressClasses = classNames( {
        'video-progress': true,
        'full-progress': isFullScreen ? true : false,
    } );
    useEffect( () => {
        options.controls = true;
        options.responsive = true;
        options.fluid = false;
        options.experimentalSvgIcons = true;
        if ( isModalOpen && !playerRef.current ) {
            options.sources = sources;
            options.autoplay = true;
            options.bigPlayButton = false;
            const videoElement = document.createElement( 'video-js' );
            videoRef.current.appendChild( videoElement );
            const player = playerRef.current = videojs( videoElement, options, () => {
                onReady && onReady( player );
            } );
        } 
        else if ( playerRef.current ) {
            options.sources = sources;
            const player = playerRef.current;
            player.autoplay( false );
            player.src( options.sources );
        }

    }, [ videoRef, isModalOpen ] );

    const onReady = ( player ) => {
        playerRef.current = player;
        player.on( 'waiting', () => {
            videojs.log( 'Video Player is waiting' );
        } );
        player.on( 'dispose', () => {
            videojs.log( 'Video player will dispose' );
            playerRef.current = undefined;
        } );
        player.on( 'timeupdate', async () => {
            setTimes();
        } );
        player.on( 'loadedmetadata', async ( ) => {
            
            if ( !lengthOfVideo && player.duration() ) {
                
                const videoDuration = player.duration();
                setLength( videoDuration );
                checkMetadata();
                
            }
            if ( window.innerWidth < 736 ) {
                player.playsinline( true );
                setIsExpand( true );
                setIsFullScreen( true );
                setIsSmallScreen( true );
                player.enableTouchActivity();
            }

        } );
        player.on( 'ended', async () => {
            
            onEndedVideo();

        } );
        player.on( 'play', () => {
            
            if ( typeof onVideoPlated === 'function' ) {
                onVideoPlayed();
            }
            togglePlay();
        } );
        player.on( 'pause', () => {
            
            togglePlay();
            if ( typeof onVideoPaused === 'function' ) {
                onVideoPaused();
            }
        } );
        player.on( 'touchend', () => {
            
            touchToggle();
        } );
        addEventListener( 'fullscreenchange', ( event ) => {
            if ( videojs.browser.IS_IOS || videojs.browser.IS_ANDROID ) {
                return;
            }
            if ( document.fullscreenElement ) {
                setIsFullScreen( true );
                setIsExpand( true );
            } else {
                setIsFullScreen( false );
                setIsExpand( false );

            }
        } );
    };
    const setTimes = () => {
        const player = playerRef.current;
        const time = player.duration();

        if ( !lengthOfVideo && time ) {

            setLength( Math.round( time ) );
        }
        updateTimeElapsed();
    };

    const updateVolume = ( e ) => {

        const player = playerRef.current;
        player.volume( event.target.value );
        const sliderValue = event.target.value * 100;
        volumeRef.current.value = player.volume();
        volumeRef.current.style.background = `linear-gradient(to right, rgba(255, 255, 255, 1) ${ sliderValue }%, rgba(255, 255, 255, 0.4) ${ sliderValue }%)`;
    };
    const updateProgress = () => {
        const player = playerRef.current;
        seekRef.current.value = player.currentTime();
        progressRef.current.value = player.currentTime();
    };

    const onEndedVideo = () => {
        const player = playerRef.current;
        if ( player.ended ) {
            setFinishedPlaying( true );
            setIsPlaying( false );
            if ( playRef.current ) {
                playRef.current.style.display = 'none';
            }
            if ( pauseRef.current ) {

                pauseRef.current.style.display = 'none';
            }
            if ( replayRef.current ) {
                replayRef.current.style.display = 'block';
            }
            if ( typeof onVideoEnded === 'function' ) {
                onVideoEnded();
            }
        }
    };
    const formatTime = ( timeInSeconds ) => {

        const result = new Date( timeInSeconds * 1000 ).toISOString().substr( 11, 8 );
        return {
            minutes: result.substr( 3, 2 ),
            seconds: result.substr( 6, 2 ),
        };


    };
    const checkMetadata = () => {
        initializeVideo();
    };
    const updateTimeElapsed = () => {
        const player = playerRef.current;
        if ( progressRef.current.value && progressRef.current.value > 0 && player.currentTime() === 0 ) {
            player.currentTime( progressRef.current.value );
        }
        if ( player.currentTime() !== 0 ) {
            const time = formatTime( player.cache_.currentTime );
            timeElapsedRef.current.innerText = `${ time.minutes }:${ time.seconds }`;
            timeElapsedRef.current.setAttribute( 'datetime', `${ time.minutes }m ${ time.seconds }s` );
            updateProgress();   
        }
    };
    const initializeVideo = async () => {
        const player = playerRef.current;
        const videoDuration = player.duration();
        seekRef.current.setAttribute( 'max', videoDuration );
        progressRef.current.setAttribute( 'max', videoDuration );
        const time = formatTime( videoDuration );
        durationRef.current.innerText = `${ time.minutes }:${ time.seconds }`;
        durationRef.current.setAttribute( 'datetime', `${ time.minutes }m ${ time.seconds }s` );
        setTimes();
        
    };
    const skipAhead = ( event ) => {
        const player = playerRef.current;
        let skipTo = ( event.target.dataset.seek ? event.target.dataset.seek : event.target.value );
        if ( skipTo <= player.currentTime() || allowSeek === true ) {
            player.currentTime( skipTo );
            progressRef.current.value = skipTo;
            seekRef.current.value = skipTo;
        }
    };
    const updateSeekTooltip = ( event ) => {
        if ( event.target.getAttribute( 'max' ) ) {
            const skipTo = Math.round(
                ( event.nativeEvent.offsetX / event.target.clientWidth ) *
                parseInt( event.target.getAttribute( 'max' ), 10 )
            );
            seekRef.current.setAttribute( 'data-seek', skipTo );
            const t = formatTime( parseInt( skipTo ) );
            if ( seekTooltipRef.current !== null ) {
                seekTooltipRef.current.textContent = `${ t.minutes }:${ t.seconds }`;
                const rect = videoRef.current.getBoundingClientRect();
                seekTooltipRef.current.style.left = `${ event.pageX - rect.left }px`;
            }
        }
    };
    const touchToggle = () => {
        const player = playerRef.current;
        if ( player ) {
            if ( player.paused() ) {
                player.play();
            }
            else {
                player.pause();
            }
        }
    };
    const videoHandler = ( control ) => {
        const player = playerRef.current;
        if ( control === 'play' ) {
            player.play();
            playRef.current.style.display = 'block';
            pauseRef.current.style.display = 'none';
            setIsPlaying( true );
            setFinishedPlaying( false );
        } else if ( control === 'pause' ) {
            player.pause();
            playRef.current.style.display = 'none';
            pauseRef.current.style.display = 'block';
            setIsPlaying( false );
            setFinishedPlaying( false );
        } else if ( control === 'replay' ) {
            player.currentTime( 0 );
            progressRef.current.value = 0;
            seekRef.current.value = 0;
            player.play();
            setIsPlaying( true );
            setFinishedPlaying( false );
        }
        controlsRef.current.animate(
            [
                {
                    opacity: 1,
                    transform: 'scale(1)',
                },
                {
                    opacity: 0,
                    transform: 'scale(1.3)',
                },
            ],
            {
                duration: 500,
            }
        );
    };
    const togglePlay = () => {
        const player = playerRef.current;
        if ( player ) {
            if ( player.paused() ) {
                // player.pause();
                if ( playRef.current ) {
                    playRef.current.style.display = 'none';
                    pauseRef.current.style.display = 'block';
                }
                setIsPlaying( false );
            }
            else {
                // player.play();
                if ( playRef.current ) {
                    playRef.current.style.display = 'block';
                    pauseRef.current.style.display = 'none';
                }
                setIsPlaying( true );
                setFinishedPlaying( false );
            }
            if ( controlsRef.current ) {
                controlsRef.current.animate(
                    [
                        {
                            opacity: 1,
                            transform: 'scale(1)',
                        },
                        {
                            opacity: 0,
                            transform: 'scale(1.3)',
                        },
                    ],
                    {
                        duration: 500,
                    }
                );
            }
        }
    };
    const mutedHandler = ( control ) => {
        const player = playerRef.current;
        if ( control === 'muted' ) {
            setIsMuted( false );
            player.muted( false );
        } else if ( control === 'unmuted' ) {
            setIsMuted( true );
            player.muted( true );
        }
    };
    const fullScreenHandler = ( control ) => {
        const player = wrapperRef.current;
        if ( control === 'expand' ) {
            setIsExpand( true );
            setIsFullScreen( true );
            player.requestFullscreen();

        } else if ( control === 'compress' ) {
            setIsExpand( false );
            setIsFullScreen( false );

            if ( navigator.userAgent.indexOf( 'Firefox' ) != -1 ) {
                document.mozCancelFullScreen();
            } else {
                // Chrome & Safari
                try {
                    document.webkitCancelFullScreen();
                } catch ( error ) {
                    console.error( 'Browser is not allowing the page to exist full screen' );
                }
            }
        }
    };

    const handleModalClose = () => {
        if ( typeof handleCancel === 'function' ) {
            handleCancel();
        }
        setIsPlaying( false );
        if ( playerRef.current && playerRef.current.ended ) {
            videoHandler( 'pause' );
            progressRef.current.value = 0;
            seekRef.current.value = 0;
        }
        playerRef.current.dispose();
    };
    const closeSmallScreen = () => {
        const player = playerRef.current;
        player.pause();
        setIsModalOpen( false );
    };
    return (
        <div>
            <Modal
                className="video-player-container-desktop"
                data-dismiss="modal"
                transparent={ true }
                open={ isModalOpen }
                maskClosable={ false }
                footer={ null }
                width={ 845 }
                onCancel={ handleModalClose }
                closeIcon={ isModalOpen ? <Tooltip title={ closeText }>{ isModalOpen ? <CloseLarge /> : null }</Tooltip> : null }
            >
                <div ref={ wrapperRef } className="custom-content-wrapper" >
                    <div className="controls" ref={ controlsRef }>
                        <img
                            ref={ pauseRef }
                            className="controlsIcon-small"
                            alt="pause"
                            src="https://api.biw.cloud/v1/images/pause-button.svg"
                        />
                        <img
                            ref={ playRef }
                            className="controlsIcon-small"
                            alt="play"
                            src="https://api.biw.cloud/v1/images/play-button.svg"
                        />
                    </div>
                    <div className="replay">
                        { finishedPlaying && (
                            <img
                                ref={ replayRef }
                                onClick={ () => videoHandler( 'replay' ) }
                                className="replay-icon"
                                alt="replay"
                                src="https://api.biw.cloud/v1/images/replay-button.svg"
                            />
                        ) }
                    </div>
                    <div
                        className={ videoPlayerClasses }
                        ref={ videoRef }
                    >
                    <div className={ videoControlClasses }>
                        <div className={ videoProgressClasses }>
                            <progress
                                className="progress"    
                                ref={ progressRef }
                                value="0"
                                min="0"
                            ></progress>
                            <input
                                className="seek"
                                ref={ seekRef }
                                value="0"
                                min="0"
                                type="range"
                                step=".01"
                                onInput={ skipAhead }
                                onMouseMove={ updateSeekTooltip }
                            />
                            <div className="seek-tooltip" ref={ seekTooltipRef }>
                                00:00
                            </div>
                        </div>
                        <div className={ controlPanelClasses }>
                            <div className="audio-control">
                                { isPlaying ? (
                                    <div className="play-button">
                                        <Tooltip
                                            getPopupContainer={ () => wrapperRef.current }
                                            title={ pauseText }
                                        >
                                            <img
                                                onClick={ () => videoHandler( 'pause' ) }
                                                src="https://api.biw.cloud/v1/images/state=pause.svg"
                                            />
                                        </Tooltip>
                                    </div>
                                ) : (
                                    <div className="play-button">
                                        <Tooltip
                                            getPopupContainer={ () => wrapperRef.current }
                                            title={ playText }
                                        >
                                            <img
                                                onClick={ () => videoHandler( 'play' ) }
                                                src="https://api.biw.cloud/v1/images/state=play.svg"
                                            />
                                        </Tooltip>
                                    </div>
                                ) }
                                <div className="volume-control">
                                    { isMuted ? (
                                        <div
                                            className="mute-icon"
                                            onClick={ () => mutedHandler( 'muted' ) }
                                        >
                                            <Tooltip
                                                getPopupContainer={ () => wrapperRef.current }
                                                title={ unmuteText }
                                            >
                                                <VolumeOff fill="#FFFFFF" width="30px" height="30px" />
                                            </Tooltip>
                                        </div>
                                    ) : (
                                        <div
                                            className="mute-icon"
                                            onClick={ () => mutedHandler( 'unmuted' ) }
                                        >
                                            <Tooltip
                                                getPopupContainer={ () => wrapperRef.current }
                                                title={ muteText }
                                            >
                                                <Volume fill="#FFFFFF" width="30px" height="30px" />
                                            </Tooltip>
                                        </div>
                                    ) }
                                    <div className="wrapper-input" >
                                        <input
                                            ref={ volumeRef }
                                            defaultValue="1"
                                            type="range"
                                            className="range-input"
                                            onInput={ updateVolume }
                                            max="1"
                                            min="0"
                                            step="0.001"
                                        />
                                    </div>
                                </div>
                                <div className="video-timeline">
                                    <time ref={ timeElapsedRef }>00:00</time>
                                    <span> / </span>
                                    <time ref={ durationRef }>00:00</time>
                                </div>
                            </div>
                            <div className="caption-control">
                                <div className="screen-control">
                                    <div>
                                        { isExpand && (
                                            <Tooltip
                                                getPopupContainer={ () => wrapperRef.current }
                                                title={ exitFullScreenText }
                                            >
                                                { isExpand ? (
                                                    <ArrowCompressRight
                                                        fill="#FFFFFF"
                                                        width="30px"
                                                        height="30px"
                                                        onClick={ isSmallScreen ? () => closeSmallScreen() : () => fullScreenHandler( 'compress' ) }
                                                    />
                                                ) : null }
                                            </Tooltip>
                                        ) }
                                    </div>
                                    <div>
                                        { !isExpand && (
                                            <Tooltip
                                                getPopupContainer={ () => wrapperRef.current }
                                                title={ enterFullScreenText }
                                            >
                                                { isExpand ? null : (
                                                    <ArrowExpandRight
                                                        fill="#FFFFFF"
                                                        width="30px"
                                                        height="30px"
                                                        onClick={ () => fullScreenHandler( 'expand' ) }
                                                    />
                                                ) }
                                            </Tooltip>
                                        ) }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>

                </div>
            </Modal>

        </div>
    );
};

export { NackleVideoPlayer };


import React from 'react';
import { LocaleString } from '@nackle/intl-tools';
import { Button, Modal } from '@nackle/paper';

const ErrorDialog = ( props ) =>{
    const {
        visible,
        onClose,
    } = props;
    return (
        <Modal
            getContainer=".game-container"
            visible={ visible }
            onCancel={ onClose }
            title="Error loading game"
            centered
            footer={ (
                <Button
                    id="instructions_dialog_close"
                    type="primary"
                    onClick={ onClose }
                >
                    <LocaleString code="game-loader.INSTRUCTIONS_MODAL.CLOSE_BUTTON" />
                </Button>
            ) }
        >
                An error occurred loading this game.
        </Modal>
    );
};

export default ErrorDialog;
import { CommError } from './comm-error';
import Comm  from './comm';

import {
    ERR_CONNECTION_TIMEOUT,
    HANDSHAKE,
    ERR_IFRAME_ALREADY_ATTACHED_TO_DOM,
    HANDSHAKE_REPLY,
    MESSAGE,
    CHECK_IFRAME_IN_DOC_INTERVAL,
    ERR_CONNECTION_DESTROYED,
} from './constants';

/*export interface ConnectToClientOptions<T = DefaultMethodMap> {
    iframeEl: HTMLIFrameElement;
    container: HTMLElement;
    child?: Window;
    parent?: Window;
    methods?: T;
    timeout?: number;
}*/

export const connectToClient = async ( opts )=> {
    const comm = new Comm( 'Parent' );

    const iframeEl = opts.iframeEl;
    const parent = opts.parent || window;
    let child = opts.child || iframeEl.contentWindow;
    const container = opts.container || document.getElementsByTagName( 'body' );
    const methods = opts.methods || {};

    if ( iframeEl && iframeEl.parentNode ) {
        throw new CommError(
          'connectToClient() must not be called with the `iframeEl` already attached to DOM',
          ERR_IFRAME_ALREADY_ATTACHED_TO_DOM,
        );
      }

    let destroyCallReceiver = () => {};

    return new Promise( ( resolve, reject ) => {
        let connectionTimeoutId;

        if ( opts.timeout !== undefined ) {
            connectionTimeoutId = setTimeout( () => {
                reject( new CommError(
                    `Connection to child timed out after ${ opts.timeout }ms`,
                    ERR_CONNECTION_TIMEOUT,
                ) );
                comm.destroy();
            }, opts.timeout );
        }

        // We resolve the promise with the call sender. If the child reconnects (for example, after
        // refreshing or navigating to another page, we'll update the call sender
        // with methods that match the latest provided by the child.
        let receiverMethodNames   = [];

        const handleChildHandshakeRequest = ( event ) => {
            if ( !child ) {
                child = iframeEl.contentWindow;
            }

            if ( event.source === child && event.data.msgType === HANDSHAKE ) {
                event.source.postMessage( {
                    msgType: HANDSHAKE_REPLY,
                    methodNames: Object.keys( methods ),
                }, '*' );

                const info = {
                    local: parent,
                    remote: child,
                    remoteOrigin: '*',
                };

                // If the child reconnected, we need to destroy the previous call receiver before setting
                // up a new one.
                if ( destroyCallReceiver ) {
                    destroyCallReceiver();
                }

                comm.connectCallReceiver( info, methods );

                destroyCallReceiver = () => {
                    parent.removeEventListener( MESSAGE, handleChildHandshakeRequest );
                };

                // If the child reconnected, we need to remove the methods from the previous call receiver
                // off the sender.
                if ( receiverMethodNames ) {
                    receiverMethodNames.forEach( ( receiverMethodName ) => {
                        delete comm.remoteApi[ receiverMethodName ];
                    } );
                }

                receiverMethodNames = event.data.methodNames;
                comm.connectCallSender(
                    info,
                    receiverMethodNames,
                );
                clearTimeout( connectionTimeoutId );
                resolve( { destroy: comm.destroy, api: comm.remoteApi } );
            }
        };

        parent.addEventListener( MESSAGE, handleChildHandshakeRequest );

        container.appendChild( iframeEl );

        // This is to prevent memory leaks when the iframe is removed
        // from the document and the consumer hasn't called destroy().
        // Without this, event listeners attached to the window would
        // stick around and since the event handlers have a reference
        // to the iframe in their closures, the iframe would stick around
        // too.
        const checkIframeInDocIntervalId = setInterval( () => {
            if ( !parent.document.contains( iframeEl ) ) {
                clearInterval( checkIframeInDocIntervalId );
                comm.destroy();
            }
        }, CHECK_IFRAME_IN_DOC_INTERVAL );

        comm.addDestructionCallback( () => {
            if ( iframeEl.parentNode ) {
                iframeEl.parentNode.removeChild( iframeEl );
            }

            destroyCallReceiver();
            clearInterval( checkIframeInDocIntervalId );

            reject( new CommError( 'Connection destroyed', ERR_CONNECTION_DESTROYED ) );
        } );
    } );
};

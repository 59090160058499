import React from 'react';

export default ( { className, fill, ...props } ) => (
    <svg width="24" height="24" viewBox="0 0 24 24" className={ className } { ...props } fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 20H20V4H9V2H20C21.1 2 22 2.9 22 4V20C22 21.1 21.1 22 20 22H4C2.9 22 2 21.1 2 20V9H4V20Z" fill={ fill || 'currentColor' } />
        <path d="M17 8.5H7V10.5H17V8.5Z" fill={ fill || 'currentColor' } />
        <path d="M7 13.5H17V15.5H7V13.5Z" fill={ fill || 'currentColor' } />
        <path d="M2 4.5C2 5.88071 3.11929 7 4.5 7C5.88071 7 7 5.88071 7 4.5C7 3.11929 5.88071 2 4.5 2C3.11929 2 2 3.11929 2 4.5Z" fill={ fill || 'currentColor' } />
    </svg>
);

import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Carousel as Carousl, Card } from 'antd';
import { routeHistory } from '@nackle/biw-web-router';
import { NackleVideoPlayer } from '../NackleVideo/nackle-video-player';
import { ArrowLeft, ArrowRight } from '@nackle/origami-icons';
/* eslint react/no-unknown-property: 0 */
const Carousel = ( { effect, autoplay, autoplaySpeed, speed, dots, hasScrim, countPath, handleNavigate, locale, allowSeek, isDelegate, ...props } ) => {
    const [ currentIndex, setCurrentIndex ] = useState( 1 );
    const [ isModalOpen ] = useState( Array( props.newsItems.length ).fill( false ) );
    const [ timeoutId, setTheTimeoutId ] = useState( false );
    const { newsItems, className } = props;
    const [ firstInit, setFirstInit ] = useState( true );
    const slider = useRef( { goTo: () => { return; } } );

    const handleCancel = ( index ) => {
        isModalOpen[ index ] = false;
        clearInterval( timeoutId );
        goNext();
    };
    const showModal = ( index, open ) => {
        isModalOpen[ index ] = open;
        clearInterval( timeoutId );
        goNext();
    };

    const updateCurrent = ( current, next ) => {
        setCurrentIndex( next + 1 );
    };
    const sleep = ( delay ) => new Promise( ( resolve, reject ) => {
        const timeout = setTimeout( resolve, delay );
        setTheTimeoutId( timeout );
    } );

    const handleNextClick = () => {
        clearInterval( timeoutId );
        goNext();

    };
    const handlePrevClick = () => {
        clearInterval( timeoutId );
        goPrev();
    };
    const goNext = () => {
        slider.current.next();
    };

    const goPrev = () => {
        slider.current.prev();
    };
    const handleKeyPress = ( e, callback, variables ) => {
        if ( e.key === 'Enter' ) {
            if ( typeof callback === 'function' ) {
                if ( variables ) {
                    callback( variables );
                } else {
                    callback();
                }
            }
        }
    };
    const startAutoplay = async ( index ) => {
        if ( newsItems[ index ].pauseTime ) {
            await sleep( newsItems[ index ].pauseTime * 1000 );
        } else {
            await sleep( 7000 );
        }
        slider.current.goTo( index + 1 );
    };
    const initCustomAutoplay = async () => {
        if ( firstInit ) {
            if ( newsItems[ 0 ].pauseTime ) {
                await sleep( newsItems[ 0 ].pauseTime * 1000 );
            } else {
                await sleep( 7000 );
            }
            try {
                slider.current.goTo( 1 );
            } catch ( error ) {
                //do nothing with inexplicable null error
            }
            setFirstInit( false );
        }
    };
    const handleNavigation = ( e ) => {
        if ( typeof handleNavigate === 'function' ) {
            handleNavigate( event );
        } else if ( countPath ) {
            routeHistory.push( countPath );
        } else {
            return;
        }
    };
    const itemCount = newsItems.length;
    const navigate = ( { type, url, onClick } ) => {

        if ( typeof onClick === 'function' ) {
            onClick( 'div.carousel-content-container' );
        }
        if ( type === 'EXTERNAL_URL' ) {
            if ( isDelegate ) {
                return;
            }
            window.open( url, '_blank' ).focus();
        } else if ( type === 'DOCUMENT' ) {
            window.open( url, '_blank' ).focus();
        } else if ( type === 'INTERNAL_URL' ) {
            routeHistory.push( url );
        } else if ( type === 'SCORM' ) {
            window.open( url, '_blank' ).focus();
        }
    };
    return (
        <div className={ className ? `carousel ${ className }` : 'carousel' }>
            <Card bordered={ false } >
                { newsItems && newsItems.length > 0 &&
                    <Carousl
                        className="carousel"
                        onInit={ initCustomAutoplay }
                        beforeChange={ updateCurrent }
                        afterChange={ startAutoplay }
                        effect={ effect || 'fade' }
                        autoplay={ false }
                        autoplaySpeed={ autoplaySpeed ? autoplaySpeed : undefined }
                        speed={ 100 }
                        dots={ dots !== undefined ? dots : false }
                        ref={ slider }
                        swipe={ false }
                        infinite={ true }
                        touchMove={ false }
                    >
                        { newsItems.map( ( item, index ) => {
                            const sources = [];
                            if ( item.linkType === 'VIDEO' ) {
                                if ( item.videoUrl && item.videoUrl.length > 0 ) {
                                    item.videoUrl.map( video => {
                                        if ( video.descriptor !== 'thumbnail' ) {
                                            sources.push( { src: video.url, type: `video/${ video.descriptor }` } );
                                        }
                                    } );
                                }
                            }
                            return (
                                <div key={ item.id }
                                    className={ isDelegate && item.linkType === 'EXTERNAL_URL' ? 'disabled carousel-content-container' : 'carousel-content-container' }>
                                    <div
                                        className="img-container"
                                        tabIndex={ currentIndex - 1 === index ? 0 : -1 }
                                        onKeyPress={ item.linkType === 'NO_LINK' ? null : item.linkType === 'INTERNAL_URL' ? () => handleKeyPress( event, navigate, { type: item.linkType, url: item.path, onClick: item.onClick } ) : () => handleKeyPress( event, navigate, { type: item.linkType, url: item.url, onClick: item.onClick } ) }>
                                        {
                                            hasScrim &&
                                            <div className="scrim" />
                                        }
                                        {
                                            item.imageUrl &&
                                            <img src={ item.imageUrl } fetchpriority="high" className="image" alt={ `carousel image ${ item.text ? item.text : item.linkType }` } />
                                        }
                                            
                                    </div>
                                    <div className={ item.linkType === 'NO_LINK' ? 'news-content-container no-link' : 'news-content-container' } onClick={ item.linkType === 'NO_LINK' ? null : item.linkType === 'INTERNAL_URL' ? () => navigate( { type: item.linkType, url: item.path, onClick: item.onClick } ) : () => navigate( { type: item.linkType, url: item.url, onClick: item.onClick } ) }>
                                        { item.linkType === 'VIDEO' ?

                                            <div className='video-modal'>
                                                {
                                                    <>
                                                        <img
                                                            onClick={ () => showModal( index, true ) }
                                                            alt="play"
                                                            fetchpriority="high"
                                                            src='https://api.biw.cloud/v1/images/play-button.svg' />
                                                        <NackleVideoPlayer
                                                            setIsModalOpen={ ( open ) => showModal( index, open ) }
                                                            handleCancel={ () => handleCancel( index ) }
                                                            isModalOpen={ isModalOpen[ index ] }
                                                            sources={ sources }
                                                            allowSeek={ allowSeek }
                                                        />
                                                    </>
                                                }
                                            </div>
                                            : null
                                        }
                                        <div className="content-box">
                                            <div className="text-container">{ item.text }</div>
                                        </div>
                                    </div>
                                    <div className="bottom-container">
                                        <div>
                                            <div className="controls">
                                                <div className="nav-button-container">
                                                    <div><div role="button" tabIndex={ currentIndex - 1 === index ? 0 : -1 } aria-label="previous-slide" className="direction-arrow" onKeyPress={ () => handleKeyPress( event, handlePrevClick ) } onClick={ handlePrevClick }><ArrowLeft fill="white" height={ 24 } width={ 24 } /></div></div>
                                                    <div><div role="button" tabIndex={ currentIndex - 1 === index ? 0 : -1 } className="direction-arrow" aria-label="next-slide" onKeyPress={ () => handleKeyPress( event, handleNextClick ) } onClick={ handleNextClick }><ArrowRight fill="white" height={ 24 } width={ 24 } /></div></div>
                                                </div>
                                                <div className={ countPath ? 'clickable count-container' : 'count-container' } onClick={ () => handleNavigation( event ) }>{ `${ currentIndex } / ${ itemCount }` }</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        } )
                        }
                    </Carousl>
                }
            </Card>
        </div>
    );
};

Carousel.propTypes = {
    /** Transition effect */
    effect: PropTypes.oneOf( [ 'scrollx', 'fade' ] ),
    /** Should Carousel autoplay on load */
    autoplay: PropTypes.bool,
    /** How long between autoplay transitions */
    autoplaySpeed: PropTypes.number,
    /** Duration of transition effect */
    speed: PropTypes.number,
    /** Should it display a scrim over the background image */
    hasScrim: PropTypes.bool,
    /** Should it display navigation dots */
    dots: PropTypes.bool,
    /** internal link for navigation when click on slide count element */
    countPath: PropTypes.string,
    /** callback called when navigating */
    handleNavigate: PropTypes.func,
    /** array of carousel items */
    newsItems: PropTypes.arrayOf( PropTypes.shape( {
        /** UUID */
        id: PropTypes.string,
        /** Url of slide background image */
        imageUrl: PropTypes.string.isRequired,
        /** Type of link when clicking slide */
        linkType: PropTypes.oneOf( [ 'EXTERNAL_URL', 'DOCUMENT', 'INTERNAL_URL', 'SCORM', 'NO_LINK', 'VIDEO' ] ),
        /** url of link */
        url: PropTypes.string,
        /** optional text to display */
        text: PropTypes.node
    } ) ),
    /** class name */
    className: PropTypes.string
};

export { Carousel };




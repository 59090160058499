import {
    ERR_CONNECTION_TIMEOUT,
    HANDSHAKE_REPLY,
    MESSAGE,
    ERR_CONNECTION_DESTROYED,
    HANDSHAKE,
} from './constants';
import { CommError } from './comm-error';
import Comm  from './comm';

/*export interface ConnectToServerOptions<T = DefaultMethodMap> {
    child?: Window;
    parent?: Window;
    methods?: T;
    timeout?: any;
}*/

export const connectToServer = async ( opts = {} ) => {
    const comm = new Comm( 'Child' );

    const child = opts.child || window;
    const parent = opts.parent || child.parent;

    const methods = opts.methods || {};
    const timeout = opts.timeout;

    

    return new Promise( ( resolve, reject ) => {
        let connectionTimeoutId;

        if ( timeout !== undefined ) {
            connectionTimeoutId = setTimeout( () => {
                reject( new CommError(
                    `Connection to parent timed out after ${ timeout }ms`,
                    ERR_CONNECTION_TIMEOUT,
                ) );
                comm.destroy();
            }, timeout );
        }

        const handleParentHandshakeReply = ( event ) => {
            if ( event.source === parent && event.data.msgType === HANDSHAKE_REPLY ) {

                child.removeEventListener( MESSAGE, handleParentHandshakeReply );

                const info = {
                    local: child,
                    remote: parent,
                    remoteOrigin: event.origin,
                };

                comm.connectCallReceiver( info, methods );
                comm.connectCallSender(
                    info,
                    event.data.methodNames,
                );
                clearTimeout( connectionTimeoutId );
                resolve( { destroy: comm.destroy, api: comm.remoteApi } );
            }
        };

        child.addEventListener( MESSAGE, handleParentHandshakeReply );

        comm.addDestructionCallback( () => {
            child.removeEventListener( MESSAGE, handleParentHandshakeReply );

            reject( new CommError( 'Connection destroyed', ERR_CONNECTION_DESTROYED ) );
        } );

        parent.postMessage(
            {
                msgType: HANDSHAKE,
                methodNames: Object.keys( methods ),
            },
            '*',
        );
    } );
};

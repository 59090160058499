import { Games, Play, Translate, Translations } from '@nackle/arcade-core-v2';
import React from 'react';
import { connect } from 'react-redux';
import { FloorText } from '../../translation-data';
import { StarCircleIcon } from 'components/icons';
import { GameImage } from './GameImage';
import './CabinetItem.less';

const mapStateToProps = ( state, props ) => {
    // get cabinet's alt text.
    const altTextTranslation = Translations.getTranslation(
        state,
        Games.cmxKeys.formatCmxCabinetThumbnailAltKey( props.cabinet.cabinetId ),
    );

    return {
        cost: Games.getCabinetCost( state, props.cabinet.cabinetId, props.roomId ),
        playTokenIds: Play.getTokensIdsToPlay( state, props.cabinet.cabinetId, props.roomId ),
        // if cabinet thumbnail alt text doesn't exist, use rom thumbnail alt text.
        altText: altTextTranslation ? altTextTranslation : Translations.getTranslation(
            state,
            Games.cmxKeys.formatCmxRomThumbnailAltKey( props.cabinet.romId ),
        )
    };
};

const mapDispatchToProps = {
    openGame: Play.openGame,
};

class CabinetItem extends React.Component {
    render() {
        const {
            cabinet,
            cost,
            playTokenIds,
            roomId,
            tokenCount,
            altText,
        } = this.props;

        const playCount = Math.floor( tokenCount / cost );

        return (
            <Translate
                fallback={ cabinet.name }
                id={ Games.cmxKeys.formatCmxRomNameKey( cabinet.romId ) }
            >
                { ( fallbackName ) => (
                    <Translate
                        fallback={ fallbackName }
                        id={ Games.cmxKeys.formatCmxCabinetNameKey( cabinet.cabinetId ) }
                        stripHtml
                    >
                        { ( gameName ) => (
                            <Translate
                                id={ FloorText.ROOM_GAME_LABEL }
                                args={ {
                                    cost,
                                    gameName,
                                    playCount,
                                } }
                            >
                                { ( label ) => (
                                    <button
                                        className={ `nkl-card nkl-card-bordered ${ playTokenIds ? 'nkl-card-hoverable' : '' }  gameCard-container` }
                                        data-cabinet-id={ cabinet.cabinetId }
                                        data-rom-id={ cabinet.romId }
                                        data-room-id={ roomId }
                                        disabled={ !playTokenIds }
                                        id="game-name-link"
                                        onClick={ this.handleClick }
                                        title={ label }
                                        aria-label={ label }
                                    >
                                        <GameImage
                                            url={ cabinet.image || cabinet.bannerImage }
                                            altText={ altText }
                                        />
                                        <div className="gameCard-footer">
                                            <div className="gameName-container">
                                                { gameName }
                                            </div>
                                            <div className="tokensToPlay-container">
                                                <StarCircleIcon className="tokensIcon" />&nbsp;{ cost }
                                            </div>
                                        </div>
                                    </button>
                                ) }
                            </Translate>
                        ) }
                    </Translate>
                ) }
            </Translate>
        );
    }

    handleClick = () => {
        const { cabinet, openGame, playTokenIds, roomId } = this.props;
        if ( playTokenIds && cabinet ) {
            openGame( cabinet.cabinetId, roomId ).catch( ( e ) => console.error( e ) );
        }
    };
}

export default connect( mapStateToProps, mapDispatchToProps )( CabinetItem );
